import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const PreviewVideoFile = () => {
    const videoPath = useSelector((state) => state.preview.videoPath, shallowEqual);

    const renderPreviewVideoFile = () => {
        const path = videoPath.replace(/#/, encodeURIComponent('#'));
        return (
            <video controls autoPlay disablePictureInPicture controlsList="nodownload" className="video-content">
                <source src={path} type="video/mp4" />
            </video>
        );
    };

    return <div className="preview-video">{videoPath && renderPreviewVideoFile()}</div>;
};

export default PreviewVideoFile;
