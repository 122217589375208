import { LOGS_DATA, LOGS_INFORMATION_POPUP, SET_TAGS_DATA, SET_LOGS_COUNT } from '../storeActionTypes';

const initialState = {
    logsData: null,
    logsCount: null,
    isLogsPopup: false,
    tagsData: null,
};

export function executionReducer(state = initialState, { type, payload }) {
    switch (type) {
        case LOGS_DATA: {
            return { ...state, logsData: payload };
        }
        case LOGS_INFORMATION_POPUP: {
            return { ...state, isLogsPopup: payload };
        }
        case SET_TAGS_DATA: {
            return { ...state, tagsData: payload };
        }
        case SET_LOGS_COUNT: {
            return { ...state, logsCount: payload };
        }
        default: {
            return state;
        }
    }
}
