import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';

import { MAIN_PAGE } from '../../variables/common';

import {
    setScreen,
    sessionExpired,
    sessionStoped,
    StreamURLDevice,
    setSessionTab,
    setInspectorMode,
    setInspectorDetails,
} from '../../store/interactive/interactive.actions';

const AlertDialog = ({ status }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setScreen(null));
        dispatch(sessionStoped(false));
        dispatch(sessionExpired(false));
        dispatch(StreamURLDevice(null));
        dispatch(setInspectorMode(false));
        dispatch(setInspectorDetails(null));
        dispatch(setSessionTab('Parameters'));
        history.push(MAIN_PAGE);
    };

    return (
        <div>
            <Dialog
                open={true}
                data-testid="dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <WarningIcon className="expire-confirm-icon" />
                    Session {status}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography component="span" className="alert-dialog-info">
                            Your session has {status}.
                        </Typography>

                        <Typography component="span" className="alert-dialog-info">
                            You will be redirected to the main page.
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AlertDialog;
