import {
    IS_EXPIRED,
    BACK_BUTTON,
    TOKEN_POPUP,
    HEADER_TITLE,
    SET_TAB_NUMBER,
    SESSIONS_POPUP,
    SET_CREATION_MODE,
    SET_SERVER_VERSION,
    IS_BROWSER_SESSION,
    SET_VALIDATE_STATUS,
} from '../storeActionTypes';

export const setTabNumber = (data) => ({
    type: SET_TAB_NUMBER,
    payload: data,
});

export const setServerVersion = (data) => ({
    type: SET_SERVER_VERSION,
    payload: data,
});

export const setValidateStatus = (data) => ({
    type: SET_VALIDATE_STATUS,
    payload: data,
});

export const backButton = (data) => ({
    type: BACK_BUTTON,
    payload: data,
});

export const sessionsPopup = (data) => ({
    type: SESSIONS_POPUP,
    payload: data,
});

export const tokenPopup = (data) => ({
    type: TOKEN_POPUP,
    payload: data,
});

export const HeaderTitle = (data) => ({
    type: HEADER_TITLE,
    payload: data,
});

export const setCreationMode = (data) => ({
    type: SET_CREATION_MODE,
    payload: data,
});

export const isBrowserSession = (data) => ({
    type: IS_BROWSER_SESSION,
    payload: data,
});

export const sessionExpiredPopup = (data) => ({
    type: IS_EXPIRED,
    payload: data,
});

export const _isExpired = (error) => (dispatch) => {
    if (error.response.status === 404) {
        dispatch(sessionExpiredPopup(true));
    }
};
