import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import Stream from './StreamContainer';
import Spiner from '../Common/Loader';
import RightPanel from './RightPanel';
import AlertDialog from '../Common/Dialog';
import CreationMode from './CreationMode';

import Grid from '@material-ui/core/Grid';

const Device = () => {
    const history = useHistory();

    const bgImage = useSelector((state) => state.interactive.bgImage, shallowEqual);
    const isLoading = useSelector((state) => state.interactive.isLoading, shallowEqual);
    const streamURL = useSelector((state) => state.interactive.streamURL, shallowEqual);
    const activeDevice = useSelector((state) => state.device.activeDevice, shallowEqual);
    const isExpired = useSelector((state) => state.interactive.sessionExpired, shallowEqual);
    const isStoped = useSelector((state) => state.interactive.sessionStoped, shallowEqual);
    const isCreationModeOpen = useSelector((state) => state.auxiliary.isCreationModeOpen, shallowEqual);
    const AppiumSID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);

    const setNumberOfColumns = (numberOfColumnsWhenModeOpen, numberOfColumnsWhenModeClose) => {
        return isCreationModeOpen ? numberOfColumnsWhenModeOpen : numberOfColumnsWhenModeClose;
    };

    const renderPage = () => {
        return (
            <>
                <CreationMode />
                <Grid container spacing={3} style={{ height: 'calc(100vh-55px)' }}>
                    <Grid item xs={setNumberOfColumns(4, false)} />

                    <Grid item xs={setNumberOfColumns(4, 7)}>
                        <Stream />
                    </Grid>

                    <Grid item xs={setNumberOfColumns(4, 5)}>
                        <div className="right-panel">
                            <RightPanel activeDevice={activeDevice} />
                        </div>
                    </Grid>
                </Grid>

                {isLoading && <Spiner />}
                {!streamURL && !bgImage && !isExpired && <Spiner />}
                {AppiumSID === isStoped?.sid && isStoped && <AlertDialog status="stopped" />}
                {AppiumSID === isExpired?.sid && isExpired && <AlertDialog status="expired" />}
            </>
        );
    };
    return <>{activeDevice ? renderPage() : history.push('/')}</>;
};

export default Device;
