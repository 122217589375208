import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const Parameters = ({ activeDevice }) => {
    function createData(name, value) {
        return { name, value };
    }

    function createDeviceType(name, value) {
        value =
            value.match(/emulator/) || value.match(/simulator/) || value.match(/\w+-\w+-\w+-\w+-\w+/)
                ? 'Virtual'
                : 'Real';

        return { name, value };
    }

    const rows = [
        createData('Manufacture', `${activeDevice.manufacturer}`),
        createData('Model', `${activeDevice.model}`),
        createData('OS', `${activeDevice.os}`),
        createData('OS Version', `${activeDevice.osVersion ? activeDevice.osVersion : activeDevice.os_version}`),
        createData('Firmware', `${activeDevice.firmware}`),
        createData('Device ID', `${activeDevice.id}`),
        createDeviceType('Device Type', `${activeDevice.id}`),
    ];

    return (
        <>
            <p className="element-trees-header">Details</p>
            <TableContainer className="parameters-table" component="div">
                <Table>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell padding="none" align="left" className="parameters-cell parameters-key">
                                    {row.name}
                                </TableCell>
                                <TableCell padding="none" align="left" className="parameters-cell parameters-value">
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Parameters;
