import React, { useState, useEffect } from 'react';

import Pump from './Skin';
import Spiner from '../Common/Loader';

const Board = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //temporary solution, artificial loading delay
        setTimeout(() => setIsLoading(false), 1500);
    }, []);

    return (
        <div className="board">
            <Pump />
            {isLoading && <Spiner />}
        </div>
    );
};

export default Board;
