import { createSelector } from 'reselect';
export const getSearch = (state) => state.device.search;
export const getVirtualDevicesSwitch = (state) => state.device.virtualDevicesSwitch;

export const getVirtualDevices = (state) => state.device.virtualDevices;
export const getRealDevices = (state) => state.device.realDevices;
export const getFilteredData = (state) => state.device.filteredData;

export const getAllDevicesData = createSelector([getVirtualDevices, getRealDevices], (virtual, real) => [
    ...virtual,
    ...real,
]);

export const getFilteredVirtualDevices = createSelector([getVirtualDevices, getSearch, getFilteredData], searchDevices);
export const getFilteredRealDevices = createSelector([getRealDevices, getSearch, getFilteredData], searchDevices);

function searchDevices(devices, search, filteredData) {
    let devicesArray = filteredData ? filteredData : devices;

    if (!devicesArray) {
        return null;
    }

    return devicesArray.filter((device) => {
        return Object.keys(device).some((key) => {
            switch (key) {
                case 'id':
                    return device[key].toLowerCase().includes(search.toLowerCase());
                case 'model':
                    return device[key].toLowerCase().includes(search.toLowerCase());
                case 'manufacturer':
                    return device[key].toLowerCase().includes(search.toLowerCase());
                case 'os':
                    return device[key].toLowerCase().includes(search.toLowerCase());
                case 'firmware':
                    return device[key].toLowerCase().includes(search.toLowerCase());
                case 'owner':
                    return device[key]?.toLowerCase().includes(search.toLowerCase());

                default:
                    return null;
            }
        });
    });
}
