import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as Yup from 'yup';
import { useField, Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { TITLE } from '../../variables/common';
import { getLogin, postLogin, setUserError } from '../../store/user/user.actions';

const MyTextField = ({ label, onShowPassword, ...props }) => {
    const [field, meta] = useField(props);
    const isPasswordType = props.type === 'password';
    const isPasswordLabel = label.toLowerCase() === 'password';

    const renderPasswordIcon = () => {
        return isPasswordType ? (
            <VisibilityOff className="login-password-icon" onClick={onShowPassword} />
        ) : (
            <Visibility className="login-password-icon" onClick={onShowPassword} />
        );
    };

    return (
        <>
            <div className="input-container">
                <p className="label">{label}</p>
                <input className="input" name={label} {...field} {...props} />

                {isPasswordLabel && renderPasswordIcon()}
            </div>

            {meta.touched && meta.error ? (
                <div className="error-login">{meta.error}</div>
            ) : (
                <div className="error-login" />
            )}
        </>
    );
};

function CognitoAuthBlock(props) {
    const { cognitoURL } = props;

    return (
        <>
            <Typography component="p" className={!cognitoURL ? 'login-separator hidden' : 'login-separator'}>
                or
            </Typography>
            <Button href={cognitoURL} className={!cognitoURL ? 'login-submit hidden' : 'login-submit'}>
                Login by Cognito
            </Button>
        </>
    );
}

const LoginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email not valid').required('Email is required'),
    password: Yup.string().min(6, 'Password must be 6 characters or longer').required('Password is required'),
});

const Login = (props) => {
    const dispath = useDispatch();
    const [isVisible, setIsVisible] = useState(false);

    const serverVersion = useSelector((state) => state.user.serverVersion, shallowEqual);
    const cognitoURL = useSelector((state) => state.user.cognitoURL, shallowEqual);
    const loginError = useSelector((state) => state.user.loginError, shallowEqual);

    const type = isVisible ? 'text' : 'password';

    //* for unit-test
    const { handleLogin } = { ...props };

    const onSubmit = (values) => {
        dispath(postLogin(values));
    };

    const onClose = () => {
        dispath(setUserError(false));
    };

    const onShowPassword = () => {
        setIsVisible((prev) => !prev);
    };

    const renderLoginError = () => {
        return (
            <div className="login-error" data-testid="login-error">
                <div className="login-error-group">
                    <ReportIcon className="login-error-report" />
                    <p>Incorrect email or password</p>
                </div>

                <CloseIcon className="login-error-close" data-testid="login-error-icon" onClick={onClose} />
            </div>
        );
    };

    useEffect(() => {
        dispath(getLogin());
    }, []);

    return (
        <div className="login-page" data-testid="login">
            <div className="login-wrap">
                <div className="login-content">
                    <div className="login-title">{TITLE.MAGNUM}</div>
                    <div className="login-recomendation">{loginError ? renderLoginError() : null}</div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={LoginValidationSchema}
                        onSubmit={(values) => handleLogin(values, onSubmit)}>
                        {() => (
                            <Form className="login-form">
                                <MyTextField name="email" type="email" label="Email" data-testid="email" />
                                <MyTextField
                                    name="password"
                                    type={type}
                                    label="Password"
                                    data-testid="password"
                                    onShowPassword={onShowPassword}
                                />
                                <Button data-testid="btn-submit" type="submit" className="login-submit">
                                    Login
                                </Button>
                                <CognitoAuthBlock cognitoURL={cognitoURL} />
                            </Form>
                        )}
                    </Formik>
                </div>
                {serverVersion ? <span className="login-version">Server version: {serverVersion}</span> : null}
            </div>
        </div>
    );
};

export default Login;
