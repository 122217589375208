import auth from '../../utils/auth';
import { WS_URL } from '../../variables/common';
import { setServerVersion, setValidateStatus } from '../auxiliary/auxiliary.actions';
import { isVirtualDevicesSwitch, RealDevices, VirtualDevices } from '../device/device.actions';

import {
    ActiveSessions,
    StreamURLDevice,
    AppiumSessionID,
    sessionExpired,
    sessionStoped,
    stopVideoRecord,
} from '../interactive/interactive.actions';

import {
    ERROR,
    STREAM,
    DEVICES,
    SESSIONS,
    SESSION_EXPIRED,
    SESSION_STOPED,
    CONNECTED,
    REAUTHORIZE,
    ADD_DEVICE,
    UPDATE_DEVICE,
    DELETE_DEVICE,
    DELETE_DEVICES,
    SERVER_VERSION,
    VALIDATE_STATUS,
    MAINTENANCE_MESSAGE,
    CANCEL_MAINTENANCE_MESSAGE,
    STOP_VIDEO_RECORD,
    PONG_RESPONSE,
} from '../storeActionTypes';
import { deleteMaintenanceMessage, setMaintenanceMessage } from '../maintenance/maintenance.actions';

const PING_INTERVAL = 1000 * 60;

class WsClient {
    constructor(url, dispatch) {
        this._ws = new WebSocket(url + `/browser`);
        this.dispatch = dispatch;
        this._addRespProcessor();

        this._ws.onopen = () => {
            // send handshake message
            this._sendReq({
                command: 'handshakeBrowser',
                data: {
                    token: auth.token,
                },
            });
            // TODO :redundand ??
            this.getActiveSessionForCurrentUser();
        };

        this._ws.onclose = () => {
            this.dispatch(RealDevices(undefined));
            this.dispatch(VirtualDevices(undefined));
            this.ACTUAL_DEVICE_LIST = {
                virtual: [],
                real: [],
            };
            clearInterval(this.pingInterval);
            // TODO show popup and reconnect when OK click
            setTimeout(() => {
                this.dispatch(CreateConnection());
            }, 3000);
        };

        // TODO workaround, change to state store
        this.ACTUAL_DEVICE_LIST = {
            virtual: [],
            real: [],
        };
    }

    _sendReq(msgObj) {
        this._ws.send(JSON.stringify(msgObj));
    }

    _addRespProcessor() {
        this._ws.onmessage = (message) => {
            const response = JSON.parse(message.data);
            switch (response.command) {
                // response for handshake message
                case CONNECTED: {
                    // request actual devicelist
                    this.getDeviceList();
                    this.getServerVersion();
                    this.getValidateStatus();
                    this.getActiveSessionForCurrentUser();

                    this.pingInterval = setInterval(() => {
                        this._sendReq({
                            command: 'ping',
                        });
                    }, PING_INTERVAL);
                    break;
                }
                case REAUTHORIZE: {
                    // caught user token issue
                    auth.logout();
                    history.go(0);
                    break;
                }
                //list of all connected devices
                case DEVICES: {
                    this.setDeviceList(response.data);
                    break;
                }
                // list of active sessions where owner is current  user
                case SESSIONS: {
                    this.setActiveSessionForCurrentUser(response.data);
                    break;
                }
                //
                case SESSION_EXPIRED: {
                    this.updateExpiredSession(response.data);
                    break;
                }
                case SESSION_STOPED: {
                    this.updateStopedSession(response.data);
                    break;
                }
                // added new device to DB
                case ADD_DEVICE: {
                    this.addDevice(response.data);
                    break;
                }
                //updated device
                case UPDATE_DEVICE: {
                    this.updateDevice(response.data);
                    break;
                }
                case DELETE_DEVICE: {
                    this.deleteDevice(response.data);
                    break;
                }
                case DELETE_DEVICES: {
                    this.deleteDevices(response.data);
                    break;
                }
                case ERROR: {
                    this.errorHandler(response.data);
                    break;
                }
                // TODO need to update
                case STREAM: {
                    this.setVideoStream(response.data);
                    break;
                }
                case STOP_VIDEO_RECORD: {
                    this.dispatch(stopVideoRecord(response.data));
                    break;
                }
                case SERVER_VERSION: {
                    this.setServerVersion(response.data);
                    break;
                }
                case VALIDATE_STATUS: {
                    this.setValidateStatus(response.data);
                    break;
                }
                // Message from superuser
                case MAINTENANCE_MESSAGE: {
                    this.dispatch(setMaintenanceMessage(response.data));
                    break;
                }
                case CANCEL_MAINTENANCE_MESSAGE: {
                    this.dispatch(deleteMaintenanceMessage());
                    break;
                }
                case PONG_RESPONSE: {
                    break;
                }
                default: {
                    console.log('Unrecognized server command');
                }
            }
        };
    }

    getDeviceList() {
        this._sendReq({
            command: 'devices',
        });
    }

    setDeviceList(devices) {
        // clear old devices
        this.ACTUAL_DEVICE_LIST = {
            virtual: [],
            real: [],
        };

        // filter arrived list
        devices.forEach((elem) => {
            if (elem.type === 'real') {
                this.ACTUAL_DEVICE_LIST.real.push(elem);
            } else {
                this.ACTUAL_DEVICE_LIST.virtual.push(elem);
            }
        });

        // set the switsh element to correct coppition
        if (this.ACTUAL_DEVICE_LIST.real.length === 0 && this.ACTUAL_DEVICE_LIST.virtual.length !== 0) {
            this.dispatch(isVirtualDevicesSwitch(true));
        } else {
            this.dispatch(isVirtualDevicesSwitch(false));
        }

        this.dispatch(RealDevices([...this.ACTUAL_DEVICE_LIST.real]));
        this.dispatch(VirtualDevices([...this.ACTUAL_DEVICE_LIST.virtual]));
    }

    getActiveSessionForCurrentUser() {
        this._sendReq({
            command: 'sessions',
            data: {
                token: auth.token,
            },
        });
    }

    setActiveSessionForCurrentUser(sessions) {
        this.dispatch(ActiveSessions(sessions));
    }

    getServerVersion() {
        this._sendReq({
            command: 'serverVersion',
            data: {
                token: auth.token,
            },
        });
    }

    getValidateStatus() {
        this._sendReq({
            command: 'validateStatus',
            data: {
                token: auth.token,
            },
        });
    }

    setServerVersion(version) {
        this.dispatch(setServerVersion(version));
    }

    setValidateStatus(validate) {
        this.dispatch(setValidateStatus(validate));
    }

    // add new device
    addDevice(device) {
        if (device.type === 'real') {
            this.ACTUAL_DEVICE_LIST.real.push(device);

            this.dispatch(RealDevices([...this.ACTUAL_DEVICE_LIST.real]));
        } else {
            this.ACTUAL_DEVICE_LIST.virtual.push(device);

            this.dispatch(VirtualDevices([...this.ACTUAL_DEVICE_LIST.virtual]));
        }
    }
    //update state with new info of device
    updateDevice(device) {
        if (device.type === 'real') {
            let indexToUpdate = this.ACTUAL_DEVICE_LIST.real.findIndex((elem) => {
                return elem.id === device.id;
            });
            if (indexToUpdate >= 0) {
                Object.assign(this.ACTUAL_DEVICE_LIST.real[indexToUpdate], device);
            }
            this.dispatch(RealDevices([...this.ACTUAL_DEVICE_LIST.real]));
        } else {
            let indexToUpdate = this.ACTUAL_DEVICE_LIST.virtual.findIndex((elem) => {
                return elem.id === device.id;
            });
            if (indexToUpdate >= 0) {
                Object.assign(this.ACTUAL_DEVICE_LIST.virtual[indexToUpdate], device);
            }

            this.dispatch(VirtualDevices([...this.ACTUAL_DEVICE_LIST.virtual]));
        }
    }

    deleteDevice(device) {
        if (device.type === 'real') {
            this.ACTUAL_DEVICE_LIST.real = this.ACTUAL_DEVICE_LIST.real.filter((elem) => {
                return elem.id !== device.id;
            });

            this.dispatch(RealDevices([...this.ACTUAL_DEVICE_LIST.real]));
        } else {
            this.ACTUAL_DEVICE_LIST.virtual = this.ACTUAL_DEVICE_LIST.virtual.filter((elem) => {
                return elem.id !== device.id;
            });

            this.dispatch(VirtualDevices([...this.ACTUAL_DEVICE_LIST.virtual]));
        }
    }

    deleteDevices(devices) {
        devices.forEach((device) => {
            if (device.type === 'real') {
                this.ACTUAL_DEVICE_LIST.real = this.ACTUAL_DEVICE_LIST.real.filter((elem) => {
                    return elem.id !== device.id;
                });
            } else {
                this.ACTUAL_DEVICE_LIST.virtual = this.ACTUAL_DEVICE_LIST.virtual.filter((elem) => {
                    return elem.id !== device.id;
                });
            }
        });

        this.dispatch(RealDevices([...this.ACTUAL_DEVICE_LIST.real]));
        this.dispatch(VirtualDevices([...this.ACTUAL_DEVICE_LIST.virtual]));
    }

    errorHandler(error) {
        console.log(JSON.stringify(error));
    }

    setVideoStream(data) {
        return this.dispatch(StreamURLDevice(data));
    }

    updateExpiredSession(sid) {
        this.getActiveSessionForCurrentUser();
        this.dispatch(StreamURLDevice(undefined));
        this.dispatch(AppiumSessionID(undefined));
        this.dispatch(sessionExpired({ sid: sid }));
    }

    updateStopedSession(sid) {
        this.dispatch(sessionStoped({ sid: sid }));
    }
}

export const deleteDevice = (device) => {
    WS_CLIENT.deleteDevice(device);
};

export const getDeviceList = (device) => {
    WS_CLIENT.getDeviceList(device);
};

export const mySessions = () => {
    WS_CLIENT.getActiveSessionForCurrentUser();
};

let WS_CLIENT = undefined;
export const CreateConnection = () => (dispatch) => {
    WS_CLIENT = new WsClient(WS_URL, dispatch);
};
