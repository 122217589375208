import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DraggableCore from 'react-draggable';
import { v4 } from 'uuid';

import {
    Table,
    Grow,
    Paper,
    Modal,
    Button,
    Select,
    Tooltip,
    Checkbox,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TextField,
    InputLabel,
    IconButton,
    FormControl,
    TableContainer,
    TableSortLabel,
    TablePagination,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

import { logsInformationPopup, setTagsData, getLogsData } from '../../store/execution/execution.actions';
import { deleteLogsData } from '../../store/execution/execution.actions';

import Artifacts from './artifacts';
import Spiner from '../Common/Loader';
import { executionLogStatus } from '../../variables/common';

const useStyles = makeStyles({
    scrollHoriz: {
        overflowX: 'inherit',
    },
    modal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderRadius: '10px',
        textAlign: 'center',
        borderColor: 'transparent',
    },
    container: {
        width: '320px',
        padding: '15px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        background: ' #fff',
        boxShadow: '4px 4px 16px -8px rgba(0, 0, 0, 0.68)',
        border: '1px solid silver',
    },
    closeIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: '#4d9bd5',
        },
    },
    executeBtn: {
        width: '100%',
        height: '37px',
        backgroundColor: '$secondary-color',
        borderRadius: '4px',
        textAlign: 'center',
        color: '$text-color-primary',
        marginTop: '25px',
        fontSize: '14px',
    },
    form: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
    popover: {
        width: '300px',
        position: 'absolute',
        top: 0,
        right: 15,
    },
    popoverContent: {
        borderRadius: 5,
        padding: 15,
    },
    titleWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    },
    copyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#4d9bd5',
        },
    },
    box: {
        inset: '0px',
        overflow: 'auto',
        height: 'fit-content',
        backgroundColor: '#cad8e673',
        lineHeight: '1.5',
        padding: '14px',
        borderRadius: '5px',
        margin: '10px 0',
    },
    title: {
        fontSize: '16px',
        textTransform: 'uppercase',
        color: '#4d9bd5',
        fontWeight: 600,
    },
    copyIcon: {
        color: '#4d9bd5',
        marginRight: 5,
    },
});

const headCells = [
    { id: 'email', label: 'User', hideSortIcon: true },
    { id: 'sid', label: 'Session', hideSortIcon: false },
    { id: 'deviceId', label: 'Device', hideSortIcon: false },
    { id: 'model', label: 'Model', hideSortIcon: true },
    { id: 'status', label: 'Status', hideSortIcon: false },
    { id: 'start', label: 'Start Time', hideSortIcon: false },
    { id: 'stop', label: 'End Time', hideSortIcon: false },
    { id: 'artifacts', label: 'Artifacts ', hideSortIcon: true },
    /* Do not delete - could be needed for the extra information */
    // { id: "customFields", label: "Other" },
];

function createData(user, session, device, model, status, start, stop, artifacts) {
    return { user, session, device, model, status, start, stop, artifacts };
}

const EnhancedTableHead = ({
    filter,
    rows,
    rowsPerPage,
    logsToDelete,
    sortConfig,
    setRows,
    setLogsToDelete,
    setSortLabel,
    setPage,
    setSortConfig,
}) => {
    const [isChecked, setIsChecked] = useState(false);
    const userRole = useSelector((state) => state.user.role, shallowEqual);
    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        const checked = e.target.checked;
        const listToDelete = checked ? rows.map((row) => row.session) : [];
        const selectedList = rows.map((row) => ({ ...row, checked }));

        setIsChecked(checked);
        setRows(selectedList);
        setLogsToDelete(listToDelete);
    };

    const handleOnClick = (id) => {
        const sortByValue = sortConfig === 'asc' ? 'desc' : 'asc';
        const logsData = { page: 0, size: rowsPerPage, sort: { label: id, param: sortByValue } };

        setPage(0);
        setSortLabel(id);
        setSortConfig(sortByValue);

        Object.keys(filter)?.length ? dispatch(getLogsData({ ...logsData, filter })) : dispatch(getLogsData(logsData));
    };

    return (
        <TableHead>
            <TableRow className="table-header">
                {userRole === 'superuser' ? (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="default"
                            size="small"
                            onChange={handleOnChange}
                            checked={isChecked && rows.length === logsToDelete.length}
                        />
                    </TableCell>
                ) : (
                    <TableCell />
                )}

                {headCells.map(({ id, hideSortIcon, label }) => (
                    <TableCell align="left" key={id}>
                        <TableSortLabel
                            hideSortIcon={hideSortIcon}
                            direction={sortConfig}
                            onClick={() => (!hideSortIcon ? handleOnClick(id) : null)}>
                            {label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = ({
    filter,
    rowsPerPage,
    logsToDelete,
    isFilterApplied,
    setIsFilterApplied,
    setFilteredData,
    setFilter,
    setPage,
    setOpenFilter,
    setLogsToDelete,
}) => {
    const dispatch = useDispatch();
    return !logsToDelete.length ? (
        <div className="filter-tooltip-wrapper">
            {isFilterApplied && Object.keys(filter).length ? (
                <Tooltip title="clear filters">
                    <IconButton
                        aria-label="clear list"
                        onClick={() => {
                            const logsData = {
                                page: 0,
                                size: rowsPerPage,
                                sort: { label: 'start', param: 'desc' },
                            };

                            dispatch(getLogsData(logsData));
                            setPage(0);
                            setIsFilterApplied(false);
                            setFilteredData(null);
                            setFilter({});
                        }}>
                        <ClearAllIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            ) : null}
            <Tooltip title="Logs filter">
                <IconButton
                    aria-label="filter list"
                    onClick={() => {
                        setOpenFilter((prev) => !prev);
                    }}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </div>
    ) : (
        <div className="delete-tooltip-wrapper">
            <span>{logsToDelete.length} selected</span>

            <Tooltip title="Delete logs">
                <IconButton
                    aria-label="delete logs"
                    onClick={() => {
                        dispatch(deleteLogsData(logsToDelete));
                        setLogsToDelete([]);
                        setIsFilterApplied(false);
                    }}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const LogsCenter = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState({});
    const [openFilter, setOpenFilter] = useState(false);
    const [sortLabel, setSortLabel] = useState('sid');
    const [sortConfig, setSortConfig] = useState('asc');
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [logsToDelete, setLogsToDelete] = useState([]);
    const [filteredData, setFilteredData] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const userRole = useSelector((state) => state.user.role, shallowEqual);
    const logsData = useSelector((state) => state.execution.logsData, shallowEqual);
    const logsCount = useSelector((state) => state.execution.logsCount, shallowEqual);
    const tagsData = useSelector((state) => state.execution.tagsData, shallowEqual);
    const isLogsPopup = useSelector((state) => state.execution.isLogsPopup, shallowEqual);

    let logs = [];

    const popoverContent = (props) => {
        return (
            <Paper elevation={4} className={classes.popoverContent}>
                <div className={classes.content}>
                    <div className={classes.titleWrap}>
                        <CloseIcon fontSize="small" className={classes.closeIcon} onClick={handleClose} />
                    </div>

                    <div className={classes.box}>
                        {props ? (
                            <div>
                                <p>NATS: {props.split(',')[0].split('=')[1]}</p>
                                <p>APP: {props.split(',')[1].split('=')[1]}</p>
                                <p>PUMP MODEL: {props.split(',')[2].split('=')[1]}</p>
                            </div>
                        ) : (
                            <p>No information</p>
                        )}
                    </div>
                </div>
            </Paper>
        );
    };

    function handleClose() {
        dispatch(logsInformationPopup(false));
        dispatch(setTagsData(undefined));
    }

    function handleChangePage(event, newPage) {
        const logsData = { page: newPage, size: rowsPerPage, sort: { label: sortLabel, param: sortConfig } };

        Object.keys(filter)?.length ? dispatch(getLogsData({ ...logsData, filter })) : dispatch(getLogsData(logsData));

        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        const rowsPerPage = parseInt(event.target.value, 10);
        const logsData = { page: 0, size: rowsPerPage, sort: { label: sortLabel, param: sortConfig } };

        setPage(0);
        setFilter({});
        setIsFilterApplied(false);
        setRowsPerPage(rowsPerPage);
        dispatch(getLogsData(logsData));
    }

    function handleFilterChange(e) {
        const { name, value } = e.target;
        setFilter((prev) => {
            if (value) {
                return { ...prev, [name]: value };
            } else {
                let temp = prev;
                delete temp[name];
                return { ...temp };
            }
        });
    }

    function filterLogs() {
        const logsData = { page: 0, size: rowsPerPage, sort: { label: sortLabel, param: sortConfig } };
        Object.keys(filter)?.length ? dispatch(getLogsData({ ...logsData, filter })) : dispatch(getLogsData(logsData));
        setOpenFilter(false);
    }

    const handleOnChange = (e, row) => {
        const { session } = row;

        const selectedList = rows.map((row) => (row.session !== session ? row : { ...row, checked: !row.checked }));
        const listToDelete = e.target.checked
            ? [...logsToDelete, session]
            : logsToDelete.filter((sid) => sid !== session);

        setRows(selectedList);
        setLogsToDelete(listToDelete);
    };

    useEffect(() => {
        // eslint-disable-next-line
        logs = filteredData || logsData;
        if (logs) {
            let tempRows = logs.map((elem) => {
                let rowsData = createData(
                    elem.user? elem.user.email: '',
                    elem.sid,
                    elem.deviceId,
                    elem.device? elem.device.model: '',
                    elem.status,
                    elem.start,
                    elem.stop,
                    elem.artifacts,
                    elem.customFields
                );
                return { ...rowsData, checked: false };
            });

            tempRows = tempRows.map((row) => {
                const checked = logsToDelete.filter((sid) => sid === row.session).length;
                return { ...row, checked };
            });

            if (tempRows) {
                setRows(tempRows);
            }
        }
    }, [logsData, filteredData]);

    useEffect(() => {
        document.getElementById('table-execution').scrollTo(0, 0);
    }, [page]);

    return (
        <>
            <Paper data-testid="logs-list" elevation={0}>
                {rows ? (
                    <>
                        <EnhancedTableToolbar
                            {...{
                                filter,
                                rowsPerPage,
                                isFilterApplied,
                                logsToDelete,
                                setFilter,
                                setFilteredData,
                                setIsFilterApplied,
                                setPage,
                                setOpenFilter,
                                setLogsToDelete,
                            }}
                        />

                        <TableContainer className={`table ${classes.scrollHoriz}`}>
                            <Table className="table">
                                <>
                                    <EnhancedTableHead
                                        {...{
                                            filter,
                                            rows,
                                            setRows,
                                            rowsPerPage,
                                            logsToDelete,
                                            setLogsToDelete,
                                            sortConfig,
                                            setSortLabel,
                                            setSortConfig,
                                            setPage,
                                        }}
                                    />
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            return (
                                                <TableRow key={row.session + index} className="table-row">
                                                    {userRole === 'superuser' ? (
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                size="small"
                                                                color="default"
                                                                checked={Boolean(row.checked)}
                                                                onChange={(e) => handleOnChange(e, row)}
                                                            />
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell />
                                                    )}

                                                    {
                                                        [...Object.entries(row)].map(([key, value], idx) => {
                                                            if (key === 'checked') {
                                                                return null;
                                                            }
                                                            if (key === 'start' || key === 'stop') {
                                                                return (
                                                                    <TableCell
                                                                        key={`${value} + ${idx}`}
                                                                        className="table-element"
                                                                        align="left">
                                                                        <div className="table-content log-info">
                                                                            {key === 'start'
                                                                                ? new Date(row.start).toUTCString()
                                                                                : row.stop
                                                                                ? new Date(row.stop).toUTCString()
                                                                                : ''}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            }
                                                            if (key === 'artifacts') {
                                                                return (
                                                                    <TableCell
                                                                        key={`${value} + ${idx}`}
                                                                        className="table-element"
                                                                        align="left">
                                                                        <div className="table-content log-info size">
                                                                            {value.some((item) => item.link) ? (
                                                                                <Artifacts artifacts={value} />
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            }
                                                            return (
                                                                <TableCell
                                                                    key={`${value} + ${idx}`}
                                                                    className="table-element"
                                                                    align="left">
                                                                    <div className="table-content log-info">
                                                                        {value}
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                        })
                                                        /* Do not delete - need for extra information */
                                                        /* <TableCell className="table-element" align="left">
                                                <p
                                                    onClick={() => {
                                                        dispatch(logsInformationPopup(true));
                                                        dispatch(setTagsData(row.customFields));
                                                    }}
                                                >
                                                    ...
                                                </p>
                                            </TableCell> */
                                                    }
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </>
                                {isFilterApplied && !rows.length && <p className="table-element-message">No rows</p>}
                                {isLogsPopup && (
                                    <div className={classes.popover}>
                                        <Grow in={isLogsPopup}>{popoverContent(tagsData)}</Grow>
                                    </div>
                                )}
                            </Table>
                        </TableContainer>

                        <TablePagination
                            page={page}
                            component="div"
                            count={logsCount}
                            className="pagination"
                            rowsPerPage={rowsPerPage}
                            data-testid="logs-pagination"
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[30, 50, 100]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Spiner />
                )}
            </Paper>

            <Modal
                disablePortal
                open={openFilter}
                disableAutoFocus
                disableEnforceFocus
                className={classes.modal}
                onClose={() => setOpenFilter((prev) => !prev)}>
                <DraggableCore>
                    <div className={classes.container}>
                        <CloseIcon className={classes.closeIcon} onClick={() => setOpenFilter((prev) => !prev)} />
                        <TextField
                            name="email"
                            label="Email"
                            className={classes.input}
                            defaultValue={filter['email']}
                            onChange={handleFilterChange}
                            onDoubleClick={(event) => event.target.select()}
                        />

                        <FormControl className={classes.form}>
                            <InputLabel htmlFor="statusSelect">Status</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    id: 'statusSelect',
                                    name: 'status',
                                }}
                                className={classes.input}
                                onChange={handleFilterChange}
                                value={filter.status}>
                                {[null, ...executionLogStatus].map((status) => {
                                    return (
                                        <option key={v4()} value={status}>
                                            {status}
                                        </option>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            name="deviceId"
                            label="Device"
                            className={classes.input}
                            defaultValue={filter['deviceId']}
                            onChange={handleFilterChange}
                            onDoubleClick={(event) => event.target.select()}
                        />

                        <TextField
                            name="sid"
                            label="Session"
                            className={classes.input}
                            defaultValue={filter['sid']}
                            onChange={handleFilterChange}
                            onDoubleClick={(event) => event.target.select()}
                        />

                        <Button
                            className={classes.executeBtn}
                            variant="contained"
                            type="submit"
                            onClick={() => {
                                setPage(0);
                                filterLogs();
                                setIsFilterApplied(true);
                            }}>
                            Apply
                        </Button>
                    </div>
                </DraggableCore>
            </Modal>
        </>
    );
};
