import {
    REAL_DEVICES,
    INSPECTOR_XY,
    SELECT_DEVICE,
    VIRTUAL_DEVICES,
    SET_DEVICE_CAPABILITIES,
    SET_DEVICE_BLE,
    IS_VIRTUAL_DEVICES_SWITCH,
    SET_SEARCH,
    SET_FILTERED_DATA,
    SET_DEVICE_PLATFORM,
} from '../storeActionTypes';

const initialState = {
    activeDevice: null,
    deviceCapabilities: null,
    relations: [],
    virtualDevicesSwitch: false,
    realDevices: [],
    virtualDevices: [],
    inspectorXY: null,
    search: '',
    filteredData: null,
    devicePlatform: ['ios', 'android'],
};

export function deviceReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SELECT_DEVICE: {
            return { ...state, activeDevice: payload };
        }
        case SET_DEVICE_CAPABILITIES: {
            return { ...state, deviceCapabilities: payload };
        }
        case SET_DEVICE_BLE: {
            return { ...state, relations: payload };
        }
        case IS_VIRTUAL_DEVICES_SWITCH: {
            return { ...state, virtualDevicesSwitch: payload };
        }
        case REAL_DEVICES: {
            return { ...state, realDevices: payload };
        }
        case VIRTUAL_DEVICES: {
            return { ...state, virtualDevices: payload };
        }
        case INSPECTOR_XY: {
            return { ...state, inspectorXY: payload };
        }
        case SET_SEARCH: {
            return { ...state, search: payload };
        }
        case SET_FILTERED_DATA: {
            return { ...state, filteredData: payload };
        }
        case SET_DEVICE_PLATFORM: {
            return { ...state, devicePlatform: payload };
        }
        default: {
            return state;
        }
    }
}
