import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DraggableCore from 'react-draggable';
import { v4 } from 'uuid';

import DeviceSettings from './DeviceSettings/DeviceSettings';
import BleSettings from './BleSettings/BleSettings';
import AgentSettings from './AgentSettings/AgentSettings';

import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Switch from '@material-ui/core/Switch';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { agentControl, agentControlNEO, deviceControl, deviceControlNEO } from '../../../../store/admin/admin.actions';
import { DEVICES_CONTROL, DEVICE_STATUS_CODES } from '../../../../variables/common';

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
    container: {
        width: '250px',
        position: 'absolute',
        top: '80px',
        right: '25px',
        padding: '15px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        background: ' #fff',
        boxShadow: '4px 4px 16px -8px rgba(0, 0, 0, 0.68)',
        border: '1px solid silver',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    form: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
    executeBtn: {
        width: '100%',
        height: '37px',
        backgroundColor: '$secondary-color',
        borderRadius: '4px',
        textAlign: 'center',
        color: '$text-color-primary',
        marginTop: '25px',
        fontSize: '14px',
    },
    mainSwitch: {
        '&$checked': {
            color: '#53A337',
            '& + $track': {
                backgroundColor: '#53A337',
            },
        },
    },
    neoSwitch: {
        '&$checked': {
            color: '#314f72',
            '& + $track': {
                backgroundColor: '#314f72',
            },
        },
    },
    secondarySwitch: {
        '&$checked': {
            color: '#4d9bd5',
            '& + $track': {
                backgroundColor: '#4d9bd5',
            },
        },
    },
    sizeSmall: {
        padding: '0',
    },
    track: {},
    checked: {},
});
const EnhancedTableToolbar = ({ filteredRows, setFilter, setFilteredRows, setOpenFilter }) => {
    return (
        <div className="filter-tooltip-wrapper agent-filter">
            {filteredRows && (
                <Tooltip title="clear filters">
                    <IconButton
                        aria-label="clear list"
                        onClick={() => {
                            setFilter({});
                            setFilteredRows(null);
                        }}>
                        <ClearAllIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="filter">
                <IconButton
                    data-testid="filter-icon"
                    aria-label="filter list"
                    onClick={() => {
                        setOpenFilter((prev) => !prev);
                    }}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};
export const Devices = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({});
    const [filteredRows, setFilteredRows] = useState(null);
    const [openFilter, setOpenFilter] = useState(false);

    const { FILTER, FILTERED_BY } = DEVICES_CONTROL;
    const allAgents = useSelector((state) => state.admin.allAgents, shallowEqual);
    const columns = [
        { id: 'collaps', align: 'left', width: '1%' },
        { id: 'restart', align: 'left', width: '1%' },
        { id: 'name', label: 'Agent', align: 'left', width: '12%' },
        { id: 'status', label: 'On/Off', align: 'center', width: '6%' },
        { id: 'enableOnNEO', label: 'on NEO', align: 'center', width: '6%' },
        { id: 'devices', label: 'Devices', align: 'left', width: '72%' },
    ];

    function setOpen(index) {
        const temp = rows;
        temp[index].open = !temp[index].open;
        setRows([...temp]);
    }

    function expandListDevices(device) {
        let tempRows = rows;
        let temp = tempRows.map((row) => {
            if (row.name === device.agent) {
                let devices = row.devices.map((_device) => {
                    if (_device.id === device.id) {
                        return { ..._device, isOpen: !_device.isOpen };
                    }
                    return _device;
                });
                return { ...row, devices };
            }
            return row;
        });

        setRows([...temp]);
    }

    function setAgentStatus(devices) {
        let status = [];
        devices && devices.map((device) => status.push(device.status));
        return !status.every((item) => item === DEVICE_STATUS_CODES.OFFLINE);
    }

    function setAgentNEOStatus(devices) {
        let status = [];
        devices && devices.map((device) => status.push(device.metadata.enableNEO));
        return !status.every((item) => item === false);
    }

    function setDeviceStatus(device) {
        return device.status !== DEVICE_STATUS_CODES.OFFLINE;
    }

    function handleAgentStatus(row, status) {
        dispatch(agentControl(row.name, status));
        // if (!row.available) {
        // dispatch(agentControl(row.name, status));
        // } else {
        //     let statuses = [];
        //     row.devices.map((device) => statuses.push(device.status));
        //     if (statuses.every((item) => item === DEVICE_STATUS_CODES.AVAILABLE)) {
        //         dispatch(agentControl(row.name, status));
        //     }
        // }

        // FOR WHAT?
    }

    function handleAgentNEOStatus(row, status) {
        dispatch(agentControlNEO(row.name, status));
    }

    function handleDeviceStatus(device, status) {
        if (device.status === DEVICE_STATUS_CODES.AVAILABLE || device.status === DEVICE_STATUS_CODES.OFFLINE) {
            dispatch(deviceControl(device.id, status));
        }
    }

    function handleDeviceStatusNEO(device, status) {
        dispatch(deviceControlNEO(device.id, status));
    }

    const renderDeviceRow = (device) => {
        return (
            <Paper tabIndex={-1} elevation={1} key={v4()} role="checkbox" className="device-row">
                <div className="device-cell device-name-cell">
                    <div className="device-name-group">
                        {device.relations && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => expandListDevices(device)}
                                classes={{ sizeSmall: classes.sizeSmall }}>
                                {device.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                        <p>{device.model}</p>
                    </div>
                    {device.relations && device.isOpen && (
                        <ul className="device-name-list">
                            {device.relations.map((device) => {
                                return <li key={v4()}>{device}</li>;
                            })}
                        </ul>
                    )}
                </div>
                <BleSettings device={device} />
                <div className="device-cell">
                    <p>{device.manufacturer}</p>
                </div>
                <div className="device-cell device-id-cell">
                    <p>{device.id}</p>
                </div>
                <div className="device-cell device-type-cell">
                    {device.type !== 'real' ? <p>Virtual</p> : <p>Real</p>}
                </div>
                <div className="device-switch">
                    <Switch
                        checked={setDeviceStatus(device)}
                        onChange={(event) => handleDeviceStatus(device, event.target.checked)}
                        name="checkedA"
                        color="primary"
                        size="small"
                        classes={{
                            colorPrimary: classes.secondarySwitch,
                            switchBase: classes.switchBase,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
                <div className="device-cell device-title-cell">
                    <p>{device.status}</p>
                </div>
                <div className="device-switch">
                    <Switch
                        checked={setDeviceStatus(device) ? device.metadata.enableNEO : setDeviceStatus(device)}
                        onChange={(event) => handleDeviceStatusNEO(device, event.target.checked)}
                        name="checkedB"
                        color="primary"
                        size="small"
                        classes={{
                            colorPrimary: classes.neoSwitch,
                            switchBase: classes.switchBase,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        disabled={!setDeviceStatus(device)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
                <div className="device-cell device-title-cell">
                    <p>on NEO</p>
                </div>
                <DeviceSettings device={device} />
            </Paper>
        );
    };

    const renderCell = (row, index) => {
        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{ height: '68px' }}>
                {columns.map((column) => {
                    if (column.id === 'collaps') {
                        return (
                            <TableCell key={column.id} className="cell-root">
                                {row.devices.length ? (
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpen(index)}
                                        classes={{ sizeSmall: classes.sizeSmall }}>
                                        {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                ) : null}
                            </TableCell>
                        );
                    }

                    if (column.id === 'restart') {
                        return (
                            <TableCell key={column.id} className="cell-root">
                                <AgentSettings {...row} />
                            </TableCell>
                        );
                    }

                    if (column.id === 'status') {
                        return (
                            <TableCell key={column.id} align={column.align} className="action-cell cell-root">
                                <Switch
                                    checked={row.available}
                                    onChange={(event) => handleAgentStatus(row, event.target.checked)}
                                    color="primary"
                                    size="small"
                                    classes={{
                                        colorPrimary: classes.mainSwitch,
                                        switchBase: classes.switchBase,
                                        track: classes.track,
                                        checked: classes.checked,
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </TableCell>
                        );
                    }

                    if (column.id === 'enableOnNEO') {
                        return (
                            <TableCell key={column.id} align={column.align} className="action-cell cell-root">
                                <Switch
                                    checked={row.available ? row.enanleOnNEO : row.available}
                                    onChange={(event) => handleAgentNEOStatus(row, event.target.checked)}
                                    color="primary"
                                    size="small"
                                    classes={{
                                        colorPrimary: classes.neoSwitch,
                                        switchBase: classes.switchBase,
                                        track: classes.track,
                                        checked: classes.checked,
                                    }}
                                    disabled={Boolean(!row.available)}
                                    data-testid="device-checkbox-test"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </TableCell>
                        );
                    }

                    if (column.id === 'devices') {
                        return (
                            <TableCell key={column.id} align={column.align} className="cell-root">
                                <Collapse in={row.open} timeout="auto" unmountOnExit>
                                    <div className="devices-wrap">
                                        {row.devices.map((device) => renderDeviceRow(device, index))}
                                    </div>
                                </Collapse>

                                {!row.devices.length && (
                                    <div className="devices-wrap">
                                        <p className="device-row">No connected devices</p>
                                    </div>
                                )}
                            </TableCell>
                        );
                    } else {
                        const flutterVersion = row?.metaData?.softwareVersion?.flutterVersion;
                        const activityTime = new Date(row?.activityTime).toLocaleString();
                        const status = row?.status.toUpperCase();
                        return (
                            <TableCell key={column.id} align={column.align} className="cell-root agent-cell">
                                <div>
                                    <div style={{ margin: '0 0 6px 0' }}>{row[column.id]}</div>

                                    <div className="agent-version">
                                        {row?.agentVersion ? <span>Agent version: {row?.agentVersion}</span> : null}
                                    </div>

                                    <div className="agent-version">
                                        {row?.agentVersion ? <span>Status: {status}</span> : null}
                                    </div>

                                    <div className="agent-version">
                                        {row?.activityTime ? <span>Activity: {activityTime}</span> : null}
                                    </div>

                                    <div className="agent-version">
                                        {row?.agentIP ? <span>Agent IP: {row?.agentIP}</span> : null}
                                    </div>

                                    <div className="flutter-info">
                                        {flutterVersion ? (
                                            <>
                                                <p>Flutter version: {flutterVersion.flutter}</p>
                                                <p>Dart server version: {flutterVersion.dart}</p>
                                                <p>Release channel: {flutterVersion.channel}</p>
                                            </>
                                        ) : (
                                            <p>Flutter version: not installed</p>
                                        )}
                                    </div>
                                </div>
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        );
    };

    function handleFilterChange(e) {
        const { name, value } = e.target;
        setFilter((prev) => {
            if (value) {
                return { ...prev, [name]: value };
            } else {
                let temp = prev;
                delete temp[name];
                return { ...temp };
            }
        });
    }

    function filterDevice() {
        if (Object.keys(filter)?.length) {
            let temp = rows;
            for (let key in filter) {
                if (filter[key]) {
                    temp = temp.filter((device) => device[key]?.toUpperCase() === filter[key]?.toUpperCase());
                }
            }

            setFilteredRows([...temp]);
            setOpenFilter((prev) => !prev);
        }
    }

    const renderFormControl = (type, data) => {
        const value = filter[type];

        return (
            <FormControl className={classes.form}>
                <InputLabel id={`${type}-select-label`}>{type.toUpperCase()}</InputLabel>

                <Select
                    native
                    inputProps={{
                        name: `${type}`,
                        id: `${type}-select`,
                        placeholder: `select-${type}`,
                    }}
                    labelId={`${type}-select-label`}
                    className={classes.input}
                    onChange={handleFilterChange}
                    placeholder={`select-${type}`}>
                    {value ? <option value={value}>{value}</option> : <option value="" />}
                    {data.map((item) =>
                        value !== item ? (
                            <option value={item} key={v4()}>
                                {item}
                            </option>
                        ) : null
                    )}
                </Select>
            </FormControl>
        );
    };

    useEffect(() => {
        if (allAgents) {
            let children = [];
            children = Object.keys(allAgents).map((key) => {
                return {
                    name: key,
                    devices: allAgents[key]?.devices.map((device) => ({ ...device, isOpen: false })),
                    id: key,
                    restart: true,
                    agentVersion: allAgents[key]?.metaData?.agentVersion,
                    agentIP: allAgents[key]?.metaData?.agentIP,
                    metaData: allAgents[key]?.metaData,
                    status: allAgents[key]?.status,
                    os: allAgents[key]?.metaData?.agentOS,
                    activityTime: allAgents[key]?.activityTime,
                };
            });

            if (children.length) {
                // eslint-disable-next-line
                children.map((agent) => {
                    agent.available = setAgentStatus(agent.devices);
                    agent.enanleOnNEO = setAgentNEOStatus(agent.devices);
                    agent.open = true;
                });
            }

            setRows([...children]);
        }
    }, [allAgents]);

    return (
        <div className="users-wrap">
            <Paper className={classes.root} variant="elevation" elevation={3}>
                {rows.length ? (
                    <EnhancedTableToolbar {...{ filteredRows, setFilter, setFilteredRows, setOpenFilter }} />
                ) : null}
                <TableContainer className="container">
                    <Table stickyHeader={true} aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        width={column.width}
                                        className="device-header"
                                        classes={{
                                            root: classes.header,
                                        }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {filteredRows ? (
                            <TableBody>{filteredRows?.map((row, index) => renderCell(row, index))}</TableBody>
                        ) : (
                            <TableBody>{rows?.map((row, index) => renderCell(row, index))}</TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Paper>

            <Modal
                disablePortal
                open={openFilter}
                disableAutoFocus
                disableEnforceFocus
                className={classes.modal}
                onClose={() => setOpenFilter((prev) => !prev)}>
                <DraggableCore>
                    <div className={classes.container}>
                        <CloseIcon className={classes.closeIcon} onClick={() => setOpenFilter((prev) => !prev)} />

                        {renderFormControl(FILTERED_BY.OS, FILTER.OS)}
                        {renderFormControl(FILTERED_BY.STATUS, FILTER.STATUS)}

                        <Button
                            className={classes.executeBtn}
                            variant="contained"
                            type="submit"
                            onClick={() => filterDevice()}>
                            Apply
                        </Button>
                    </div>
                </DraggableCore>
            </Modal>
        </div>
    );
};
