import React from 'react';

import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmDialog = (props) => {
    const { isOpen, setIsOpen, isPopupOpen, value, action, children } = props;

    return (
        <Dialog
            open={isOpen[value]}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => setIsOpen({ ...isOpen, [value]: false })}>
            <DialogTitle id="alert-dialog-title">
                <HelpIcon className="reboot-confirm-icon" />
                {children}
            </DialogTitle>

            <DialogActions>
                <Button color="primary" onClick={!isPopupOpen && action}>
                    Confirm
                </Button>

                <Button color="primary" onClick={() => setIsOpen({ ...isOpen, [value]: false })}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
