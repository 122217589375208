import { NOTIFICATION_MESSAGE } from '../storeActionTypes';

export const NotificationMessage = (data) => ({
    type: NOTIFICATION_MESSAGE,
    payload: data,
});

export const handlerNotification = (end) => (dispatch) => {
    setTimeout(() => {
        dispatch(NotificationMessage(undefined));
    }, end ?? 4000);
};
