import axios from 'axios';
import { BASE_URL } from '../../variables/common';
import errorHandler from '../../utils/errorHandler';
import { deduction } from '../../../static/notifications-sounds';
import { NotificationMessage, handlerNotification } from '../notification/notification.actions';
import { SET_TEXT_FILE, SET_VIDEO_PATH } from '../storeActionTypes';
import fileDownload from 'js-file-download';

export const setVideoUrl = (data) => ({
    type: SET_VIDEO_PATH,
    payload: data,
});

export const setTextFile = (data) => ({
    type: SET_TEXT_FILE,
    payload: data,
});

export const setVideoPath = (data) => (dispatch) => {
    axios({
        method: 'GET',
        url: BASE_URL + '/execution/signed-url',
        params: {
            path: data.link,
            isPreview: true
        },
    }).then((res) => {
        console.log('res: ', res);
        dispatch(setVideoUrl(res.data.url));
    });
};

export const getTextFile = (path) => (dispatch) => {
    axios({
        method: 'GET',
        url: BASE_URL + '/preview/text-file',
        params: { path: path },
    })
        .then((response) => {
            dispatch(setTextFile(response.data));
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const downloadFile = (data) => (dispatch) => {
    const { path, type, sid } = data;
    axios({
        method: 'GET',
        url: `${BASE_URL}/execution/signed-url`,
        params: { path: path },
    })
    .then((res) => {
        let axiosConfig = {
            method: 'GET',
            url: res.data.url,
            responseType: 'blob',
        };

        if (res.data.noAdditionalAuth) {
            axiosConfig.transformRequest = [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data
                }]
        }
        return axios(axiosConfig)
    })
    .then((response) => {
        // generate artifact file name
        let _fname = `${type}_${sid}.` + `${(type == 'video') ? 'mp4':'zip'}`;
        fileDownload(response.data, _fname);
    })
    .catch((error) => {
        errorHandler(error);
        deduction.play();
        dispatch(NotificationMessage(error.response.data));
        dispatch(handlerNotification());
    });
};
