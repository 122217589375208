import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as Yup from 'yup';
import { useField, Form, Formik } from 'formik';

import Button from '@material-ui/core/Button';

import { userRole } from '../../variables/common';
import { userRegistration } from '../../store/user/user.actions';

const adminRoles = ['user'];

const MyTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const role = useSelector((state) => state.user.role, shallowEqual);
    const options = role === 'superuser' ? userRole : adminRoles;

    return (
        <div>
            <div className="input-container">
                <p className="label">{label}</p>

                {label.toLowerCase() === 'role' ? (
                    <select className="input" {...field}>
                        {options.map((option, index) => (
                            <option value={option} key={option + index}>
                                {option}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input className="input" {...field} {...props} />
                )}
            </div>

            {meta.touched && meta.error ? (
                <div className="error-login">{meta.error}</div>
            ) : (
                <div className="error-login" />
            )}
        </div>
    );
};

export const Registration = ({ setDrawerState, handleRegistration }) => {
    const dispath = useDispatch();

    const onSubmit = (values) => {
        let { group } = values;
        group = group.split(',').map((group) => group.trim());

        dispath(userRegistration({ ...values, group }));
        setTimeout(() => setDrawerState(false), 1000);
    };

    const newUser = {
        name: '',
        lastName: '',
        email: '',
        role: 'user',
        password: '',
        group: '',
    };

    return (
        <div className="login-wrapper" data-testid="registration-test">
            <div className="login-content">
                <div className="login-title">Registration</div>
                <Formik
                    initialValues={newUser}
                    validationSchema={Yup.object({
                        group: Yup.string().required('Required'),
                        email: Yup.string().email('Invalid email address').required('Required'),
                        password: Yup.string().min(6, 'Must be 6 characters or more').required('Required'),
                    })}
                    onSubmit={(values, { resetForm }) => {
                        handleRegistration(values, onSubmit);
                        resetForm(newUser);
                    }}>
                    <Form className="registration-form">
                        <MyTextField name="name" label="Name" data-testid={'reg-input-name'} />
                        <MyTextField name="lastName" label="Last name" data-testid={'reg-input-lname'} />
                        <MyTextField name="group" label="Group" data-testid={'reg-input-group'} />
                        <MyTextField name="email" type="email" label="Email" data-testid={'reg-input-email'} />
                        <MyTextField name="role" label="Role" data-testid={'reg-input-role'} />
                        <MyTextField name="password" type="password" label="Password" data-testid={'reg-input-pass'} />
                        <Button type="submit" data-testid={'reg-btn-submit'} className="login-submit">
                            Register new user
                        </Button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
