import axios from 'axios';
import auth from '../../utils/auth';
import { BASE_URL } from '../../variables/common';
import errorHandler from '../../utils/errorHandler';
import { NotificationMessage, handlerNotification } from '../notification/notification.actions';
import {
    ALL_USERS,
    SET_USER_ROLE,
    SET_USER_GROUP,
    SET_USER_EMAIL,
    SET_SERVER_VERSION,
    SET_USER_ERROR,
    SET_COGNITO_URL,
} from '../storeActionTypes';

export const allUsers = (data) => ({
    type: ALL_USERS,
    payload: data,
});

export const setUserRole = (data) => ({
    type: SET_USER_ROLE,
    payload: data,
});

export const setUserGroup = (data) => ({
    type: SET_USER_GROUP,
    payload: data,
});

export const setUserEmail = (data) => ({
    type: SET_USER_EMAIL,
    payload: data,
});

export const setUserError = (data) => ({
    type: SET_USER_ERROR,
    payload: data,
});

export const setServerVersion = (data) => ({
    type: SET_SERVER_VERSION,
    payload: data,
});

export const setCognitoURL = (data) => ({
    type: SET_COGNITO_URL,
    payload: data,
});

export const getLogin = () => (dispatch) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/user`,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            dispatch(setCognitoURL(response.data.cognitoURL));
            dispatch(setServerVersion(response.data.serverVersion));
        })
        .catch((error) => {
            dispatch(setCognitoURL(null));
            dispatch(setServerVersion(null));
            errorHandler(error);
        });
};

export const postLogin = (data) => (dispatch) => {
    const { email, password } = data;
    axios({
        method: 'POST',
        url: BASE_URL + '/user/login',
        data: {
            email,
            password,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            dispatch(setUserError(null));

            auth.login(
                response.data.user.token,
                response.data.user.email,
                response.data.user.userClass,
                response.data.user.fullName,
                response.data.user.group
            );
        })
        .catch((error) => {
            dispatch(setUserError(true));
            errorHandler(error);
        });
};

export const userRegistration = (data) => (dispatch) => {
    const { name, lastName, group, email, role, password } = data;
    axios({
        method: 'POST',
        url: BASE_URL + '/admin/users/register',
        data: {
            name,
            lastName,
            group,
            email,
            role,
            password,
        },
    })
        .then((response) => {
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
            dispatch(getUsers());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
            dispatch(getUsers());
        });
};

export const updateUserData = (data) => (dispatch) => {
    axios({
        method: 'PUT',
        url: `${BASE_URL}/admin/users/update`,
        data: data,
    })
        .then((response) => {
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
            dispatch(getUsers());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
            dispatch(getUsers());
        });
};

export const deleteUser = (data) => (dispatch) => {
    axios({
        method: 'DELETE',
        url: `${BASE_URL}/admin/users/${data}`,
    })
        .then((response) => {
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
            dispatch(getUsers());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
            dispatch(getUsers());
        });
};

export const getUsers = () => (dispatch, getState) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/admin/users/list`,
    })
        .then((response) => {
            dispatch(allUsers(response.data));

            let updateUserGroup;
            let { email, usersData } = getState().user;

            if (usersData) {
                updateUserGroup = Object.values(usersData)
                    .map(({ users }) => users)
                    .flat()
                    .filter((item) => item.email === email)[0]?.group;

                dispatch(setUserGroup(updateUserGroup));
            } else {
                dispatch(setUserGroup(''));
            }
        })
        .catch((error) => {
            errorHandler(error);
        });
};
