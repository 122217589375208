import axios from 'axios';
import { BASE_URL } from '../../variables/common';
import { handlerNotification, NotificationMessage } from '../notification/notification.actions';
import { CANCEL_MAINTENANCE_MESSAGE, SET_MAINTENANCE_MESSAGE } from '../storeActionTypes';

export const setMaintenanceMessage = (data) => ({
    type: SET_MAINTENANCE_MESSAGE,
    payload: data,
});
export const deleteMaintenanceMessage = () => ({
    type: CANCEL_MAINTENANCE_MESSAGE,
    payload: null,
});

export const sendMaintenanceMessage = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/admin/maintenance',
        data,
    })
        .then(() => {
            dispatch(
                NotificationMessage({
                    msg: 'Notification sent successfully',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const cancelMaintenanceMessage = () => (dispatch) => {
    axios({
        method: 'DELETE',
        url: BASE_URL + '/admin/maintenance',
    })
        .then(() => {
            dispatch(
                NotificationMessage({
                    msg: 'Notification canceled successfully',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const sendRefreshNEORP = () => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/admin/maintenance/rp/refresh',
    })
        .then(() => {
            dispatch(
                NotificationMessage({
                    msg: 'Refresh request was sent',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};
export const sendForceSyncRepo = () => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/admin/maintenance/repo/sync',
    })
        .then(() => {
            dispatch(
                NotificationMessage({
                    msg: 'Sync request was sent',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

