import React, { useEffect, useState } from 'react';
import Spiner from '../Common/Loader';
import AppleWatchSkin from './Skin';

const AppleWatch = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //temporary solution, artificial loading delay
        setTimeout(() => setIsLoading(false), 1500);
    }, []);

    return (
        <div className="apple-watch">
            <AppleWatchSkin />
            {isLoading && <Spiner />}
        </div>
    );
};

export default AppleWatch;
