import { ALL_AGENTS, VIRTUAL_DEVICES_AGENT, USED_VIRTUAL_DEVICES, SET_DEVICES_TIMELINE, AGENT_LOGS } from '../storeActionTypes';

const initialState = {
    allAgents: null,
    virtualDevicesAgent: null,
    agentLogs: [],
    usedVirtualDevices: [],
    chartData: null,
};

export function adminReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ALL_AGENTS: {
            return { ...state, allAgents: payload };
        }
        case VIRTUAL_DEVICES_AGENT: {
            return { ...state, virtualDevicesAgent: payload };
        }
        case AGENT_LOGS: {
            return { ...state, agentLogs: payload };
        }
        case USED_VIRTUAL_DEVICES: {
            return { ...state, usedVirtualDevices: payload };
        }
        case SET_DEVICES_TIMELINE: {
            return { ...state, chartData: payload };
        }
        default: {
            return state;
        }
    }
}
