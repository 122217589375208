import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { isVirtualDevicesSwitch, setFilteredData } from '../../store/device/device.actions';
import { HeaderTitle, setTabNumber } from '../../store/auxiliary/auxiliary.actions';
import { setDevicePlatform } from '../../store/device/device.actions';
import { getLogsData } from '../../store/execution/execution.actions';
import { setSelectedRepositorium } from '../../store/repositorium/repositorium.actions';

import DeviceList from '../Main/DeviceList';
import DeviceDashboard from '../DeviceDashboard';
import { Repositorium } from '../Repositorium';
import { LogsCenter } from '../ExecutionCenter/index';

import { AppBar, Tabs, Tab, Box, FormControlLabel, Switch } from '@material-ui/core/';
import { TITLE, BOARD, APPLE_WATCH } from '../../variables/common';

import { ReactComponent as LogoIOS } from '../../../static/images/logo_iOS.svg';
import { ReactComponent as LogoWatch } from '../../../static/images/logo_watch.svg';
import { ReactComponent as LogoBoard } from '../../../static/images/logo_board.svg';
import { ReactComponent as LogoAndroid } from '../../../static/images/logo_android.svg';
import { ReactComponent as LogoDongle } from '../../../static/images/logo_dongle.svg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3} id="table-execution" classes={{ root: 'table-container' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const MySwitch = React.forwardRef(({ handleCheck, handlerSwitch, virtualDevicesSwitch }, ref) => {
    return (
        <FormControlLabel
            className="switch-control"
            ref={ref}
            control={
                <Switch
                    color="primary"
                    data-testid="tabs-swich-test"
                    checked={virtualDevicesSwitch}
                    onChange={() => handleCheck(null, handlerSwitch)}
                    label="Virtual Devices"
                />
            }
            label="Virtual Devices"
            labelPlacement="start"
        />
    );
});

export const TabsPage = ({ handleCheck }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handlerSwitch = () => {
        dispatch(setFilteredData(null));
        dispatch(isVirtualDevicesSwitch(!virtualDevicesSwitch));
    };
    // default states of "Device platform" switches
    const [isActiveButton, setIsActiveButton] = useState({ ios: true, android: true, dongle: false });

    const tabNumber = useSelector((state) => state.auxiliary.tabNumber, shallowEqual);
    const devicePlatform = useSelector((state) => state.device.devicePlatform, shallowEqual);
    const virtualDevicesSwitch = useSelector((state) => state.device.virtualDevicesSwitch, shallowEqual);
    const ref = React.createRef();

    const handleDevicesPlatform = (platform) => {
        const isExist = devicePlatform.some((item) => item === platform);

        isExist
            ? dispatch(setDevicePlatform(devicePlatform.filter((item) => item !== platform)))
            : dispatch(setDevicePlatform([...devicePlatform, platform]));
    };

    return (
        <div className="main-container">
            <AppBar position="static" className="tabs-wrapper">
                <div style={{ display: 'flex' }}>
                    <Tabs value={tabNumber}>
                        <Tab
                            className="controll-tab"
                            label={TITLE.DEVICE_LIST}
                            onClick={() => {
                                dispatch(HeaderTitle(TITLE.DEVICE_LIST));
                                dispatch(setTabNumber(0));
                            }}
                        />

                        <Tab
                            label={TITLE.DEVICE_DASHBOARD}
                            className="controll-tab"
                            onClick={() => {
                                dispatch(HeaderTitle(TITLE.DEVICE_DASHBOARD));
                                dispatch(setTabNumber(1));
                            }}
                        />

                        <Tab
                            label={TITLE.REPOSITORIUM}
                            className="controll-tab"
                            onClick={() => {
                                dispatch(setSelectedRepositorium(null));
                                dispatch(HeaderTitle(TITLE.REPOSITORIUM));
                                dispatch(setTabNumber(2));
                            }}
                        />

                        <Tab
                            label={TITLE.EXECUTION_CENTER}
                            className="controll-tab"
                            onClick={() => {
                                const logsData = { page: 0, size: 50, sort: { label: 'start', param: 'desc' } };

                                dispatch(HeaderTitle(TITLE.EXECUTION_CENTER));
                                dispatch(setTabNumber(3));
                                dispatch(getLogsData(logsData));
                            }}
                        />
                    </Tabs>

                    {tabNumber === 0 && (
                        <div style={{ display: 'flex' }}>
                            <MySwitch
                                ref={ref}
                                handleCheck={handleCheck}
                                handlerSwitch={handlerSwitch}
                                virtualDevicesSwitch={virtualDevicesSwitch}
                            />
                            <div className="controll-button-group">
                                <LogoIOS
                                    className={isActiveButton.ios ? 'active-btn' : 'disable-btn'}
                                    onClick={() => {
                                        handleDevicesPlatform('ios');
                                        setIsActiveButton({ ...isActiveButton, ios: !isActiveButton.ios });
                                    }}
                                />

                                <LogoAndroid
                                    className={isActiveButton.android ? 'active-btn' : 'disable-btn'}
                                    onClick={() => {
                                        handleDevicesPlatform('android');
                                        setIsActiveButton({ ...isActiveButton, android: !isActiveButton.android });
                                    }}
                                />

                                <LogoDongle
                                    className={isActiveButton.dongle ? 'active-btn' : 'disable-btn'}
                                    onClick={() => {
                                        handleDevicesPlatform('ble-serial-port');
                                        setIsActiveButton({ ...isActiveButton, dongle: !isActiveButton.dongle });
                                    }}
                                />

                                <LogoWatch
                                    style={{
                                        cursor: 'not-allowed' /* fill: "#fff", stroke: "#000", strokeWidth: "0.65" */,
                                    }}
                                    className="disable-btn"
                                    //temporary solution, for go to the apple watch page
                                    onClick={() => history.push(APPLE_WATCH)}
                                />

                                <LogoBoard
                                    style={{
                                        cursor: 'not-allowed' /* fill: "#fff", stroke: "#000", strokeWidth: "0.25" */,
                                    }}
                                    className="disable-btn"
                                    //temporary solution, for go to the board page
                                    onClick={() => history.push(BOARD)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </AppBar>

            <TabPanel value={tabNumber} index={0} className="main-table tab-device general-table">
                <DeviceList />
            </TabPanel>

            <TabPanel value={tabNumber} index={1} className="main-table tab-dashboard">
                <DeviceDashboard />
            </TabPanel>

            <TabPanel value={tabNumber} index={2} className="main-table tab-repositorium">
                <Repositorium />
            </TabPanel>

            <TabPanel value={tabNumber} index={3} className="main-table tab-logs-center general-table">
                <LogsCenter />
            </TabPanel>
        </div>
    );
};
