import React from 'react';

export const NotFound = () => {
    return (
        <div className="not-found-container" data-testid="page-not-found">
            <h1>Page not found</h1>
        </div>
    );
};

export default NotFound;
