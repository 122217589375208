import UIfx from 'uifx';
import deductionAudio from '../../static/notifications-sounds/deduction.mp3';
import caseClose from '../../static/notifications-sounds/case-closed.mp3';

export const deduction = new UIfx(deductionAudio, {
    volume: 1.0,
    throttleMs: 200,
});

export const caseCloses = new UIfx(caseClose, {
    volume: 1.0,
    throttleMs: 400,
});
