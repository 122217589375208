import React, { useState, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { MAIN_PAGE } from '../../../variables/common';
import { setSelectedRepositorium } from '../../../store/repositorium/repositorium.actions';

const useStyles = makeStyles({
    root: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
        padding: '5px',
    },
    content: {
        marginTop: 5,
    },
    iconContainer: {
        color: `#1b3656`,
    },
    label: {
        width: '95%',
        overflow: 'hidden',
        fontSize: 14,
        color: `#182d44`,
    },
    title: {
        position: 'sticky',
        top: '0',
        left: '0',
        zIndex: '10',
        height: 56,
        backgroundColor: '#4d9bd5',
        margin: 0,
        fontSize: 15,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        padding: '0 15px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    },
    iconWrapper: {
        position: 'absolute',
        top: '50%',
        right: '0',
        transform: 'translateY(-50%)',
    },
    iconInner: {
        padding: '5px',
    },
    iconButton: {
        width: '20px',
        height: '20px',
        color: '#314f72',
    },
    folderMenu: {
        width: '200px',
    },
});

function getName(baseName, role) {
    if (role === 'user') {
        if (baseName.includes('#')) {
            const position = baseName.indexOf('#');
            return baseName.slice(position + 1);
        }
        return baseName;
    }
    return baseName;
}

const AllFolders = (props) => {
    const { beforeDeleteFile, downLoadFile, openRenameFileModal, protectedFolder, isDisableToDelete } = props;

    const buttonRef = useRef();
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [renameStatus, setRenameStatus] = useState(false);

    const role = useSelector((state) => state.user.role, shallowEqual);
    const activeDevice = useSelector((state) => state.device.activeDevice, shallowEqual);
    const directories = useSelector((state) => state.repositorium.directories[0].children, shallowEqual);

    const handleToggle = (event, nodeIds) => setExpanded(nodeIds);
    const handleSelect = (event, nodeIds) => dispatch(setSelectedRepositorium(nodeIds));

    const createElement = (elem) => {
        return elem?.children?.map((item) => (item.type === 'folder' ? createTree(item) : null));
    };

    const handleMenu = (event) => {
        event.preventDefault();
        setAnchorEl(true);

        const { children, baseName, type } = selected;
        setRenameStatus(protectedFolder(selected));
        setDeleteStatus(isDisableToDelete({ row: { children, baseName, type } }));
    };

    const handleRename = () => {
        const { path, baseName, type } = selected;
        setAnchorEl(false);
        openRenameFileModal({ row: { path, baseName, type } });
    };

    const handleDownload = () => {
        const { path, baseName } = selected;
        setAnchorEl(false);
        downLoadFile({ path, baseName });
    };

    const handleDelete = () => {
        const { path, baseName } = selected;
        setAnchorEl(false);
        beforeDeleteFile({ row: { path, baseName } });
    };

    const menuItems = [
        [handleRename, 'Rename', <EditIcon className="folder-menu-icon" key={v4()} />, renameStatus],
        [handleDelete, 'Delete', <DeleteIcon className="folder-menu-icon" key={v4()} />, deleteStatus],
        [handleDownload, 'Download', <GetAppIcon className="folder-menu-icon" key={v4()} />, false],
    ];

    const setFolderOnTop = (folder) => {
        const idx = directories.findIndex((directory) => directory.id === folder);
        return idx > 0 ? [directories[idx], ...directories.slice(0, idx), ...directories.slice(idx + 1)] : directories;
    };

    const createTree = (elem) => (
        <TreeItem
            key={elem.baseName}
            nodeId={elem.id}
            label={getName(elem.baseName, role)}
            onClick={() => setSelected(elem)}
            classes={{
                content: classes.content,
                iconContainer: classes.iconContainer,
                label: classes.label,
            }}>
            {createElement(elem)}
        </TreeItem>
    );

    return (
        <>
            <p className={classes.title}>
                Repository
                {location.pathname === MAIN_PAGE && selected ? (
                    <span className={classes.iconWrapper}>
                        <IconButton
                            edge="start"
                            ref={buttonRef}
                            aria-label="menu"
                            onClick={handleMenu}
                            className={classes.iconInner}>
                            <Tooltip title="Folder actions">
                                <MoreVertIcon className={classes.iconButton} />
                            </Tooltip>
                        </IconButton>
                    </span>
                ) : null}
            </p>

            {location.pathname === MAIN_PAGE ? (
                <Menu
                    id="menu-appbar"
                    keepMounted={false}
                    open={Boolean(anchorEl)}
                    className={classes.folderMenu}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={() => buttonRef.current}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    {anchorEl &&
                        menuItems.map(([action, label, icon, status]) => (
                            <MenuItem
                                key={label}
                                onClick={action}
                                disabled={status}
                                className="folder-item folder-menu-item">
                                {icon} {label}
                            </MenuItem>
                        ))}
                </Menu>
            ) : null}

            <TreeView
                expanded={expanded}
                className={classes.root}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}>
                {setFolderOnTop('public')?.map((item, index) => {
                    if (location.pathname !== MAIN_PAGE) {
                        const extension = activeDevice.os.toLowerCase() === 'android' ? '.apk' : '.ipa';
                        return JSON.stringify(item).includes(extension) ? createTree(item, index) : null;
                    }

                    return createTree(item, index);
                })}
            </TreeView>
        </>
    );
};

export const Folders = React.memo(AllFolders);
