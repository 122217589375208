import axios from 'axios';
import { BASE_URL } from '../../variables/common';
import errorHandler from '../../utils/errorHandler';
import { caseCloses, deduction } from '../../../static/notifications-sounds';
import { NotificationMessage, handlerNotification } from '../notification/notification.actions';

import { LOGS_DATA, LOGS_INFORMATION_POPUP, SET_TAGS_DATA, SET_LOGS_COUNT } from '../storeActionTypes';

export const setLogsData = (data) => ({
    type: LOGS_DATA,
    payload: data,
});

export const logsInformationPopup = (data) => ({
    type: LOGS_INFORMATION_POPUP,
    payload: data,
});

export const setTagsData = (data) => ({
    type: SET_TAGS_DATA,
    payload: data,
});

export const setLogsCount = (data) => ({
    type: SET_LOGS_COUNT,
    payload: data,
});

export const getLogsData = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + `/execution/list`,
        data: data,
    })
        .then((response) => {
            const { executionData, count } = response.data;

            dispatch(setLogsCount(count));
            dispatch(setLogsData(executionData));
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const deleteLogsData = (data) => (dispatch) => {
    axios({
        method: 'DELETE',
        url: BASE_URL + '/execution/artifacts',
        data: data,
    })
        .then((response) => {
            const logsData = { page: 0, size: 50, sort: { label: 'start', param: 'desc' } };

            dispatch(getLogsData(logsData));
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};
