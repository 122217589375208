import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import adminRoutes from '../../../routes/adminRoutes';
import { USERCONTROL } from '../../../variables/common';

export const Tabpanel = () => {
    const role = useSelector((state) => state.user.role, shallowEqual);
    const { url } = useRouteMatch();
    const { pathname } = useLocation();

    return (
        <div className="tabpanel-wrap">
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={pathname !== USERCONTROL ? pathname : false}
                className="tabs-container"
                classes={{
                    scrollable: 'scrollable-container',
                    indicator: 'tabs-indicator',
                }}>
                {adminRoutes.map(({ path, name, restricted }) => {
                    return role !== 'superuser' && restricted ? null : (
                        <Tab label={name} component={Link} to={`${url}${path}`} value={`${url}${path}`} key={path} />
                    );
                })}
            </Tabs>
        </div>
    );
};
