export const getDeviceInfo = (realDevices, virtualDevices, activeSessions) => {
    if (realDevices?.length || virtualDevices?.length) {
        const deviceInfo = [...realDevices, ...virtualDevices].filter((value) => {
            return activeSessions.find((item) => {
                const [deviceType, deviceId, sid] = [value.type, value.id, item.id];

                return value.id === item.deviceId
                    ? Object.assign(value, item, { id: deviceId, type: deviceType, sid, sessionType: item.type })
                    : false;
            });
        });

        const uniqueSID = [...new Set(deviceInfo.map((item) => item.sid))];
        const newDeviceInfo = uniqueSID.map((sid) => {
            return deviceInfo.find((item) => item.sid === sid);
        });

        return newDeviceInfo;
    }

    return false;
};
