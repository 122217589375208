import React from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import { MAIN_PAGE } from '../../../variables/common';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-footer': {
            padding: '0 70px',
            borderTop: '1px solid #e9e5e5',
        },
    },
});

export const Header = ({ createRows, columns, rows, path }) => {
    const classes = useStyles();
    const check = createRows(rows).length && path === MAIN_PAGE;

    return (
        <DataGrid
            rows={createRows(rows)}
            columns={columns}
            className={check && classes.root}
            hideFooterPagination={createRows(rows).length ? false : true}
            disableSelectionOnClick
        />
    );
};
