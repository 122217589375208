import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import XMLParser from 'react-xml-parser';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support

import Paper from '@material-ui/core/Paper';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Spiner from '../../../Common/Loader';
import { inspectorXY } from '../../../../store/device/device.actions';

function TransitionComponent(props) {
    const { in: inProp } = props;
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: inProp ? 1 : 0, transform: `translate3d(${inProp ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = withStyles(() => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
    root: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});

const Tree = (props) => {
    const { value, childs, id, os, activeTreeItem, tree } = props;
    const dispatch = useDispatch();

    const handleItemInspector = useCallback(
        (id, tree) => {
            let bounds, XYtop, XYbottom;
            function findBounds(treeView) {
                if (Array.isArray(treeView)) {
                    treeView.forEach((item) => findBounds(item));
                } else {
                    if (treeView.id === id) {
                        bounds = treeView.attributes.bounds;
                        XYtop = bounds && JSON.parse(bounds.slice(0, bounds.indexOf(']') + 1));
                        XYbottom = bounds && JSON.parse(bounds.slice(bounds.indexOf(']') + 1));
                        return;
                    }
                    findBounds(treeView.children);
                }
            }
            if (tree) findBounds(tree);

            if (XYtop && XYbottom) {
                dispatch(inspectorXY([XYtop, XYbottom]));
            }
        },
        [dispatch]
    );

    const nestedTree = childs.map((child) => {
        return (
            <Tree
                {...props}
                value={os === 'ios' ? child.attributes.type : child.attributes.class}
                childs={child.children}
                key={child.id}
                id={child.id}
            />
        );
    });

    return (
        <StyledTreeItem
            style={{ color: `${activeTreeItem === id ? 'blue' : 'black'}` }}
            nodeId={id}
            label={value}
            onMouseEnter={() => handleItemInspector(id, tree)}>
            {nestedTree}
        </StyledTreeItem>
    );
};

const ElementTrees = ({ activeDevice }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tree, setTree] = useState(null);
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [activeTreeItem, setActiveTreeItem] = useState('');
    const [activeDetails, setActiveDetails] = useState(null);
    const xmlSource = useSelector((state) => state.interactive.xmlSource, shallowEqual);
    const inspectorDetails = useSelector((state) => state.interactive.inspectorDetails, shallowEqual);
    const treeItemsToExpand = useSelector((state) => state.interactive.inspectorElementNodesId, shallowEqual);

    const os = activeDevice?.os.toLowerCase() || 'android';

    useEffect(
        () => {
            if (xmlSource) {
                const xml = new XMLParser().parseFromString(xmlSource);
                let id = 1;
                function identifiedXML(xml) {
                    if (Array.isArray(xml)) {
                        xml.forEach((item) => {
                            identifiedXML(item);
                        });
                    } else {
                        xml.id = `${id}`;
                        id++;
                        identifiedXML(xml.children);
                    }
                }
                identifiedXML(xml);
                setTree(xml);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [xmlSource]
    );

    useEffect(() => {
        if (activeTreeItem) {
            let attributes;
            function findAttributes(treeView) {
                if (Array.isArray(treeView)) {
                    treeView.forEach((item) => findAttributes(item));
                } else {
                    if (treeView.id === activeTreeItem) {
                        attributes = treeView.attributes;
                        return;
                    }
                    findAttributes(treeView.children);
                }
            }
            findAttributes(tree);
            setActiveDetails(attributes);
        }
    }, [activeTreeItem, tree]);

    useEffect(() => {
        if (treeItemsToExpand && inspectorDetails) {
            setExpanded(treeItemsToExpand);
            setSelected(treeItemsToExpand);
            setActiveTreeItem(treeItemsToExpand.slice(-1)[0]);
        }
    }, [treeItemsToExpand, inspectorDetails, activeDetails]);

    const handleNodeSelect = (_, nodeId) => {
        setSelected(expanded.filter((id) => id !== nodeId));
        setActiveTreeItem(nodeId);
    };

    const handleNodeToggle = (_, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleInspectorOff = () => {
        dispatch(inspectorXY(null));
    };

    return (
        <>
            {xmlSource ? (
                <div className="element-tree-wrap" data-testid="element-tree-test">
                    <p className="element-trees-header" />
                    <Paper elevation={2} className="trees" hover="true" role="checkbox" tabIndex={-1}>
                        <TreeView
                            className={classes.root}
                            defaultExpanded={['1']}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            selected={selected}
                            expanded={expanded}
                            onNodeSelect={handleNodeSelect}
                            onNodeToggle={handleNodeToggle}>
                            <div onMouseOut={handleInspectorOff}>
                                {tree ? (
                                    Array.isArray(tree) ? (
                                        tree.map((subTree) => {
                                            return (
                                                <Tree
                                                    value={
                                                        os === 'ios'
                                                            ? subTree.attributes.type
                                                            : subTree.attributes.class
                                                    }
                                                    childs={subTree.children}
                                                    key={subTree.id}
                                                    id={subTree.id}
                                                    os={os}
                                                    tree={tree}
                                                    activeTreeItem={activeTreeItem}
                                                />
                                            );
                                        })
                                    ) : (
                                        <Tree
                                            value={os === 'ios' ? tree.attributes.type : tree.attributes.class}
                                            childs={tree.children}
                                            key={tree.id}
                                            id={tree.id}
                                            os={os}
                                            tree={tree}
                                            activeTreeItem={activeTreeItem}
                                        />
                                    )
                                ) : null}
                            </div>
                        </TreeView>
                    </Paper>
                    <div className={'active-details'}>
                        {/* <div className={`${activeDetails ? 'active-details' : ''}`}> */}
                        {activeDetails || inspectorDetails ? (
                            <ul>
                                {Object.entries(activeDetails || inspectorDetails).map(([key, value]) => {
                                    return (
                                        <li key={`${key} - ${value}`}>
                                            {key} : {value}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="spiner-device-controll">
                    <Spiner />
                </div>
            )}
        </>
    );
};

export default ElementTrees;
