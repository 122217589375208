import React, { useState } from 'react';

const AppleWatchSkin = () => {
    const [message, setMessage] = useState(null);
    return (
        <div className="watch-container">
            <svg width="321" height="520" viewBox="0 0 964 1560" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#a)">
                    <g filter="url(#b)">
                        <path
                            d="M289 1396h357c0-13.85 0-20.78.219-27.62a427.955 427.955 0 0 1 39.86-167.15c2.891-6.2 6.016-12.38 12.268-24.75L735 1104a472.766 472.766 0 0 1-178.264 37.02L468 1142l-88.736-.98A472.766 472.766 0 0 1 201 1104l36.123 72.25c6.652 13.3 9.978 19.95 13.036 26.63a428.022 428.022 0 0 1 38.589 163.47c.252 7.34.252 14.78.252 29.65Z"
                            fill="#515e76"
                        />
                    </g>
                    <g filter="url(#c)">
                        <path
                            d="M289 156h357c0 13.853 0 20.779.219 27.618a427.983 427.983 0 0 0 39.86 167.155c2.891 6.201 6.016 12.382 12.268 24.744L735 448a472.806 472.806 0 0 0-178.264-37.022L468 410l-88.736.978A472.806 472.806 0 0 0 201 448l36.123-72.246c6.652-13.304 9.978-19.956 13.036-26.637a427.996 427.996 0 0 0 38.589-163.463c.252-7.343.252-14.78.252-29.654Z"
                            fill="#515e76"
                        />
                    </g>
                    <g filter="url(#d)">
                        <rect x="160" y="408" width="616" height="736" rx="138" fill="#b4b5b7" />
                    </g>
                    <g filter="url(#e)">
                        <path
                            className="watch-button"
                            onClick={() => setMessage('button was clicked')}
                            d="M775.602 793.921c.236-.546.804-.87 1.394-.797l2.118.265a5.578 5.578 0 0 1 4.886 5.535v173.051a6.659 6.659 0 0 1-5.995 6.625l-2.677.267a1.656 1.656 0 0 1-1.74-1.137A114.576 114.576 0 0 1 768 942.386V830.652a92.536 92.536 0 0 1 7.602-36.731Z"
                            fill="#b4b5b7"
                        />
                    </g>
                    <g filter="url(#f)">
                        <path
                            d="M773.678 618.861a4.001 4.001 0 0 1 3.597-1.671l15.746 1.312c1.938.161 2.908.242 3.79.528a8.005 8.005 0 0 1 3.164 1.93c.658.653 1.174 1.478 2.205 3.128.599.958.899 1.438 1.125 1.945.306.686.514 1.411.618 2.155.077.551.077 1.116.077 2.246v97.132c0 1.13 0 1.695-.077 2.246a8.011 8.011 0 0 1-.618 2.155c-.226.507-.526.987-1.125 1.945-1.031 1.65-1.547 2.475-2.205 3.128a8.005 8.005 0 0 1-3.164 1.93c-.882.286-1.852.367-3.79.528l-15.874 1.323a3.857 3.857 0 0 1-3.425-1.556 77.137 77.137 0 0 1-14.708-38.619l-.965-10.368c-.316-3.402-.475-5.103-.56-6.806a80.272 80.272 0 0 1 .051-8.938c.106-1.702.283-3.401.639-6.8l1.133-10.823.004-.044a80.007 80.007 0 0 1 14.336-37.97l.026-.036Z"
                            fill="#b4b5b7"
                        />
                    </g>
                    <g opacity="1" filter="url(#g)">
                        <path d="M795 631a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#h)" />
                        <path d="M795 636a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#i)" />
                        <path d="M795 641a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#j)" />
                        <path d="M795 646a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#k)" />
                        <path d="M795 651a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#l)" />
                        <path d="M795 656a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#m)" />
                        <path d="M795 661a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#n)" />
                        <path d="M795 666a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#o)" />
                        <path d="M795 671a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#p)" />
                        <path d="M795 676a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#q)" />
                        <path d="M795 681a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#r)" />
                        <path d="M795 686a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#s)" />
                        <path d="M795 691a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#t)" />
                        <path d="M795 696a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#u)" />
                        <path d="M795 701a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#v)" />
                        <path d="M795 706a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#w)" />
                        <path d="M795 711a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#x)" />
                        <path d="M795 716a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#y)" />
                        <path d="M795 721a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#z)" />
                        <path d="M795 726a1 1 0 0 1 1-1h8v2h-8a1 1 0 0 1-1-1Z" fill="url(#A)" />
                    </g>
                </g>
                <g filter="url(#B)">
                    <rect x="192" y="440" width="552" height="672" rx="124" fill="#030303" />
                </g>
                <g style={{ mixBlendMode: 'hard-light' }} filter="url(#C)">
                    <path
                        d="M192 680c0-113.137 0-169.706 35.147-204.853C262.294 440 318.863 440 432 440h72c113.137 0 169.706 0 204.853 35.147C744 510.294 744 566.863 744 680v192c0 113.137 0 169.71-35.147 204.85C673.706 1112 617.137 1112 504 1112h-72c-113.137 0-169.706 0-204.853-35.15C192 1041.71 192 985.137 192 872V680Z"
                        fill="#fff"
                        fillOpacity=".01"
                    />
                </g>
                <defs>
                    <linearGradient id="h" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="i" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="j" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="k" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="l" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="m" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="n" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="o" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="p" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="q" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="r" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="s" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="t" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="u" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="v" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="w" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="x" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="y" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="z" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <linearGradient id="A" x1="799.5" y1="630" x2="799.5" y2="727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0A1629" stopOpacity=".27" />
                        <stop offset=".231" stopColor="#050D1A" stopOpacity=".573" />
                        <stop offset="1" stopColor="#071120" stopOpacity=".28" />
                    </linearGradient>
                    <filter
                        id="a"
                        x="0"
                        y="0"
                        width="964"
                        height="1560"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="80" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_61" />
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_61" result="shape" />
                    </filter>
                    <filter
                        id="b"
                        x="137"
                        y="1104"
                        width="602"
                        height="304"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="4" dy="4" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="-64" dy="4" />
                        <feGaussianBlur stdDeviation="62" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0" />
                        <feBlend in2="effect1_innerShadow_24_61" result="effect2_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="16" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                        <feBlend in2="effect2_innerShadow_24_61" result="effect3_innerShadow_24_61" />
                    </filter>
                    <filter
                        id="c"
                        x="137"
                        y="156"
                        width="602"
                        height="296"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="4" dy="4" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="-64" dy="4" />
                        <feGaussianBlur stdDeviation="62" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                        <feBlend in2="effect1_innerShadow_24_61" result="effect2_innerShadow_24_61" />
                    </filter>
                    <filter
                        id="d"
                        x="148"
                        y="400"
                        width="629"
                        height="745"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feMorphology radius="4" in="SourceAlpha" result="effect1_innerShadow_24_61" />
                        <feOffset dx="-12" dy="-8" />
                        <feGaussianBlur stdDeviation="6" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="-4" dy="-4" />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                        <feBlend in2="effect1_innerShadow_24_61" result="effect2_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feMorphology radius="4" in="SourceAlpha" result="effect3_innerShadow_24_61" />
                        <feOffset dx="1" dy="1" />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                        <feBlend in2="effect2_innerShadow_24_61" result="effect3_innerShadow_24_61" />
                    </filter>
                    <filter
                        id="e"
                        x="768"
                        y="780.114"
                        width="18"
                        height="198.761"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="4" />
                        <feGaussianBlur stdDeviation=".5" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="2" dy="-21" />
                        <feGaussianBlur stdDeviation="6.5" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                        <feBlend in2="effect1_innerShadow_24_61" result="effect2_innerShadow_24_61" />
                    </filter>
                    <filter
                        id="f"
                        x="751.388"
                        y="608.175"
                        width="56.612"
                        height="140.659"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="-2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend in2="effect1_dropShadow_24_61" result="effect2_dropShadow_24_61" />
                        <feBlend in="SourceGraphic" in2="effect2_dropShadow_24_61" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="2" dy="-9" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                        <feBlend in2="shape" result="effect3_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                        <feBlend in2="effect3_innerShadow_24_61" result="effect4_innerShadow_24_61" />
                    </filter>
                    <filter
                        id="g"
                        x="795"
                        y="630"
                        width="10"
                        height="98"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1" dy="1" />
                        <feGaussianBlur stdDeviation=".5" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_24_61" />
                    </filter>
                    <filter
                        id="B"
                        x="188"
                        y="440"
                        width="560"
                        height="680"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_24_61" />
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow_24_61" result="shape" />
                    </filter>
                    <filter
                        id="C"
                        x="180"
                        y="424"
                        width="576"
                        height="700"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="12" dy="12" />
                        <feGaussianBlur stdDeviation="9" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.54 0" />
                        <feBlend in2="shape" result="effect1_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="-12" dy="-12" />
                        <feGaussianBlur stdDeviation="9" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0" />
                        <feBlend in2="effect1_innerShadow_24_61" result="effect2_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="8" dy="8" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                        <feBlend in2="effect2_innerShadow_24_61" result="effect3_innerShadow_24_61" />
                        <feColorMatrix
                            in="SourceAlpha"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="-8" dy="-16" />
                        <feGaussianBlur stdDeviation="10" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
                        <feBlend in2="effect3_innerShadow_24_61" result="effect4_innerShadow_24_61" />
                    </filter>
                </defs>
            </svg>
            <div className="watch-message">{message}</div>
        </div>
    );
};

export default AppleWatchSkin;
