import React, { useState, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { DEVICECLASS } from '../../../../../variables/common';
import { setMobileBle, deleteMobileBle } from '../../../../../store/device/device.actions';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

const DeviceBle = ({ device }) => {
    const buttonRef = useRef();
    const dispatch = useDispatch();
    const realDevices = useSelector((state) => state.device.realDevices, shallowEqual);
    const virtualDevices = useSelector((state) => state.device.virtualDevices, shallowEqual);
    const linkedBle = useSelector((state) => state.device.relations, shallowEqual);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const devices = [...realDevices, ...virtualDevices];
    const mobiles = devices.filter((item) => item.agent === device.agent && item.class === DEVICECLASS.MOBILE);
    const handleOpenMenu = (event) => {
        event.preventDefault();
        setAnchorEl(true);
    };
    const handleLinkDevice = (mobiles, device) => {
        dispatch(setMobileBle({ deviceId: mobiles.id, relationId: device.id }));
    };

    const handleUnLinkDevice = (mobiles, device) => {
        dispatch(deleteMobileBle({ deviceId: mobiles.id, relationId: device.id }));
    };

    const checkExistConnect = (arr, id) =>
        arr.find((item) => {
            return item.deviceId === id && item.relationId === device.id;
        });

    return (
        <div className="device-relations device-cell ">
            {device.class !== DEVICECLASS.MOBILE ? (
                <Toolbar className="device-menu-wrapper">
                    <IconButton
                        edge="start"
                        ref={buttonRef}
                        aria-label="menu"
                        className="icon-wrapper"
                        size="small"
                        onContextMenu={handleOpenMenu}>
                        <Tooltip title="Device association">
                            <AllInclusiveIcon className="settings-icon" />
                        </Tooltip>
                    </IconButton>
                    <Menu
                        open={open}
                        id="menu-appbar"
                        onClose={() => setAnchorEl(null)}
                        anchorEl={() => buttonRef.current}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        PaperProps={{ style: { minWidth: 100 } }}>
                        {mobiles.map((devices, idx) => (
                            <MenuItem
                                key={idx++}
                                className={'settings-device-item device-menu-ble '}
                                onClick={() => {
                                    checkExistConnect(linkedBle, devices.id)
                                        ? handleUnLinkDevice(devices, device)
                                        : handleLinkDevice(devices, device);
                                }}>
                                {devices.model}&nbsp;
                                {checkExistConnect(linkedBle, devices.id) ? (
                                    <LinkIcon className="device-relations-icon" />
                                ) : (
                                    <LinkOffIcon className="device-relations-icon" />
                                )}
                            </MenuItem>
                        ))}
                    </Menu>
                </Toolbar>
            ) : null}
        </div>
    );
};

export default DeviceBle;
