import { NOTIFICATION_MESSAGE } from '../storeActionTypes';

const initialState = {
    message: null,
};

export function notificationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case NOTIFICATION_MESSAGE: {
            return { ...state, message: payload };
        }
        default: {
            return state;
        }
    }
}
