import { Users } from '../components/UserControl/components/Users';
import { Chart } from '../components/UserControl/components/Chart';
import Maintenance from '../components/UserControl/components/Maintenance';
import { Devices } from '../components/UserControl/components/DevicesControl/Devices';

const adminRoutes = [
    {
        path: '/devices-control',
        name: 'devices control',
        component: Devices,
        restricted: true,
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        restricted: false,
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: Chart,
        restricted: false,
    },

    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
        restricted: true,
    },
];
export default adminRoutes;
