import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Board from '../components/Board';
import Login from '../components/Login';
import MainPage from '../components/Main';
import Device from '../components/Device';
import Preview from '../components/Preview';
import AppleWatch from '../components/AppleWatch';
import UserControl from '../components/UserControl';
import PrivateRoute from '../components/PrivateRoute';
import NotFound from '../components/NotFound/index.js';
import DeviceDashboardPage from '../components/DeviceDashboard';
import { Repositorium } from '../components/Repositorium/index';
// import { Registration } from '../components/Registration/index';

import auth, { handleSubmit } from '../utils/auth';

import {
    DEVICE,
    MAIN_PAGE,
    PREVIEW,
    USERCONTROL,
    REPOSITORIUM,
    DEVICE_DASHBOARD,
    BOARD,
    APPLE_WATCH,
    USERCONTROL_USERS,
    DEVICECONTROL_DEVICES,
} from '../variables/common';

const getRouteByRole = () => {
    const role = auth.role;
    switch (role) {
        case 'admin':
            return USERCONTROL_USERS;
        case 'superuser':
            return DEVICECONTROL_DEVICES;
        default:
            null;
    }
};
const AuthVerify = () => {
    return auth.jwt_decode?.exp ? Date.now() >= auth.jwt_decode.exp * 1000 : false;
};
export const routes = () => {
    return (
        <Switch>
            <Route
                exact
                path={MAIN_PAGE}
                component={() =>
                    auth.isAuthenticated && !AuthVerify() ? <MainPage /> : <Login handleLogin={handleSubmit} />
                }
            />
            <PrivateRoute exact path={DEVICE_DASHBOARD} component={DeviceDashboardPage} />
            <PrivateRoute exact path={DEVICE} component={Device} />
            <PrivateRoute exact path={BOARD} component={Board} />
            <PrivateRoute exact path={PREVIEW} component={Preview} />
            <PrivateRoute exact path={APPLE_WATCH} component={AppleWatch} />
            <PrivateRoute exact path={REPOSITORIUM} component={Repositorium} />
            {/* <PrivateRoute exact path={REGISTRATION} component={Registration} /> */}
            <Redirect exact from={USERCONTROL} to={getRouteByRole()} />
            <PrivateRoute path={USERCONTROL} component={UserControl} />
            <Route component={NotFound} />
        </Switch>
    );
};
