import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { denyRoutesByRole } from '../../variables/common';
import auth from '../../utils/auth';
import { MAIN_PAGE } from '../../variables/common';
const PrivateRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();
    const checkRoleDeniedRout = () => {
        const denyRoutes = Object.entries(denyRoutesByRole).find(([key, value]) => {
            return key === auth.role && value.includes(location.pathname);
        });
        return denyRoutes;
    };
    return (
        <Route
            {...rest}
            render={(props) => {
                return !auth.isAuthenticated || checkRoleDeniedRout() ? (
                    <Redirect to={MAIN_PAGE} />
                ) : (
                    <Component {...props} />
                );
            }}
        />
    );
};

export default PrivateRoute;
