import { MAIN_PAGE } from '../variables/common';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';

const history = createBrowserHistory();
const cookies = new Cookies(null, { path: process.env.REACT_APP_API_PREFIX});

//* for unit-test
export const handleSubmit = (values, callback) => {
    callback(values);
};

class Auth {
    constructor() {
        if (cookies.get('oauth')) {
            localStorage.setItem('email', cookies.get('email') || null);
            localStorage.setItem('role', cookies.get('role') || null);
            localStorage.setItem('group', cookies.get('group') || null);
            localStorage.setItem('full_name', cookies.get('full_name') || null);

            cookies.remove('oauth');
            cookies.remove('email');
            cookies.remove('role');
            cookies.remove('group');
            cookies.remove('full_name');
        }
    }

    get isAuthenticated() {
        if (cookies.get('jwt')) {
            return true;
        }
        return false;
    }
    get role() {
        return localStorage.getItem('role') || null;
    }
    get token() {
        return cookies.get('jwt') || null;
    }
    get jwt_decode() {
        return this.parseJwt(this.token) || null;
    }

    login(token, email, role, fullName, group) {
        localStorage.setItem('email', email);
        localStorage.setItem('role', role);
        localStorage.setItem('group', group);
        localStorage.setItem('full_name', fullName);
        cookies.set('jwt', token);

        history.push(MAIN_PAGE, { jwt: cookies.get('jwt') });
        history.go(0);
    }

    logout() {
        localStorage.removeItem('email');
        localStorage.removeItem('role');
        localStorage.removeItem('group');
        localStorage.removeItem('full_name');

        cookies.remove('jwt');

        history.push(MAIN_PAGE);
        /* history.go(0); */
    }

    parseJwt(token) {
        try {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload);
        }
        catch (err) {
            return false
        }

    }
}

const auth = new Auth();

export default auth;
