import auth from './auth';

const errorHandler = (error) => {
    if (error.response && error.response.status) {
        switch (error.response.status) {
            case 401: {
                auth.logout();
                break;
            }
            default: {
                break;
            }
        }
    }
    console.log(error);
};

export default errorHandler;
