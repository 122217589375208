/**
 * The Module provides the possibility to take actions with the phone file system
 */
import Spiner from '../../../Common/Loader';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import SelectPathPopup from './SelectPathPopup';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';

import {
    getDeviceFolder,
    pushFileToDevice,
    removeItemFromDevice,
    pullFileFromDevice,
} from '../../../../store/interactive/interactive.actions';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 16, height: 16, opacity: 0.5 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const { in: inProp } = props;
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: {
            opacity: inProp ? 1 : 0,
            transform: `translate3d(${inProp ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = withStyles((theme) => ({
    root: {
        marginLeft: '1%',
    },
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
    content: {
        width: 'fit-content',
        whiteSpace: 'nowrap',
        paddingRight: 15,
    },
}))(({ folder, ...props }) => {
    return (
        <TreeItem
            {...props}
            TransitionComponent={TransitionComponent}
            endIcon={folder ? <PlusSquare /> : <MinusSquare />}
        />
    );
});

const useStyles = makeStyles({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 15px 20px 15px',
        height: '100%',
    },
    wrapperTree: {
        display: 'flex',
        lineHeight: '1',
        whiteSpace: 'nowrap',
    },
    iconTree: {
        margin: '0 4px 0 0',
        cursror: 'pointer',
    },
});

const Tree = (props) => {
    const {
        value,
        path,
        childs,
        handleTreeItem,
        handleDeleteIconClick,
        setIsLoading,
        SID,
        setIsOpen,
        handleDownloadIconClick,
        setDownloadData,
    } = props;

    const nestedTree = childs
        ? childs.map((child) => {
              return (
                  <Tree
                      value={child.value}
                      path={child.path}
                      childs={child.childs}
                      key={child.path}
                      handleTreeItem={handleTreeItem}
                      handleDeleteIconClick={handleDeleteIconClick}
                      handleDownloadIconClick={handleDownloadIconClick}
                      setIsLoading={setIsLoading}
                      setIsOpen={setIsOpen}
                      setDownloadData={setDownloadData}
                      SID={SID}
                  />
              );
          })
        : null;

    const pattern = /\/$/;
    const folder = value.match(pattern);

    return (
        <div className="storage-list">
            {folder ? (
                <div className="icon">
                    <Tooltip title="Remove Folder">
                        <DeleteIcon onClick={() => handleDeleteIconClick(path)} />
                    </Tooltip>

                    <Tooltip title="Download Folder">
                        <div className="icon">
                            <GetAppIcon
                                onClick={() => {
                                    setDownloadData({ path, type: 'folder' });
                                    setIsOpen(true);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : (
                <div className="icon">
                    <Tooltip title="Remove">
                        <DeleteIcon onClick={() => handleDeleteIconClick(path)} />
                    </Tooltip>

                    <Tooltip title="Download">
                        <GetAppIcon
                            onClick={() => {
                                setDownloadData({ path, type: 'file' });
                                setIsOpen(true);
                            }}
                        />
                    </Tooltip>
                </div>
            )}

            <StyledTreeItem nodeId={path} label={value} onClick={handleTreeItem(value, path, childs)} folder={folder}>
                {nestedTree}
            </StyledTreeItem>
        </div>
    );
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const FileManager = ({ activeDevice }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [file, setFile] = useState(undefined);
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deviceTree, setDeviceTree] = useState(null);
    const [activeFolder, setActiveFolder] = useState('');
    const [selectedPath, setSelectedPath] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [downloadData, setDownloadData] = useState({ path: null, type: null });

    const SID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);
    const deviceFolder = useSelector((state) => state.interactive.deviceFolder, shallowEqual);

    const prevFolder = usePrevious(deviceFolder);
    const isAndroid = activeDevice.os.toLowerCase() === 'android';

    const handleDeleteIconClick = (pathItemToDelete) => {
        dispatch(
            removeItemFromDevice({
                AppiumSID: SID,
                command: 'remove',
                filePath: pathItemToDelete,
                setIsLoading,
            })
        );
        deleteFile();
    };

    function handleDownloadIconClick(pathItemToDownload, path) {
        setIsOpen(false);
        dispatch(
            pullFileFromDevice({
                AppiumSID: SID,
                saveAsPath: path,
                filePath: pathItemToDownload,
                fileType: downloadData.type,
            })
        );
    }

    function deleteFile() {
        setExpanded([]);
        setActiveFolder('');
        dispatch(getDeviceFolder({ SID: SID }));
    }

    const handleTreeItem = (folder, path, childs) => () => {
        setSelectedPath(path);
        if (folder[folder.length - 1] === '/') {
            const expandedCopy = [...expanded];
            if (expandedCopy.find((copyPath) => copyPath === path)) {
                setExpanded(expandedCopy.filter((copyPath) => copyPath !== path));
                setSelected([path]);
                // setSelected(expandedCopy.filter((copyPath) => copyPath !== path));
                setActiveFolder('');
            } else {
                if (childs === null) {
                    dispatch(
                        getDeviceFolder(
                            {
                                SID,
                                path: path.replace(' ', '\\ '),
                            },
                            setIsLoading
                        )
                    );
                }
                setExpanded([...expandedCopy, path]);
                setSelected([path]);
                setActiveFolder(folder);
            }
        }
    };

    const handleToggle = (event, nodeIds) => setExpanded(nodeIds);

    const createTree = (elem) => {
        return (
            <div key={elem.path} className={classes.wrapperTree}>
                {!elem.parent ? (
                    <div className="icon">
                        <Tooltip title="Remove">
                            <DeleteIcon className={classes.iconTree} onClick={() => handleDeleteIconClick(elem.path)} />
                        </Tooltip>

                        <Tooltip title="Download">
                            <GetAppIcon
                                className={classes.iconTree}
                                onClick={() => {
                                    let [path, type] = [elem.path, elem.type];
                                    type = type !== 'file' ? 'folder' : 'file';
                                    setDownloadData({ path, type });
                                    setIsOpen(true);
                                }}
                            />
                        </Tooltip>
                    </div>
                ) : null}

                <StyledTreeItem
                    nodeId={elem.path}
                    label={elem.name}
                    onClick={() => {
                        setSelectedPath(elem.path);
                    }}>
                    {elem?.children?.map((item) => createTree(item))}
                </StyledTreeItem>
            </div>
        );
    };

    useEffect(
        () => {
            if (deviceFolder?.length) {
                const listToTree = deviceFolder.map((item) => {
                    return { value: item, path: item, childs: null };
                });
                setDeviceTree(listToTree);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deviceFolder?.length]
    );

    useEffect(
        () => {
            if (activeFolder !== '' && JSON.stringify(prevFolder) !== JSON.stringify(deviceFolder)) {
                const treeClone = cloneDeep(deviceTree);
                function findRec(tree) {
                    if (tree && Array.isArray(tree)) {
                        tree.forEach((elem) => findRec(elem));
                    } else if (tree) {
                        if (tree.value === activeFolder) {
                            tree.childs = deviceFolder.map((item) => {
                                return { value: item, path: `${tree.path + item}`, childs: null };
                            });
                        } else {
                            findRec(tree.childs);
                        }
                    }
                }
                findRec(treeClone);
                setDeviceTree(treeClone);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deviceFolder, activeFolder, prevFolder, deviceTree]
    );

    useEffect(
        () => {
            if (file) {
                const data = new FormData();
                data.append('file', file, file.name);

                dispatch(pushFileToDevice(SID, data, selectedPath, file.name));

                setExpanded([]);
                setActiveFolder('');

                // dispatch(getDeviceFolder({ SID: SID, deviceOs: activeDevice.os }))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [file]
    );

    return (
        <div className="apllication-wrap" data-testid="fm-test">
            <div className="title">
                <p className="element-trees-header">{/* Phone storage */}</p>

                <div className="file-window">
                    <input
                        className="upload-file"
                        id="icon-button-file"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                    <Tooltip title="Upload file">
                        <label htmlFor="icon-button-file" className="upload-wrap">
                            <AddIcon className="addIcon" />
                        </label>
                    </Tooltip>
                </div>

                <SelectPathPopup
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    path={downloadData.path}
                    type={downloadData.type}
                    action={handleDownloadIconClick}
                />
            </div>

            <div className="aplication-activity">
                <div className="applications">
                    <TreeView
                        className={classes.root}
                        defaultExpanded={['1']}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<PlusSquare />}
                        selected={selected}
                        expanded={expanded}
                        onNodeToggle={!isAndroid ? handleToggle : null}>
                        {isAndroid
                            ? deviceTree &&
                              deviceTree.map((subTree) => {
                                  return (
                                      <Tree
                                          value={subTree.value}
                                          path={subTree.path}
                                          childs={subTree.childs}
                                          key={subTree.path}
                                          handleTreeItem={handleTreeItem}
                                          handleDeleteIconClick={handleDeleteIconClick}
                                          setIsLoading={setIsLoading}
                                          SID={SID}
                                          setIsOpen={setIsOpen}
                                          handleDownloadIconClick={handleDownloadIconClick}
                                          setDownloadData={setDownloadData}
                                      />
                                  );
                              })
                            : deviceFolder && deviceFolder.map((item, index) => createTree(item, index))}
                    </TreeView>
                </div>
            </div>

            {(!deviceTree || isLoading) && (
                <div className="spiner-device-controll">
                    <Spiner />
                </div>
            )}
        </div>
    );
};

export default FileManager;
