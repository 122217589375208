import React from 'react';
import Spiner from '../Common/Loader';
import { useSelector, shallowEqual } from 'react-redux';

const PreviewTextFile = () => {
    const textFile = useSelector((state) => state.preview.textFile, shallowEqual);
    return <div> {textFile ? <pre className="preview-text">{textFile}</pre> : <Spiner />}</div>;
};

export default PreviewTextFile;
