import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import StopIcon from '@material-ui/icons/Stop';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { List, ListItem, Tooltip, Accordion, Typography } from '@material-ui/core';

import Spiner from '../../../Common/Loader';
import { Repositorium } from '../../../Repositorium';

import { launchApp, terminateApp, uninstallApp } from '../../../../store/interactive/interactive.actions';

const Applications = ({ activeDevice }) => {
    const dispatch = useDispatch();
    const deviceOs = activeDevice.os;

    const [open, setOpen] = useState(false);

    const AppiumSID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);
    const commandsData = useSelector((state) => state.interactive.commandsData, shallowEqual);
    const installedApps = useSelector((state) => state.interactive.installedApps, shallowEqual);

    const handleIconClick = (params, command) => {
        let packageName = deviceOs === 'iOS' ? params.bundleId : params.packageName;

        switch (command) {
            case 'launchApp': {
                dispatch(launchApp({ AppiumSID, command, params, commandsData }));
                break;
            }
            case 'closeApp': {
                dispatch(terminateApp({ AppiumSID, command, packageName, commandsData }));
                break;
            }
            case 'uninstallApp': {
                dispatch(uninstallApp({ AppiumSID, deviceOs, command, packageName, commandsData }));
                break;
            }
            default: {
                return {};
            }
        }
    };

    return (
        <div className="apllication-wrap">
            <div className="title">
                <p className="element-trees-header">Applications&apos; List</p>
                <div className="file-window" onClick={() => setOpen(true)}>
                    <Tooltip title="Install Application">
                        <label className="upload-wrap" htmlFor="icon-button-file">
                            <SystemUpdateIcon className="addIcon" />
                        </label>
                    </Tooltip>
                </div>
            </div>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent style={{ height: '65vh' }}>
                    <Repositorium />
                </DialogContent>
            </Dialog>

            {!!installedApps?.length && (
                <div className="aplication-activity">
                    <div className="applications">
                        <List style={{ marginTop: 15 }}>
                            {installedApps.map((item, id) => (
                                <div className="package-list" key={id}>
                                    <Accordion className="expand-panel">
                                        <Typography>
                                            <ListItem className="file-item" key={id}>
                                                <Tooltip
                                                    title="Start Application"
                                                    data-testid="application-tooltip-test">
                                                    <PlayCircleOutlineIcon
                                                        data-testid="application-icon-test"
                                                        className="icon-action"
                                                        onClick={() => handleIconClick(item, 'launchApp')}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Stop Application">
                                                    <StopIcon
                                                        className="icon-action"
                                                        onClick={() => handleIconClick(item, 'closeApp')}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Uninstall Application">
                                                    <RemoveCircleOutlineIcon
                                                        className="icon-action"
                                                        onClick={() => handleIconClick(item, 'uninstallApp')}
                                                    />
                                                </Tooltip>
                                                {deviceOs === 'iOS' ? item.bundleId : item.packageName}
                                            </ListItem>
                                        </Typography>
                                    </Accordion>
                                </div>
                            ))}
                        </List>
                    </div>
                </div>
            )}

            <div className="spiner-device-controll">{!installedApps && <Spiner />}</div>
        </div>
    );
};

export default Applications;
