import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { copyText } from '../../../helpers/copyText';

import auth from '../../utils/auth';
/* import TokenPopup from './Token'; */
import { ActiveSessionsPopup } from '../ActiveSessions';

import beta from '../../../static/images/beta.png';
import { ReactComponent as ChevronLeft } from '../../../static/images/chevron_left.svg';

import {
    DEVICE,
    BOARD,
    MAIN_PAGE,
    REPOSITORIUM,
    USERCONTROL,
    DEVICE_DASHBOARD,
    TITLE,
    APPLE_WATCH,
    PREVIEW,
    ACTIVE_SESSION,
} from '../../variables/common';
import LOCALSTORAGE from '../../variables/localstorage';

import { mySessions } from '../../store/socket/socketActions';
import { selectDevice, setSearch } from '../../store/device/device.actions';
import {
    AppiumSessionID,
    StreamURLDevice,
    deleteDeviceSession,
    setScreen,
} from '../../store/interactive/interactive.actions';
import {
    /* backButton, */
    /* tokenPopup, */
    sessionsPopup,
    HeaderTitle,
    setTabNumber,
} from '../../store/auxiliary/auxiliary.actions';
import { NotificationMessage, handlerNotification } from '../../store/notification/notification.actions';

import { TextField, Menu, MenuItem, Button } from '@material-ui/core';
import { getSearch, getFilteredRealDevices, getFilteredVirtualDevices } from '../../store/device/device.selectors';

const GoBack = ({ handleClick }) => {
    const title = useSelector((state) => state.auxiliary.Title, shallowEqual);
    return (
        <div className="go-to-list" onClick={handleClick}>
            <div>
                <ChevronLeft className="arrow" />
            </div>
            {title && (
                <div>
                    <h1>{title}</h1>
                </div>
            )}
        </div>
    );
};

export const Header = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    let isBetaVersion = '';
    const userEmail = localStorage.getItem('email');
    const userFullName = localStorage.getItem('full_name');

    const [anchorEl, setAnchorEl] = useState(null);

    const searchValue = useSelector(getSearch);

    const role = useSelector((state) => state.user.role, shallowEqual);
    const Title = useSelector((state) => state.auxiliary.Title, shallowEqual);
    const isSessions = useSelector((state) => state.auxiliary.isSessions, shallowEqual);
    /* const isTokenPopup = useSelector((state) => state.auxiliary.isTokenPopup, shallowEqual); */
    const activeDevice = useSelector((state) => state.device.activeDevice, shallowEqual);
    const activeSessions = useSelector((state) => state.interactive.activeSessions, shallowEqual);
    const virtualDevicesSwitch = useSelector((state) => state.device.virtualDevicesSwitch, shallowEqual);

    const virtualDevicesData = useSelector(getFilteredVirtualDevices);
    const realDevicesData = useSelector(getFilteredRealDevices);
    const devicesData = virtualDevicesSwitch ? virtualDevicesData : realDevicesData;

    if (devicesData && activeDevice) {
        isBetaVersion = devicesData.find((item) => item.id === activeDevice.id)?.metadata?.betaVersion;
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        mySessions();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const locationHome = (headerTitle = TITLE.MAGNUM, tabNumber = 0) => {
        history.push(MAIN_PAGE);
        dispatch(HeaderTitle(headerTitle));
        dispatch(setTabNumber(tabNumber));
    };

    const logoutHandler = () => {
        activeSessions
            .filter(({ type }) => type === ACTIVE_SESSION.TYPE)
            .map(({ id }) => {
                dispatch(deleteDeviceSession({ sid: id }));
            });

        history.go(0);
        auth.logout();
    };

    const openActiveSession = (elem) => {
        dispatch(StreamURLDevice(elem.stream));
        dispatch(selectDevice(elem));
        dispatch(AppiumSessionID(elem.sid));
        history.push(DEVICE);
        dispatch(sessionsPopup(false));
    };

    const locationSwitch = () => {
        if (pathname.includes(PREVIEW)) {
            return <GoBack handleClick={() => locationHome(TITLE.EXECUTION_CENTER, 3)} />;
        }

        if (pathname.includes(USERCONTROL)) {
            return <GoBack handleClick={() => locationHome()} />;
        }

        switch (pathname) {
            case BOARD:
            case APPLE_WATCH:
            case DEVICE:
                return (
                    <>
                        {isBetaVersion && (
                            <div className="beta-version-logo">
                                <img src={beta} alt="beta" />
                            </div>
                        )}
                        <GoBack
                            handleClick={() => {
                                locationHome();
                                mySessions();
                                dispatch(StreamURLDevice(undefined));
                                dispatch(setScreen(null));
                            }}
                        />
                    </>
                );
            case MAIN_PAGE:
                if (auth.isAuthenticated) {
                    return <h1>{Title && Title}</h1>;
                } else {
                    return <h1>{TITLE.MAGNUM}</h1>;
                }
            case REPOSITORIUM:
            case USERCONTROL:
            case DEVICE_DASHBOARD: {
                return (
                    <GoBack
                        handleClick={() => {
                            locationHome();
                            mySessions();
                        }}
                    />
                );
            }
            default:
                return <h1>{Title && Title}</h1>;
        }
    };

    const onHandleSearch = (e) => {
        dispatch(setSearch(e.target.value));
    };

    return (
        <>
            <header data-testid="header">
                {locationSwitch()}
                {auth.isAuthenticated ? (
                    <div className="control-wrapper">
                        {(Title === TITLE.DEVICE_LIST || Title === TITLE.MAGNUM) && pathname === MAIN_PAGE && (
                            <TextField
                                type="search"
                                data-testid="textbox"
                                value={searchValue}
                                className="search-field"
                                id="outlined-basic"
                                label="Search"
                                autoComplete="off"
                                variant="outlined"
                                onChange={onHandleSearch}
                            />
                        )}
                        <div className="user-account">
                            {activeSessions && activeSessions.length !== 0 && !anchorEl ? (
                                <div className="indecator">
                                    <p>{activeSessions.length}</p>
                                </div>
                            ) : null}
                            <Button className="user-email" onClick={handleMenuOpen}>
                                {userFullName ? userFullName : userEmail}
                            </Button>
                            <Button
                                className="user-token"
                                onClick={() => {
                                    handleMenuClose();
                                    copyText(auth.token);
                                    dispatch(
                                        NotificationMessage({ msg: 'Token successfully copied', type: 'success' })
                                    );
                                    dispatch(handlerNotification());
                                    //dispatch(tokenPopup(true));
                                }}>
                                Get Token
                            </Button>
                            <Menu
                                className="user-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}>
                                {activeSessions && (
                                    <div>
                                        {anchorEl && (
                                            <div className="counter">
                                                <p>{activeSessions.length}</p>
                                            </div>
                                        )}

                                        <MenuItem
                                            disabled={activeSessions.length ? false : true}
                                            onClick={() => {
                                                handleMenuClose();
                                                dispatch(sessionsPopup(true));
                                            }}>
                                            My Sessions
                                        </MenuItem>
                                    </div>
                                )}
                                {role !== 'user' && !pathname.includes(USERCONTROL) && (
                                    <MenuItem
                                        onClick={() => {
                                            history.push(USERCONTROL);
                                            dispatch(HeaderTitle(TITLE.MAGNUM));
                                            handleMenuClose();
                                        }}>
                                        Admin Page
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        logoutHandler();
                                    }}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                ) : null}
            </header>
            {isSessions && <ActiveSessionsPopup openActiveSession={openActiveSession} />}
            {/* {isTokenPopup && <TokenPopup />} */}
        </>
    );
};
