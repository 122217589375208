import React from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import adminRoutes from '../../../routes/adminRoutes';

const TabContentItem = () => {
    const { tabName } = useParams();
    const normalizedName = tabName.replace(/[^a-zA-Z0-9 ]/g, ' ');
    const { component: Component } = adminRoutes.find(({ name }) => name === normalizedName);
    return (
        <div role="tabpanel" className="tab-content">
            <Box p={3} className="content-box">
                <Component />
            </Box>
        </div>
    );
};

export const Tabcontent = () => {
    const { path } = useRouteMatch();

    return (
        <div className="tabcontent-wrap" data-testid="tab-content">
            <Route path={`${path}/:tabName`}>
                <TabContentItem />
            </Route>
        </div>
    );
};
