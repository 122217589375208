import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    vdPopup: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        },
    },
    vdContent: {
        fontWeight: '700',
        opacity: '0.8',
        color: '#000',
    },
});

const AgentLogsPopup = ({ isOpen, setIsOpen }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.vdPopup}>
            <DialogTitle id="alert-dialog-title">
                <WarningIcon className="expire-confirm-icon" />
                Agent Logs downloaded
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p>
                        Log file will appears in the repository
                    </p>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={() => setIsOpen(false)}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AgentLogsPopup;
