import React from 'react';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';

const MetadataEditField = (props) => {
    const { prop, value, setProp, setValue, handleSaveData, handleCloseChildPopup } = props;

    const createField = (label, value, action, state) => {
        return (
            <TextField
                fullWidth
                id="agent"
                type="text"
                label={label}
                autoFocus={state}
                value={`${value}`}
                onChange={(event) => action(event.target.value)}
            />
        );
    };

    return (
        <div className="popup-child-box" onClick={handleCloseChildPopup}>
            <div className="popup-child-wrapper" onClick={(event) => event.stopPropagation()}>
                {createField('Key', prop, setProp, true)}
                {createField('Value', value, setValue, false)}

                <div className="popup-child-button">
                    <Button
                        size="medium"
                        color="primary"
                        variant="outlined"
                        startIcon={<SaveIcon />}
                        onClick={() => handleSaveData(prop, value)}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MetadataEditField;
