import {
    STREAM_URL,
    ACTIVE_SESSIONS,
    APPIUM_SESSION_ID,
    IS_SESSION_STOPED,
    IS_SESSION_EXPIRED,
    COMMAND_LIST,
    SET_SCREEN,
    DEVICE_ACTIVE_FOLDER,
    INSTALLED_APP_LIST,
    XML_SOURCE,
    SET_SESSION_TAB,
    SET_INSPECTOR_MODE,
    SET_INSPECTOR_DETAILS,
    SET_INSPECTOR_ELEMENT,
    SET_INSPECTOR_ELEMENT_NODES_ID,
    PREVENT_MULTIPLE_CLICKS,
    RECORDING,
    DEVICE_HAS_PIN_CODE,
    IS_LOADING,
} from '../storeActionTypes';

const initialState = {
    AppiumSID: null,
    streamURL: null,
    recording: false,
    activeSessions: null,
    sessionExpired: false,
    sessionStoped: false,
    sessionTab: 'Parameters',
    bgImage: null,
    commandsData: [],
    xmlSource: null,
    installedApps: null,
    deviceFolder: null,
    inspectorMode: false,
    inspectorDetails: null,
    inspectorElement: null,
    inspectorElementNodesId: [],
    isPrevented: false,
    hasPinCode: null,
    isLoading: null,
};

export function interactiveReducer(state = initialState, { type, payload }) {
    switch (type) {
        case APPIUM_SESSION_ID: {
            return { ...state, AppiumSID: payload };
        }
        case STREAM_URL: {
            return { ...state, streamURL: payload };
        }
        case RECORDING: {
            return { ...state, recording: payload };
        }
        case ACTIVE_SESSIONS: {
            return { ...state, activeSessions: payload };
        }
        case IS_SESSION_EXPIRED: {
            return { ...state, sessionExpired: payload };
        }
        case IS_SESSION_STOPED: {
            return { ...state, sessionStoped: payload };
        }
        case COMMAND_LIST: {
            return { ...state, commandsData: payload };
        }
        case SET_SCREEN: {
            return { ...state, bgImage: payload };
        }
        case DEVICE_ACTIVE_FOLDER: {
            return { ...state, deviceFolder: payload };
        }
        case INSTALLED_APP_LIST: {
            return { ...state, installedApps: payload };
        }
        case XML_SOURCE: {
            return { ...state, xmlSource: payload };
        }
        case SET_SESSION_TAB: {
            return { ...state, sessionTab: payload };
        }
        case SET_INSPECTOR_MODE: {
            return { ...state, inspectorMode: payload };
        }
        case SET_INSPECTOR_DETAILS: {
            return { ...state, inspectorDetails: payload };
        }
        case SET_INSPECTOR_ELEMENT: {
            return { ...state, inspectorElement: payload };
        }
        case SET_INSPECTOR_ELEMENT_NODES_ID: {
            return { ...state, inspectorElementNodesId: payload };
        }
        case PREVENT_MULTIPLE_CLICKS: {
            return { ...state, isPrevented: payload };
        }
        case DEVICE_HAS_PIN_CODE: {
            return { ...state, hasPinCode: payload };
        }
        case IS_LOADING: {
            return { ...state, isLoading: payload };
        }
        default: {
            return state;
        }
    }
}
