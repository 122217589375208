import axios from 'axios';
import auth from '../utils/auth';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { adminReducer } from './admin/admin.reducer';
import { auxiliaryReducer } from './auxiliary/auxiliary.reducer';
import { deviceReducer } from './device/device.reducer';
import { executionReducer } from './execution/execution.reducer';
import { interactiveReducer } from './interactive/interactive.reducer';
import { notificationReducer } from './notification/notification.reducer';
import { maintenanceReducer } from './maintenance/maintenance.reducer';
import { repositoriumReducer } from './repositorium/repositorium.reducer';
import { previewReducer } from './preview/preview.reducer';
import { userReducer } from './user/user.reducer';

axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;

const rootReducer = combineReducers({
    admin: adminReducer,
    auxiliary: auxiliaryReducer,
    device: deviceReducer,
    execution: executionReducer,
    interactive: interactiveReducer,
    notification: notificationReducer,
    maintenanceMessage: maintenanceReducer,
    repositorium: repositoriumReducer,
    user: userReducer,
    preview: previewReducer,
});

export const StoreConfiguration = () => {
    let store = undefined;
    if (process.env.REACT_APP_NODE === 'production') {
        store = createStore(rootReducer, applyMiddleware(thunk));
    } else {
        store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, logger)));
    }
    return store;
};
