import { SET_TEXT_FILE, SET_VIDEO_PATH } from '../storeActionTypes';

const initialState = {
    textFile: null,
    videoPath: null,
};

export function previewReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_TEXT_FILE: {
            return { ...state, textFile: payload };
        }
        case SET_VIDEO_PATH: {
            return { ...state, videoPath: payload };
        }
        default: {
            return state;
        }
    }
}
