import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import { requestToUploadLogFile } from '../../../../../store/admin/admin.actions';

import AgentLogsPopup from './AgentLogsPopup';

const useStyles = makeStyles({
    tableCell: {
        padding: '17px',
    },
    tableContainer: {
        maxHeight: '700px',
        overflowY: 'auto',
        backgroundColor: '#ffffff',
        width: '450px',
    },
    centerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1300
    },
    contentWrapper: {
        width: '450px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    downloadIcon: {
        opacity: '1',
        cursor: 'pointer',
    },
    emptyState: {
        textAlign: 'center',
        padding: '20px',
    }
});

const AgentLogsList = ({ agentUrl, agentLogs, handleHideTable }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const headCell = ['FileName', '', ''];

    const handleClick = (file) => {
        dispatch(requestToUploadLogFile({ agent: agentUrl, file: file }))
            .catch(error => {
                // Handle error (e.g., show a notification)
                console.error('Error uploading log file:', error);
            });
        setIsOpen(true);
    };

    return (
        <div className={classes.centerWrapper}>
            <AgentLogsPopup isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={classes.contentWrapper}>
                <div className="agent-log-popup">
                    <p>Agent logs</p>
                    <CloseIcon className={classes.closeIcon} onClick={handleHideTable} />
                </div>
                <div className={classes.tableContainer}>
                    <Table aria-label="Agent Logs Table">
                        <TableHead className="agent-log-table-head">
                            <TableRow>
                                {headCell.map((item, idx) => (
                                    <TableCell className={classes.tableCell} key={idx}>
                                        {item}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {agentLogs.length ? (
                                agentLogs.map((elem, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCell}>{elem}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Tooltip title="Download">
                                                <PlayCircleFilledWhiteOutlinedIcon
                                                    className={classes.downloadIcon}
                                                    onClick={() => handleClick(elem)}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={headCell.length} className={classes.emptyState}>
                                        No logs available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default AgentLogsList;
