import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Menu from '@material-ui/core/Menu';
import ListIcon from '@material-ui/icons/List';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Download from '@material-ui/icons/GetApp';

import { HeaderTitle } from '../../store/auxiliary/auxiliary.actions';
import { getTextFile, setTextFile, setVideoUrl, setVideoPath, downloadFile } from '../../store/preview/preview.actions';

import { TITLE, PREVIEW } from '../../variables/common';

const Artifacts = ({ artifacts }) => {
    const buttonRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = () => {
        setAnchorEl(true);
    };

    const handlePreview = (type, link) => {
        // clear old preview
        dispatch(setTextFile(''));
        dispatch(setVideoUrl(''));
        // chose preview type
        let _viewerType = type == 'video' ? 'video': 'text'
        if (type === 'video') {
            dispatch(setVideoPath({ link }));//
        }
        else {
            dispatch(getTextFile(link));
        }

        dispatch(HeaderTitle(TITLE.PREVIEW));
        history.push({ pathname: PREVIEW, state: { type: _viewerType, link } });
    };

    const handleDownload = (link, type, sid) => {
        dispatch(
            downloadFile({path: link, type: type, sid: sid})
        );
    };

    return (
        <div className="logs-settings">
            <Toolbar className="log-menu-wrapper">
                <IconButton
                    edge="start"
                    ref={buttonRef}
                    aria-label="menu"
                    className="icon-wrapper"
                    onClick={handleMenu}>
                    <ListIcon className="settings-icon" />
                </IconButton>

                <Menu
                    id="menu-appbar"
                    keepMounted={false}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={() => buttonRef.current}
                    PaperProps={{ style: { transform: 'translateX(-10px) translateY(10px)', width: '150px' } }}>
                    {anchorEl &&
                        artifacts.map(({ type, link, sid }) => (
                            <MenuItem disabled={!link} key={type} className="artifact-item artifact-menu-item">
                                <div
                                    className="artifact-item-preview"
                                    onClick={() => handlePreview(type, link)}>
                                    {type}
                                </div>

                                <Download className="artifact-item-download" onClick={() => handleDownload(link, type, sid)} />
                            </MenuItem>
                        ))}
                </Menu>
            </Toolbar>
        </div>
    );
};

export default Artifacts;
