import React, { useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { v4 } from 'uuid';
import VirtualDevices from './VirtualDevices';
import AgentLogsList from './AgentLogsList';

import Menu from '@material-ui/core/Menu';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import HelpIcon from '@material-ui/icons/Help';
import StopIcon from '@material-ui/icons/Stop';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { agentActions, getListVirtualDevices, deleteAgentActions, getAgentLogsList } from '../../../../../store/admin/admin.actions';

const AgentSettings = ({ name, metaData = {} }) => {
    const buttonRef = useRef();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [confirm, setConfirm] = useState({ restart: false, stop: false, delete: false });

    const metaDataList = [];
    const virtualDevices = useSelector((state) => state.admin.virtualDevicesAgent, shallowEqual);
    const agentLogs = useSelector((state) => state.admin.agentLogs, shallowEqual);

    const isObject = (value) => {
        return typeof value === 'object' && value !== null;
    };

    const createMetaDataList = (metaData) => {
        for (let key in metaData) {
            isObject(metaData[key]) ? createMetaDataList(metaData[key]) : metaDataList.push([key, metaData[key]]);
        }
    };

    createMetaDataList(metaData);

    const handleMenu = (event) => {
        event.preventDefault();
        setAnchorEl(true);
    };

    const handleOpenInfoAgent = () => {
        setAnchorEl(null);
        setOpenModal(true);
    };

    const handleCloseInfoAgent = () => {
        setOpenModal(false);
    };

    const handleRestartAgent = () => {
        setAnchorEl(null);
        setConfirm({ ...confirm, restart: true });
    };

    const handleStopAgent = () => {
        setAnchorEl(null);
        setConfirm({ ...confirm, stop: true });
    };
    const handleDeleteAgent = () => {
        setAnchorEl(null);
        setConfirm({ ...confirm, delete: true });
    };

    const handleGetListDevices = () => {
        setAnchorEl(null);
        setShowTable(true);
        setShowLogs(false);
        dispatch(getListVirtualDevices(name));
    };

    const handleGetLogsList = () => {
        setAnchorEl(null);
        setShowLogs(true);
        setShowTable(false);
        dispatch(getAgentLogsList(name));
    };

    const handleHideTable = () => {
        setShowTable(false);
        setShowLogs(false);
    };

    const menuItems = [
        [handleRestartAgent, 'Restart Agent', <AutorenewIcon className="agent-menu-icon" key={v4()} />, false],
        [handleStopAgent, 'Stop Agent', <StopIcon className="agent-menu-icon" key={v4()} />, false],
        [handleDeleteAgent, 'Delete Agent', <DeleteIcon className="agent-menu-icon" key={v4()} />, false],
        [
            handleOpenInfoAgent,
            'Info Agent',
            <FormatListNumberedIcon className="agent-menu-icon" key={v4()} />,
            !metaDataList.length,
        ],
        [handleGetListDevices, 'VD Manager', <AddCircleOutlineIcon className="agent-menu-icon" key={v4()} />, false],
        [handleGetLogsList, 'Agent Logs', <AddCircleOutlineIcon className="agent-menu-icon" key={v4()} />, false]
    ];

    const renderConfirmMessage = (type, command, agentAction) => {
        return (
            <Dialog
                open={type}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setConfirm({ ...confirm, [command]: false })}>
                <DialogTitle id="alert-dialog-title">
                    <HelpIcon className="reboot-confirm-icon" />
                    Are you sure you want to {command} agent?
                </DialogTitle>

                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            dispatch(agentAction({ agentUrl: name, command }));
                            setConfirm({ ...confirm, [command]: false });
                        }}>
                        Confirm
                    </Button>

                    <Button color="primary" onClick={() => setConfirm({ ...confirm, [command]: false })}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <div className="agent-settings">
            <Toolbar className="agent-menu-wrapper">
                <IconButton
                    edge="start"
                    ref={buttonRef}
                    aria-label="menu"
                    className="icon-wrapper"
                    onContextMenu={handleMenu}
                    data-testid="agent-button-test">
                    <Tooltip title="Agent settings" placement="right-start">
                        <SettingsIcon className="settings-icon" />
                    </Tooltip>
                </IconButton>

                <Menu
                    id="menu-appbar"
                    keepMounted={false}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={() => buttonRef.current}
                    PaperProps={{ style: { transform: 'translateX(-10px) translateY(10px)' } }}>
                    {anchorEl &&
                        menuItems.map(([action, label, icon, status]) => (
                            <MenuItem
                                key={label}
                                onClick={action}
                                disabled={status}
                                className="settings-agent-item agent-menu-item">
                                {icon} {label}
                            </MenuItem>
                        ))}
                </Menu>
            </Toolbar>

            {showTable && virtualDevices && <VirtualDevices {...{ virtualDevices, handleHideTable, agentUrl: name }} />}
            {showLogs && agentLogs && <AgentLogsList {...{ agentLogs, handleHideTable, agentUrl: name }} />}

            {confirm.stop ? renderConfirmMessage(confirm.stop, 'stop', agentActions) : null}
            {confirm.restart ? renderConfirmMessage(confirm.restart, 'restart', agentActions) : null}
            {confirm.delete ? renderConfirmMessage(confirm.delete, 'delete', deleteAgentActions) : null}

            <Modal
                open={openModal}
                onClose={handleCloseInfoAgent}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className="agent-modal-wrapper">
                    <h2 className="agent-modal-title">Configuration:</h2>

                    <ul className="agent-modal-list">
                        {metaDataList.map(([key, value]) => {
                            const isVersion = !isNaN(parseInt(value)) && !key.toLowerCase().includes('version');
                            const prop = isVersion ? `${key}Version` : key;

                            return (
                                <li className="agent-modal-item" key={`${key} ${value}`}>
                                    <span>{prop}:</span>
                                    <span>{value}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Modal>
        </div>
    );
};

export default AgentSettings;
