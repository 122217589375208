import React, { useState } from 'react';
import { AppBar, Box, Button, Container, InputBase, Paper, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Datetime from 'react-datetime';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { getMaintenanceMessage } from '../../../store/maintenance/maintenance.selectors';
import {
    cancelMaintenanceMessage,
    sendMaintenanceMessage,
    sendRefreshNEORP,
    sendForceSyncRepo,
} from '../../../store/maintenance/maintenance.actions';

import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
    wrapper: {
        padding: '20px',
    },
    tabs: {
        color: '#182d44',
        fontSize: 14,
    },

    notification: {
        display: 'flex',
        flexDirection: 'column',
    },
    AppBar: {
        backgroundColor: '#cad8e6',
    },
    label: {
        marginBottom: '10px',
    },
    text: {
        fontWeight: 300,
        wordBreak: 'break-all',
        paddingLeft: '10px',
        marginBottom: '10px',
    },
    inputBody: {
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #d2d2d2',
        padding: '10px',
        boxSizing: 'border-box',
        outline: 'none',
        marginBottom: '20px',
    },
    button: {
        color: '#FFFF',
        backgroundColor: '#314f72',
        boxShadow: '0px 1px 8px 2px rgba(34, 60, 80, 0.2)',
        '&:hover': {
            opacity: 0.9,
            backgroundColor: '#4d9bd5',
        },
    },
    error: {
        display: 'block',
        fontSize: '12px',
        color: '#f99898',
        marginBottom: '20px',
    },
});
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
};
const RefreshNeoRp = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const refreshNEORP = () => {
        dispatch(sendRefreshNEORP());
    };
    return (
        <div className={classes.wrapper}>
            <Button
                type="refresh_neo_rp"
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={refreshNEORP}>
                Refresh_NEORP
            </Button>
        </div>
    );
};
const ForceSyncRepository = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const ForceSyncRepo = () => {
        dispatch(sendForceSyncRepo());
    };
    return (
        <div className={classes.wrapper}>
            <Button
                type="sync_repo"
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={ForceSyncRepo}>
                Synchronize Repositorium
            </Button>
        </div>
    );
};

const MaintenanceMessage = () => {
    const classes = useStyles();
    const maintenanceMessage = useSelector(getMaintenanceMessage);
    const dispatch = useDispatch();

    const minDate = () => {
        const time = new Date().getTime();
        const date = time + 60000;
        return moment(date).toString();
    };

    const validationSchema = yup.object({
        text: yup
            .string()
            .trim()
            .min(3, 'Enter at least 3 characters')
            .max(2000, 'Enter less than 2000 characters')
            .required('Field is required!'),
        endDate: yup.date().min(minDate(), 'Date must be 1 minute or more later than current date.'),
    });

    const formik = useFormik({
        initialValues: {
            text: '',
            endDate: new Date(),
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(sendMaintenanceMessage(values));
        },
    });

    const handleChange = (e) => {
        const { value } = e.target;
        formik.setFieldValue('text', value);
    };

    const handleDateChange = (e) => {
        const date = new Date(e);
        formik.setFieldValue('endDate', date);
    };

    const handleCancel = () => {
        dispatch(cancelMaintenanceMessage());
    };

    const valid = (currentDate) => {
        const yesterday = moment().subtract(1, 'day');
        return currentDate.isAfter(yesterday);
    };

    return (
        <Container component="div" maxWidth="sm">
            <Paper variant="elevation" elevation={3} className={classes.wrapper}>
                {maintenanceMessage ? (
                    <Box component="div" className={classes.notification}>
                        <Typography component="p" className={classes.label}>
                            Notification text:
                        </Typography>
                        <Typography component="p" className={classes.text}>
                            {maintenanceMessage.text}
                        </Typography>
                        <Typography component="p" className={classes.label}>
                            End time:
                        </Typography>
                        <Typography component="p" className={classes.text}>
                            {moment(maintenanceMessage.endDate).format('LLL')}
                        </Typography>
                        <Button
                            variant="contained"
                            className={classes.button}
                            style={{ alignSelf: 'flex-end' }}
                            size="medium"
                            onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Box>
                ) : (
                    <Box component="form" onSubmit={formik.handleSubmit}>
                        <Typography component="p" className={classes.label}>
                            Notification text
                        </Typography>
                        <InputBase
                            id="text"
                            name="text"
                            value={formik.values.text}
                            placeholder="Enter notification"
                            rows={4}
                            maxLength="2000"
                            className={classes.inputBody}
                            autoComplete="text"
                            multiline
                            autoFocus
                            fullWidth
                            onChange={handleChange}
                        />
                        {formik.touched.text && formik.errors.text ? (
                            <Typography component="span" className={classes.error}>
                                {formik.errors.text}
                            </Typography>
                        ) : null}
                        <Typography component="p" className={classes.label}>
                            Choose end time
                        </Typography>
                        <Datetime
                            name="endDate"
                            inputProps={{
                                placeholder: 'end date',
                                className: classes.inputBody,
                            }}
                            initialValue={formik.values.endDate}
                            isValidDate={valid}
                            onChange={handleDateChange}
                        />
                        {formik.touched.endDate && formik.errors.endDate ? (
                            <Typography component="span" className={classes.error}>
                                {formik.errors.endDate}
                            </Typography>
                        ) : null}

                        <Button type="submit" variant="contained" className={classes.button} fullWidth>
                            Submit
                        </Button>
                    </Box>
                )}
            </Paper>
        </Container>
    );
};
const Maintenance = () => {
    const classes = useStyles();
    const [TabNumber, setTabNumber] = useState(0);
    return (
        <Paper className={classes.root} variant="elevation" elevation={3}>
            <AppBar
                component="div"
                position="relative"
                classes={{
                    positionRelative: classes.AppBar,
                }}>
                <Tabs value={TabNumber} TabIndicatorProps={{ style: { backgroundColor: '#314f72f5' } }} centered>
                    <Tab
                        label="maintaince message"
                        onClick={() => {
                            setTabNumber(0);
                        }}
                        className={classes.tabs}
                    />
                    <Tab
                        label="neo resource provider"
                        onClick={() => {
                            setTabNumber(1);
                        }}
                        className={classes.tabs}
                    />
                     <Tab
                        label="repositorium"
                        onClick={() => {
                            setTabNumber(2);
                        }}
                        className={classes.tabs}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={TabNumber} index={0}>
                <MaintenanceMessage />
            </TabPanel>

            <TabPanel value={TabNumber} index={1}>
                <RefreshNeoRp />
            </TabPanel>
            <TabPanel value={TabNumber} index={2}>
                <ForceSyncRepository />
            </TabPanel>
        </Paper>
    );
};

export default Maintenance;
