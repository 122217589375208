import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box, IconButton, MenuItem, Menu, Toolbar } from '@material-ui/core/';

import Parameters from './Parameters';
import FileManager from './FileManager';
import Applications from './Applications';
import ElementTrees from './ElementTrees';

import {
    getDeviceInstalledApp,
    getDeviceFolder,
    getElementTrees,
    setSessionTab,
} from '../../../store/interactive/interactive.actions';

const useStyles = makeStyles({
    menu: {
        '& .MuiPaper-root ul': {
            padding: '0',
        },
    },
    toolbar: {
        minHeight: '48px',
        borderBottom: '0px',
        backgroundColor: '#314f72',
        borderTop: '1px solid #7e767638',
    },
    iconButton: {
        color: '#f9f9f9fc',
        transition: '0.3s',
        '&:hover': {
            color: '#778da6',
        },
    },
});

const RightPanelTabs = withStyles({
    root: {
        borderBottom: '0px',
        backgroundColor: '#314f72',
        borderTop: '1px solid #7e767638',
    },
    indicator: {
        backgroundColor: '#f9f9f9fc',
    },
    flexContainer: {
        justifyContent: 'space-evenly',
    },
})(Tabs);

const RightPanelTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightNormal,
        fontSize: 14,
        minWidth: 50,
        backgroundColor: '#314f72',
        color: '#778da6',
        opacity: 1,
        fontFamily: ['Roboto'].join(','),
        '&:hover': {
            color: '#FFFFFF',
            opacity: 1,
        },
        '&$selected': {
            color: '#f9f9f9fc',
            opacity: 1,
            backgroundColor: '#314f72',
        },
        '&:focus': {
            color: '#FFFFFF',
            opacity: 1,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
};

const RightPanel = (props) => {
    const classes = useStyles();
    const { activeDevice } = props;

    const buttonRef = useRef();
    const [value, setValue] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const mediumScreen = useMediaQuery('(max-width:1280px)');

    const dispatch = useDispatch();
    const AppiumSID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);

    const menuItems = [
        [null, 'Parameters'],
        [getDeviceInstalledApp({ SID: AppiumSID, deviceOs: activeDevice.os }), 'Applications'],
        [getDeviceFolder({ SID: AppiumSID }), 'File Manager'],
        [getElementTrees({ SID: AppiumSID }), 'Element Trees'],
    ];

    const handleMenu = () => setAnchorEl(true);
    const handleChange = (event, newValue) => setValue(newValue);
    const handleMouseLeave = (event) => (event.target.style.backgroundColor = '#ffffff');
    const handleMouseEnter = (event) => (event.target.style.backgroundColor = 'rgba(0, 0, 0, 0.05)');

    const handleMenuItem = (deviceAction, value, label) => {
        setValue(value);
        setAnchorEl(null);
        dispatch(setSessionTab(label));
        deviceAction && dispatch(deviceAction);
    };

    useEffect(() => {
        if (!mediumScreen) {
            setAnchorEl(false);
        }
    }, [mediumScreen]);

    return (
        <>
            {!mediumScreen ? (
                <RightPanelTabs value={value} onChange={handleChange} aria-label="ant example">
                    {menuItems.map(([deviceAction, label], idx) => (
                        <RightPanelTab
                            key={idx}
                            label={label}
                            onClick={() => {
                                dispatch(setSessionTab(label));
                                deviceAction && dispatch(deviceAction);
                            }}
                        />
                    ))}
                </RightPanelTabs>
            ) : (
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        ref={buttonRef}
                        aria-label="menu"
                        onClick={handleMenu}
                        className={classes.iconButton}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        open={open}
                        id="menu-appbar"
                        keepMounted={false}
                        className={classes.menu}
                        onClose={() => setAnchorEl(null)}
                        anchorEl={() => buttonRef.current}
                        PaperProps={{ style: { transform: 'translateX(10px) translateY(10px)' } }}>
                        {menuItems.map(([deviceAction, label], idx) => (
                            <MenuItem
                                key={idx}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleMenuItem(deviceAction, idx, label)}
                                style={{ margin: '0', color: '#000', borderRadius: '0', lineHeight: '2.5' }}>
                                {label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Toolbar>
            )}

            <TabPanel value={value} index={0} className="tree-tab">
                <Parameters activeDevice={activeDevice} />
            </TabPanel>

            <TabPanel value={value} index={1} className="tree-tab">
                <Applications activeDevice={activeDevice} />
            </TabPanel>

            <TabPanel value={value} index={2} className="tree-tab">
                <FileManager activeDevice={activeDevice} />
            </TabPanel>

            <TabPanel value={value} index={3} className="tree-tab">
                <ElementTrees activeDevice={activeDevice} />
            </TabPanel>
        </>
    );
};

export default RightPanel;
