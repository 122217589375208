import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { notificationType } from '../../variables/common';

const NotificationMessage = ({ type, msg, btn }) => {
    const [isHidden, setIsHidden] = useState(false);
    const styles = ['notification'];

    if (isHidden) {
        styles.push('hidden');
    }

    if (btn) {
        return (
            <Alert severity={type} className={styles.join(' ')} onClose={() => setIsHidden(true)}>
                {msg}
            </Alert>
        );
    }

    return (
        <Alert severity={type} className={styles.join(' ')}>
            {msg}
        </Alert>
    );
};

export const Notification = ({ type, msg, btn = true }) => {
    return type && notificationType.some((item) => item === type) ? (
        <NotificationMessage {...{ msg, type, btn }} />
    ) : null;
};
