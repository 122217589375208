import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import DraggableCore from 'react-draggable';

import {
    uploadFile,
    setIsRename,
    SubElements,
    downloadFile,
    createFolder,
    deleteFolder,
    selectContext,
    cretionDirElem,
    getDirectoryList,
    renameFileorFolder,
    toggleFileForlder,
} from '../../store/repositorium/repositorium.actions';
import { setVideoPath } from '../../store/preview/preview.actions';
import { installApp } from '../../store/interactive/interactive.actions';
import { backButton, HeaderTitle } from '../../store/auxiliary/auxiliary.actions';

import Spiner from '../Common/Loader';
import { Header } from './components/Header';
import { Folders } from './components/Folders';

import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import CircularProgress from '@material-ui/core/CircularProgress';

import { copyText } from '../../../helpers/copyText';

import { MAIN_PAGE, TITLE, PREVIEW, repositoriumFolder } from '../../variables/common';

const useStyles = makeStyles({
    wrap: {
        width: '100%',
        padding: '0 0 0 15px',
        position: 'relative',
        '& .file-list-container': {
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: '#4d9bd5',
            color: '#FFFF',
            padding: '0 15px',
        },
        '& .table-cell': {
            '&:focus': {
                outline: 'none',
            },
            '& .cell-actions': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                '& .Mui-disabled': {
                    '& .icon': {
                        color: 'rgba(0, 0, 0, 0.26)',
                    },
                },
                '& .btn': {
                    minWidth: 0,
                    padding: 0,
                    '& .icon': {
                        color: '#6b6b6b',
                        fontSize: 18,
                        marginRight: 7,
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#314f72',
                        },
                    },
                },
            },
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-row': {
            color: '#6b6b6b',
            fontWeight: 400,
        },
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#4d9bd5',
        },
    },
    root: {
        '& .MuiDataGrid-footer': {
            padding: '0 70px',
            borderTop: '1px solid #e9e5e5',
        },
    },
    fab: {
        position: 'absolute',
        right: '5px',
        bottom: 0,
        backgroundColor: '#4d9bd5',
        '&:hover': {
            backgroundColor: '#314f72',
        },
    },
    modal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        borderColor: 'transparent',
    },
    paper: {
        borderColor: 'transparent',
        backgroundColor: '#FFFF',
        borderRadius: '10px',
    },
    snackbar: {
        borderRadius: 5,
        '& .anchorTopRight': {
            top: 0,
            right: 0,
        },
    },
    snackbar_content: {
        backgroundColor: '#53a337',
        boxShadow: '4px 4px 12px -2px rgb(159 166 189 / 42%)',
        borderRadius: '4px',
    },
    anchorTopRight: {
        top: 0,
        right: 0,
    },
});

const TabsControlls = ({ fileFolder }) => {
    const dispatch = useDispatch();
    return (
        <div className="tabs-wrapper">
            <Button
                className={`tab ${fileFolder === 'file' && 'file'}`}
                onClick={() => dispatch(toggleFileForlder('file'))}>
                File
            </Button>
            <Button
                className={`tab ${fileFolder === 'folder' && 'folder'}`}
                onClick={() => dispatch(toggleFileForlder('folder'))}>
                Folder
            </Button>
        </div>
    );
};

export const Repositorium = () => {
    const style = useStyles();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();

    const AppiumSID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);
    const activeDevice = useSelector((state) => state.device.activeDevice, shallowEqual);
    const loadingPercent = useSelector((state) => state.repositorium.loadingPercent, shallowEqual);
    const contextElement = useSelector((state) => state.repositorium.contextElement, shallowEqual);
    const backButtonData = useSelector((state) => state.auxiliary.backButtonData, shallowEqual);
    const directories = useSelector((state) => state.repositorium.directories, shallowEqual);
    const fileFolder = useSelector((state) => state.repositorium.fileFolder, shallowEqual);
    const selected = useSelector((state) => state.repositorium.selectedRepo, shallowEqual);
    const addPopup = useSelector((state) => state.auxiliary.addPopup, shallowEqual);
    const isRename = useSelector((state) => state.repositorium.isRename, shallowEqual);
    const Title = useSelector((state) => state.auxiliary.Title, shallowEqual);

    const [addPopupDelete, setAddPopupDelete] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [pathForRename, setPathForRename] = useState(null);
    const [instaling, setInstaling] = useState(false);
    const [folder, setFolder] = useState('');
    const [rename, setRename] = useState(undefined);
    const [isEmpty, setIsEmpty] = useState(false);
    const [file, setFile] = useState(undefined);
    const [path, setPath] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);

    let columns = [
        {
            field: 'baseName',
            headerName: 'File name',
            flex: pathname === MAIN_PAGE ? 1 : 1.5,
            headerClassName: 'file-list-container',
            cellClassName: 'table-cell',
            renderCell: (params) => getNameWithoutExtention(params.row),
        },
        {
            field: 'path',
            headerName: 'Locator',
            flex: pathname === MAIN_PAGE ? 1 : 1.5,
            headerClassName: 'file-list-container',
            cellClassName: 'table-cell',
            renderCell: (params) => getLocator(params.row.path),
        },
        {
            field: 'mtime',
            headerName: 'Upload Date',
            flex: 0.8,
            headerClassName: 'file-list-container',
            cellClassName: 'table-cell',
            valueGetter: (params) => params.row.metadata?.mtime,
            renderCell: (params) => UploadTime(params.row),
        },
        {
            field: 'ext',
            flex: pathname === MAIN_PAGE ? 0.5 : 0.45,
            headerName: 'File type',
            headerClassName: 'file-list-container',
            cellClassName: 'table-cell',
            valueGetter: (params) => params.row.metadata?.ext,
            renderCell: (params) => ifIsFolder(params),
        },
        {
            field: 'size',
            headerName: 'Size',
            flex: 0.5,
            headerClassName: 'file-list-container',
            cellClassName: 'table-cell',
            valueGetter: (params) => params.row.metadata?.size,
            renderCell: (params) => calculateMB(params.row.metadata?.size),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: pathname === MAIN_PAGE ? 0.6 : 0.45,
            headerClassName: 'file-list-container',
            cellClassName: 'table-cell',
            renderCell: (params) => cellActions(params),
        },
    ];

    if (pathname !== MAIN_PAGE) {
        columns = [columns[0], columns[1], columns[3], columns[5]];
    }

    function cellActions(params) {
        return (
            <div className={'cell-actions'}>
                {pathname === MAIN_PAGE ? (
                    <>
                        <Button className={'btn'} onClick={() => copyPath(params.row.path)}>
                            <FileCopyIcon className={'icon'} />
                        </Button>

                        <Button className={'btn'} onClick={() => downLoadFile(params.row)}>
                            <GetAppIcon className={'icon'} />
                        </Button>

                        <Button
                            className={'btn'}
                            onClick={() => beforeDeleteFile(params)}
                            disabled={isDisableToDelete(params)}>
                            <DeleteIcon className={'icon'} />
                        </Button>

                        <Button
                            className={'btn'}
                            onClick={() => openRenameFileModal(params)}
                            disabled={protectedFolder(params.row)}>
                            <EditIcon className={'icon'} />
                        </Button>

                        {params.row.metadata?.ext === '.mp4' && (
                            <Button className={'btn'} onClick={() => openPreviewVideoFile(params)}>
                                <VisibilityIcon className={'icon'} />
                            </Button>
                        )}
                    </>
                ) : null}
                {pathname !== MAIN_PAGE && /(\.apk)|(\.ipa)/.test(params.row.metadata?.ext) && (
                    <Button className={'btn'} onClick={() => installAppOnDevice(params)} disabled={!AppiumSID}>
                        {instaling ? (
                            <div style={{ width: '18px', height: '18px' }}>
                                <Loader color="black" type="Triangle" width="18" height="18" />
                            </div>
                        ) : (
                            <SystemUpdateIcon className={'icon'} />
                        )}
                    </Button>
                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    classes={{
                        root: style.snackbar,
                        anchorOriginTopRight: style.anchorTopRight,
                    }}
                    ContentProps={{
                        classes: {
                            root: style.snackbar_content,
                        },
                    }}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message="The path was successfully copied"
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </div>
        );
    }

    function ifIsFolder(params) {
        if (params.row.metadata?.ext) {
            return params.row.metadata.ext;
        } else {
            return <FolderOpenIcon fontSize="small" />;
        }
    }

    function protectedFolder(contextElement) {
        let isProtected = false;
        if (contextElement) {
            const { baseName } = contextElement;
            // eslint-disable-next-line
            repositoriumFolder.map((value) => {
                const check = baseName.toLowerCase().includes(`#${value}`);

                const regex = new RegExp('^' + value + '$', 'i');
                const isMatch = baseName.match(regex);

                if ((isMatch && baseName === isMatch[0]) || check) {
                    return (isProtected = true);
                }
            });
        }
        return isProtected;
    }

    function isFolderEmpty(contextElement) {
        if (contextElement.type === 'folder') {
            const { children } = contextElement;
            return !children.length;
        } else {
            return true;
        }
    }

    function isDisableToDelete(elem) {
        return !isFolderEmpty(elem.row) || protectedFolder(elem.row);
    }

    function uploadHandlerClick() {
        dispatch(uploadFile(file, path, file.name));
    }

    function openRenameFileModal(elem) {
        setPathForRename(elem.row.path);
        setRename(elem.row.name);
        dispatch(
            selectContext({
                name: elem.row.name,
                type: elem.row.type,
                extension: elem.row.extension,
            })
        );
        dispatch(setIsRename(true));
        dispatch(cretionDirElem(true));
        setRename(elem.row.name);
    }

    function openPreviewVideoFile(elem) {
        dispatch(setVideoPath(elem?.row?.path));
        dispatch(HeaderTitle(TITLE.PREVIEW));
        history.push({ pathname: PREVIEW, state: { ext: 'mp4' } });
    }

    function deleteFile() {
        dispatch(deleteFolder(fileToDelete));
        handleClose();
    }

    function beforeDeleteFile(elem) {
        const path = elem?.row?.path;
        const context = elem?.row;
        const name = elem?.row?.baseName;
        setFileToDelete({
            path,
            name,
            context,
        });
        setAddPopupDelete(true);
    }

    function renameFile() {
        setRename('');
        dispatch(
            renameFileorFolder({
                path,
                rename,
                pathForRename,
                contextElement,
            })
        );
        handleClose();
    }

    function handleClose() {
        setRename('');
        setFolder('');
        setAddPopupDelete(false);
        dispatch(cretionDirElem(false));
        setFileToDelete(null);
    }

    function installAppOnDevice(data) {
        let appPath = data?.row?.path;
        dispatch(installApp(appPath, activeDevice.os, AppiumSID, setInstaling));
    }

    function copyPath(path) {
        const text = getLocator(path);
        copyText(text);
        setOpen(true);
    }

    function downLoadFile(elem) {
        const path = elem?.path;
        const file = elem?.baseName;
        dispatch(downloadFile({ path, file }));
    }

    function getLocator(path) {
        if (path.includes('#')) {
            const position = path.indexOf('#');
            return normalizePath(path.slice(position + 1).replace('/root/private/', 'PRIVATE/'));
        } else {
            // this was used with the previous tree
            return normalizePath(
                // this was used with the previous tree
                path.replace('/root/public/', 'PUBLIC/')
            );
        }
    }

    function normalizePath(path) {
        return path.replaceAll('\\', '/');
    }

    function UploadTime(row) {
        if (row.metadata?.mtime) {
            return new Date(row.metadata?.mtime).toLocaleString();
        } else return '';
    }

    function calculateMB(size) {
        return `${(size * 0.000001).toFixed(2)} MB`;
    }

    function getNameWithoutExtention(param) {
        if (param.type === 'file') {
            return param.baseName.replace(param.metadata?.ext, '');
        } else {
            return param.baseName;
        }
    }

    function findById(acc, el) {
        if (el.id === selected) return el;
        if (el.children) return el.children.reduce(findById, acc);
        return acc;
    }

    const renderTabs = (data) => {
        switch (data) {
            case 'folder': {
                return (
                    <div className="tab-body">
                        <TextField
                            required
                            id="outlined-basic-1"
                            label="Name"
                            variant="outlined"
                            error={!folder}
                            value={folder}
                            className="filed"
                            onChange={(e) => setFolder(e.target.value)}
                        />
                        <TextField
                            disabled={true}
                            id="outlined-basic-3"
                            label="Data"
                            variant="outlined"
                            value={new Date()}
                            className="filed"
                        />
                        <Button
                            className="create-filed"
                            disabled={!folder}
                            onClick={() => {
                                dispatch(createFolder({ path, folder }));
                                handleClose();
                            }}>
                            Create
                        </Button>
                    </div>
                );
            }
            case 'file': {
                return (
                    <div className="tab-body">
                        <input
                            className="upload-file"
                            id="icon-button-file"
                            multiple
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        <label htmlFor="icon-button-file">
                            <IconButton
                                className={`icon-button ${file && 'file-uploaded'}`}
                                aria-label="upload picture"
                                component="span">
                                <CloudUploadIcon />
                            </IconButton>
                        </label>
                        {file && <p>{file.name}</p>}
                        <Button
                            className={`upload-file-btn ${!file && 'disable'}`}
                            disabled={!file}
                            onClick={() => {
                                uploadHandlerClick();
                                setFile(undefined);
                                handleClose();
                            }}>
                            Upload
                        </Button>
                    </div>
                );
            }
            default: {
                break;
            }
        }
    };

    const sortApplicationsByOS = (os) => {
        const extension = os.toLowerCase() === 'android' ? '.apk' : '.ipa';
        return rows.filter((item) => item.type !== 'folder' && item?.metadata?.ext === extension);
    };

    const createRows = (rows) => {
        return pathname === MAIN_PAGE
            ? rows.filter((item) => item.type !== 'folder')
            : sortApplicationsByOS(activeDevice?.os);
    };

    useEffect(
        () => {
            if (backButtonData && backButtonData.isRevised === true) {
                dispatch(SubElements(backButtonData.path));
                dispatch(backButton(undefined));
            } else {
                dispatch(getDirectoryList());
            }
        }, // eslint-disable-next-line
        [Title, dispatch]
    );

    useEffect(() => {
        if (selected) {
            let selectedFolder = directories.reduce(findById, null);
            if (selectedFolder && selectedFolder.type === 'folder') {
                const children = selectedFolder.children;
                setPath(selectedFolder.path);

                setRows([...children]);
            } else {
                setRows(() => []);
            }
        }
        // eslint-disable-next-line
    }, [selected, directories]);

    return (
        <div className="repositorium" data-testid="repo-test">
            <div className="path-drawer">
                {directories ? (
                    <Folders
                        {...{ beforeDeleteFile, downLoadFile, openRenameFileModal, protectedFolder, isDisableToDelete }}
                    />
                ) : (
                    <Spiner />
                )}
            </div>

            <div className={style.wrap}>
                <Header {...{ rows, columns, createRows, path: pathname }} />

                {pathname === MAIN_PAGE && (
                    <Fab
                        data-testid="repo-btn-test"
                        color="primary"
                        aria-label="add"
                        className={style.fab}
                        disabled={!path}
                        onClick={() => {
                            dispatch(cretionDirElem(!addPopup));
                            dispatch(setIsRename(false));
                        }}>
                        <AddIcon />
                    </Fab>
                )}
            </div>

            <Modal
                disablePortal
                open={Boolean(addPopup)}
                disableAutoFocus
                disableEnforceFocus
                onClose={handleClose}
                className={style.modal}
                data-testid="repo-modal-test">
                <DraggableCore>
                    <div className="popup">
                        {!isRename ? (
                            <>
                                <HighlightOffIcon
                                    className="close-icon"
                                    onClick={() => {
                                        dispatch(cretionDirElem(!addPopup));
                                        setFile(undefined);
                                    }}
                                />
                                <h1 className="title">{fileFolder === 'file' ? 'Upload new' : 'Create new'}</h1>
                                <div className="controll-wrapper">
                                    <TabsControlls fileFolder={fileFolder} />
                                    {fileFolder && renderTabs(fileFolder)}
                                </div>
                            </>
                        ) : (
                            <>
                                <HighlightOffIcon
                                    className="close-icon"
                                    onClick={() => {
                                        dispatch(cretionDirElem(!addPopup));
                                        setFile(undefined);
                                    }}
                                />
                                <h1 className="title">Rename file</h1>
                                <div className="controll-wrapper">
                                    <div className="tab-body">
                                        <TextField
                                            defaultValue={rename}
                                            id="outlined-basic-7"
                                            className="rename-file"
                                            onChange={(e) => {
                                                e.target.value.length ? setIsEmpty(false) : setIsEmpty(true);
                                                setRename(e.target.value);
                                            }}
                                        />

                                        <Button
                                            className={`upload-file-btn ${contextElement.name === rename && 'disable'}`}
                                            onClick={() => {
                                                renameFile();
                                            }}
                                            disabled={contextElement.name === rename || isEmpty}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </DraggableCore>
            </Modal>

            <Modal
                disablePortal
                disableAutoFocus
                disableEnforceFocus
                open={addPopupDelete}
                onClose={handleClose}
                className={style.modal}>
                <div className="popup small">
                    <p>
                        Do you want to delete the&ensp;<span>{fileToDelete?.name}</span>&ensp;file?
                    </p>
                    <div className="btn-wrap">
                        <Button className={'upload-file-btn'} onClick={deleteFile}>
                            OK
                        </Button>
                        <Button className={'upload-file-btn'} onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>

            {loadingPercent ? (
                <Box className="loading-wrapper">
                    <CircularProgress variant="determinate" value={loadingPercent} />
                    <Box>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            className="loading-display">
                            {`${Math.round(loadingPercent)}%`}
                        </Typography>
                    </Box>
                </Box>
            ) : null}
        </div>
    );
};
