import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getUsers } from '../../store/user/user.actions';
import { getAllAgents } from '../../store/admin/admin.actions';
import { getDeviceRelations } from '../../store/device/device.actions';
import { handleSubmit } from '../../utils/auth';

import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';

import { Tabpanel } from './components/Tabpanel';
import { Tabcontent } from './components/Tabcontent';
import { Registration } from '../Registration';
import { USERCONTROL_USERS } from '../../variables/common';

const NewUserButton = ({ setDrawerState }) => (
    <Tooltip title="add new user">
        <Fab color="primary" aria-label="add" className="button-wrap" onClick={() => setDrawerState(true)}>
            <PersonIcon />
        </Fab>
    </Tooltip>
);

const UserControl = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const [drawerState, setDrawerState] = useState(false);

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState((prev) => !prev);
    };

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getAllAgents());
        dispatch(getDeviceRelations());
        // eslint-disable-next-line
    }, []);

    return (
        <div className="control-system-wrapper" data-testid="admin-test">
            <div className="tabs-wrap">
                <Tabpanel />
            </div>

            <div className="content-wrap">
                <Tabcontent />
                {pathname === USERCONTROL_USERS && <NewUserButton setDrawerState={setDrawerState} />}
            </div>

            <Drawer
                open={drawerState}
                onClose={() => toggleDrawer(false)}
                anchor="right"
                classes={{
                    root: 'drawer-wrap',
                    paper: 'drawer-container',
                }}>
                <Registration setDrawerState={setDrawerState} handleRegistration={handleSubmit} />
            </Drawer>
        </div>
    );
};

export default UserControl;
