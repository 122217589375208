// Admin
export const ALL_AGENTS = 'ALL_AGENTS';
export const SET_DEVICES_TIMELINE = 'SET_DEVICES_TIMELINE';
export const VIRTUAL_DEVICES_AGENT = 'VIRTUAL_DEVICES_AGENT';
export const USED_VIRTUAL_DEVICES = 'USED_VIRTUAL_DEVICES';
export const AGENT_LOGS = 'AGENT_LOGS';

// Auxiliary
export const IS_EXPIRED = 'IS_EXPIRED';
export const BACK_BUTTON = 'BACK_BUTTON';
export const TOKEN_POPUP = 'TOKEN_POPUP';
export const HEADER_TITLE = 'HEADER_TITLE';
export const SESSIONS_POPUP = 'SESSIONS_POPUP';
export const SET_TAB_NUMBER = 'SET_TAB_NUMBER';
export const SET_CREATION_MODE = 'SET_CREATION_MODE';
export const SET_SERVER_VERSION = 'SET_SERVER_VERSION';
export const SET_VALIDATE_STATUS = 'SET_VALIDATE_STATUS';
export const IS_BROWSER_SESSION = 'IS_BROWSER_SESSION';

// Interactive
export const SET_SCREEN = 'SET_SCREEN';
export const COMMAND_LIST = 'COMMAND_LIST';
export const XML_SOURCE = 'XML_SOURCE';
export const INSTALLED_APP_LIST = 'INSTALLED_APP_LIST';
export const DEVICE_ACTIVE_FOLDER = 'DEVICE_ACTIVE_FOLDER';
export const STREAM_URL = 'STREAM_URL';
export const RECORDING = 'RECORDING';
export const ACTIVE_SESSIONS = 'ACTIVE_SESSIONS';
export const APPIUM_SESSION_ID = 'APPIUM_SESSION_ID';
export const IS_SESSION_EXPIRED = 'IS_SESSION_EXPIRED';
export const IS_SESSION_STOPED = 'IS_SESSION_STOPED';
export const SET_SESSION_TAB = 'SET_SESSION_TAB';
export const SET_INSPECTOR_MODE = 'SET_INSPECTOR_MODE';
export const SET_INSPECTOR_DETAILS = 'SET_INSPECTOR_DETAILS';
export const SET_INSPECTOR_ELEMENT = 'SET_INSPECTOR_ELEMENT';
export const SET_INSPECTOR_ELEMENT_NODES_ID = 'SET_INSPECTOR_ELEMENT_NODES_ID';
export const PREVENT_MULTIPLE_CLICKS = 'PREVENT_MULTIPLE_CLICKS';
export const DEVICE_HAS_PIN_CODE = 'DEVICE_HAS_PIN_CODE';
export const IS_LOADING = 'IS_LOADING';

// Device
export const REAL_DEVICES = 'REAL_DEVICES';
export const INSPECTOR_XY = 'INSPECTOR_XY';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const VIRTUAL_DEVICES = 'VIRTUAL_DEVICES';
export const SET_DEVICE_CAPABILITIES = 'SET_DEVICE_CAPABILITIES';
export const SET_DEVICE_BLE = 'SET_DEVICE_BLE';
export const IS_VIRTUAL_DEVICES_SWITCH = 'IS_VIRTUAL_DEVICES_SWITCH';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA';
export const SET_DEVICE_PLATFORM = 'SET_DEVICE_PLATFORM';

// Repositorium
export const IS_RENAME = 'IS_RENAME';
export const SELECT_CONTEXT = 'SELECT_CONTEXT';
export const CREAT_DIR_ELEM = 'CREAT_DIR_ELEM';
export const DIRECTORY_LIST = 'DIRECTORY_LIST';
export const SET_SELECTED_REPO = 'SET_SELECTED_REPO';
export const TEXT_FILE_DATA = 'TEXT_FILE_DATA';
export const TOGGLE_FILE_FOLDER = 'TOGGLE_FILE_FOLDER';
export const VIDEO_PLAYER_STREAM = 'VIDEO_PLAYER_STREAM';
export const SET_LOADING_PERCENT = 'SET_LOADING_PERCENT';

// Preview
export const SET_TEXT_FILE = 'SET_TEXT_FILE';
export const SET_VIDEO_PATH = 'SET_VIDEO_PATH';

// Notification
export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE';
export const SET_MAINTENANCE_MESSAGE = 'SET_MAINTENANCE_MESSAGE';

// Socket
export const LOGS = 'logs';
export const ERROR = 'error';
export const STREAM = 'stream';
export const DEVICES = 'devices';
export const SESSIONS = 'sessions';
export const SESSION_EXPIRED = 'sessionExpired';
export const SESSION_STOPED = 'sessionStoped';
export const CONNECTED = 'connected';
export const REAUTHORIZE = 'reAuthorize';
export const ADD_DEVICE = 'addDevice';
export const UPDATE_DEVICE = 'updateDevice';
export const DELETE_DEVICE = 'deleteDevice';
export const DELETE_DEVICES = 'deleteDevices';
export const UPDATE_DEVICE_METADATA = 'updateDeviceMetadata';
export const SERVER_VERSION = 'serverVersion';
export const VALIDATE_STATUS = 'validateStatus';
export const MAINTENANCE_MESSAGE = 'addMaintenanceMessage';
export const CANCEL_MAINTENANCE_MESSAGE = 'cancelMaintenanceMessage';
export const STOP_VIDEO_RECORD = 'stopVideoRecord';
export const PONG_RESPONSE = 'pong';

// User
export const ALL_USERS = 'ALL_USERS';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_GROUP = 'SET_USER_GROUP';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_COGNITO_URL = 'SET_COGNITO_URL';

//Execution
export const LOGS_DATA = 'LOGS_DATA';
export const LOGS_INFORMATION_POPUP = 'LOGS_INFORMATION_POPUP';
export const SET_TAGS_DATA = 'SET_TAGS_DATA';
export const SET_LOGS_COUNT = 'SET_LOGS_COUNT';
