import {
    IS_EXPIRED,
    BACK_BUTTON,
    TOKEN_POPUP,
    HEADER_TITLE,
    SET_TAB_NUMBER,
    SESSIONS_POPUP,
    CREAT_DIR_ELEM,
    SET_CREATION_MODE,
    SET_SERVER_VERSION,
    IS_BROWSER_SESSION,
    SET_VALIDATE_STATUS,
} from '../storeActionTypes';

const initialState = {
    tabNumber: 0,
    isExpired: false,
    addPopup: false,
    isSessions: false,
    Title: 'Magnum',
    isTokenPopup: false,
    backButtonData: null,
    serverVersion: null,
    isCreationModeOpen: false,
    isBrowserSession: false,
    validateStatus: false,
};

export function auxiliaryReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_TAB_NUMBER: {
            return { ...state, tabNumber: payload };
        }
        case SET_SERVER_VERSION: {
            return { ...state, serverVersion: payload };
        }
        case SET_VALIDATE_STATUS: {
            return { ...state, validateStatus: payload };
        }
        case BACK_BUTTON: {
            return { ...state, backButtonData: payload };
        }
        case SESSIONS_POPUP: {
            return { ...state, isSessions: payload };
        }
        case TOKEN_POPUP: {
            return { ...state, isTokenPopup: payload };
        }
        case HEADER_TITLE: {
            return { ...state, Title: payload };
        }
        case CREAT_DIR_ELEM: {
            return { ...state, addPopup: payload };
        }
        case SET_CREATION_MODE: {
            return { ...state, isCreationModeOpen: payload };
        }
        case IS_EXPIRED: {
            return { ...state, isExpired: payload };
        }
        case IS_BROWSER_SESSION: {
            return { ...state, isBrowserSession: payload };
        }
        default: {
            return state;
        }
    }
}
