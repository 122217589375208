import React from 'react';
import Loader from 'react-loader-spinner';

const Spiner = () => {
    return (
        <div className="loader-wrapper" data-testid="spiner-test">
            <Loader className="spiner" type="Triangle" color="#fff" />
        </div>
    );
};

export default Spiner;
