import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import DoneIcon from '@material-ui/icons/Done';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import ClearIcon from '@material-ui/icons/Clear';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import { NativeSelect } from '@material-ui/core';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import InputBase from '@material-ui/core/InputBase';
import LockIcon from '@material-ui/icons/Lock';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';

import { updateUserData, deleteUser } from '../../../store/user/user.actions';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
    },
    container: {
        height: '100%',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        margin: '0 0 7px 0',
        boxSizing: 'border-box',
        padding: '2px 10px',
    },
    modal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        borderColor: 'transparent',
    },
    groupTitle: {
        fontSize: '12px',
        /* textTransform: 'uppercase', */
    },
});

export const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const role = useSelector((state) => state.user.role, shallowEqual);
    const usersData = useSelector((state) => state.user.usersData, shallowEqual);

    const [rows, setRows] = useState([]);
    const [rowToEdit, setRowToEdit] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null);
    const [popupDelete, setPopupDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [popupChangeValue, setPopupChangeValue] = useState(false);
    const [passwordPopup, setPasswordPopup] = useState(false);
    const [password, setPassword] = useState({ value: '', show: false, errMessage: ' ' });
    const [isInvalid, setIsInvalid] = useState({ name: false, lastName: false, group: false });

    const setPasswordData = (key, value) => setPassword({ ...password, [key]: value });
    const clearPasswordData = () => setPassword({ value: '', show: false, errMessage: ' ' });

    const columns = [
        { id: 'collaps', align: 'left', width: '5%' },
        { id: 'group', label: 'Group', align: 'left', width: '10%' },
        { id: 'users', label: 'Users', align: 'left', width: '85%' },
    ];

    const handlerDeleteUser = useCallback(
        (id) => {
            dispatch(deleteUser(id));
            setPopupDelete(false);
            setUserToDelete(null);
        },
        [dispatch]
    );

    const changeUserData = useCallback(
        (userData) => {
            dispatch(updateUserData(userData));
            setUserToEdit(null);
        },
        [dispatch]
    );

    function setEditMode(user, id) {
        let tempRows = rows[id];

        tempRows.users.forEach((row) => {
            if (row.id === user.id) {
                row.isEdit = !row.isEdit;
            }
        });

        setRows([...rows.slice(0, id), tempRows, ...rows.slice(id + 1)]);
    }

    function beforeDeleteUser(row) {
        setUserToDelete(row);
        setPopupDelete(true);
    }

    function openPasswordPopup(row) {
        setUserToEdit(row);
        setPasswordPopup(true);
    }

    function handleChangePassword(event) {
        setPasswordData('value', event.target.value);
    }

    function handleSavePassword() {
        if (password.value.length < 6) {
            setPasswordData('errMessage', 'Password must be 6 characters or longer');
        } else {
            changeUserData({ email: userToEdit.email, password: password.value });
            clearPasswordData();
            setPasswordPopup(false);
        }
    }

    function handleClose() {
        setIsInvalid({ name: false, lastName: false, group: false });
        setRowToEdit(false);
        setPopupDelete(false);
        setPopupChangeValue(false);
        setPasswordPopup(false);
        setUserToDelete(null);
        clearPasswordData();
    }

    function handleSaveChanges() {
        let oldUserData = Object.values(usersData)
            .flatMap(({ users }) => users)
            .find((user) => user.id === userToEdit.id);

        let dataToUpdate = { email: userToEdit.email };

        Object.entries(userToEdit).forEach(([key, value]) => {
            if (key !== 'isEdit' && value !== oldUserData[key]) {
                dataToUpdate[key] = value;
            }
        });

        if (dataToUpdate.hasOwnProperty('group')) {
            let temp = { ...dataToUpdate };
            dataToUpdate = temp?.group?.length
                ? { ...dataToUpdate, group: temp?.group?.split(',') }
                : { ...dataToUpdate, group: null };
        }

        changeUserData(dataToUpdate);
        setPopupChangeValue(false);
    }

    function handleChanges(row, idx, group) {
        //eslint-disable-next-line
        const { isEdit, ...rest } = row;
        const currentUser = usersData[group].users
            .filter((user) => user.id === row.id)
            .map((item) => {
                const group = item.group && item.group.join(',');
                return { ...item, group };
            });

        const picked = (obj) => {
            return _.pick(obj, ['name', 'lastName', 'userClass', 'group']);
        };

        if (_.isEqual(picked(row), picked(currentUser[0]))) {
            setEditMode(row, idx);
        } else {
            setUserToEdit(rest);
            setPopupChangeValue(true);
            setEditMode(row, idx);
        }
    }

    function setOpen(index) {
        const temp = rows;
        temp[index].open = !temp[index].open;
        setRows([...temp]);
    }

    function handleChangeUser(row, event, id) {
        const tempRows = rows[id];
        const { name, value } = event.target;
        const regex = /^(?:(?:[a-z0-9_]{1,})(?:,{0,1}))*[a-z0-9_]$/gi;
        const status = value.length && !regex.test(value);

        tempRows.users.forEach((item) => {
            if (item.id === row.id) {
                item[name] = value;
            }
            return item;
        });

        setIsInvalid({ ...isInvalid, [name]: status });
        setRows([...rows.slice(0, id), tempRows, ...rows.slice(id + 1)]);
    }

    const renderInputCell = (name, info, isEdit, value, user, id) => {
        const classes = ['input'];

        if (isEdit) {
            classes.push('active');

            if (isInvalid[name]) {
                classes.push('invalid');
            }
        }

        return (
            <InputBase
                name={name}
                disabled={!isEdit}
                autoComplete="off"
                value={value ?? ''}
                placeholder={info}
                className={classes.join(' ')}
                onChange={(event) => handleChangeUser(user, event, id)}
            />
        );
    };

    const renderUserIcon = (user, id, name) => {
        const { isEdit } = user;
        const isError = Object.values(isInvalid).some(Boolean);

        if (!isEdit) {
            return (
                <Tooltip title="Edit user">
                    <EditIcon className="icon" onClick={() => setEditMode(user, id)} />
                </Tooltip>
            );
        }

        return (
            <>
                {isError ? (
                    <Tooltip title="Clear user">
                        <ClearIcon className="icon" onClick={handleClose} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Save user">
                        <CheckCircleOutlineSharpIcon
                            className="icon check"
                            onClick={() => handleChanges(user, id, name)}
                        />
                    </Tooltip>
                )}
            </>
        );
    };

    const renderUserRow = (user, id, name) => {
        return (
            <Paper tabIndex={-1} elevation={1} key={user.id} role="checkbox" className={classes.wrapper}>
                <div className="device-cell" key={user.email} style={{ width: '35%' }}>
                    <p>{user.email}</p>
                </div>

                <div className="device-cell">{renderInputCell('name', 'Name', user.isEdit, user.name, user, id)}</div>

                <div className="device-cell">
                    {renderInputCell('lastName', 'Last name', user.isEdit, user.lastName, user, id)}
                </div>

                <div className="select-cell-wrap device-cell">
                    <NativeSelect
                        id="simple-select"
                        name="userClass"
                        value={user.userClass}
                        className="role-select"
                        onChange={(event) => handleChangeUser(user, event, id)}
                        disabled={!user.isEdit}>
                        <option className="role-select-item" value={'user'}>
                            user
                        </option>
                        <option className="role-select-item" value={'admin'}>
                            admin
                        </option>
                        {role === 'superuser' && (
                            <option className="role-select-item" value={'superuser'}>
                                superuser
                            </option>
                        )}
                    </NativeSelect>
                </div>

                <div className="device-cell">Cognito: {user.cognitoId ? 'yes' : 'no'}</div>

                <div className="device-cell">
                    {renderInputCell('group', 'Group', user.isEdit, user.group, user, id)}
                </div>

                <div className="device-cell" style={{ justifyContent: 'flex-end' }}>
                    {renderUserIcon(user, id, name)}
                    {role === 'superuser' && (
                        <Tooltip title="Change password">
                            <LockIcon className="icon" onClick={() => openPasswordPopup(user, id, name)} />
                        </Tooltip>
                    )}
                    <Tooltip title="Delete user">
                        <DeleteIcon className="icon" onClick={() => beforeDeleteUser(user, id, name)} />
                    </Tooltip>
                </div>
            </Paper>
        );
    };

    const renderCell = (row, index) => {
        let name = row.name;
        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className="customRow">
                {columns.map((column) => {
                    if (column.id === 'collaps') {
                        return (
                            <TableCell key={column.id} className="cell-root">
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(index)}
                                    classes={{ sizeSmall: classes.sizeSmall }}>
                                    {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                        );
                    }
                    if (column.id === 'group') {
                        return (
                            <TableCell key={column.id} align={column.align}>
                                <div className={classes.groupTitle}>{row.id}</div>
                            </TableCell>
                        );
                    }
                    if (column.id === 'users') {
                        return (
                            <TableCell key={column.id} align={column.align} className="cell-root">
                                <Collapse in={row.open} timeout="auto" unmountOnExit>
                                    <div className="devices-wrap">
                                        {row.users.map((user) => renderUserRow(user, index, name))}
                                    </div>
                                </Collapse>
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        );
    };

    useEffect(() => {
        if (usersData) {
            let children = [];

            children = Object.keys(usersData).map((key) => {
                return {
                    name: key,
                    users: usersData[key]?.users,
                    id: key,
                };
            });

            if (children.length) {
                children.forEach((user) => (user.open = true));
            }

            let tempRows = children.map((item) => {
                let users = item.users.map((user) => ({
                    ...user,
                    isEdit: false,
                    group: user.group ? user.group.join(',') : null,
                }));
                return { ...item, users };
            });

            setRows([...tempRows]);
            setRowToEdit(true);
        } else {
            setRows([]);
            setRowToEdit(true);
        }
    }, [usersData, rowToEdit]);

    return (
        <div className="users-wrap" data-testid="users-test">
            <Paper className={classes.root} variant="elevation" elevation={3}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader={true} aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ width: column.width }}
                                        classes={{
                                            root: 'stickyHeader-custom',
                                        }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>{rows?.map((row, index) => renderCell(row, index))}</TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Modal
                disablePortal
                disableAutoFocus
                disableEnforceFocus
                open={popupDelete}
                onClose={handleClose}
                className={classes.modal}>
                <div className="popup small">
                    <p>
                        Do you want to delete the <span>{userToDelete?.email}</span> user?
                    </p>
                    <div className="btn-wrap">
                        <Button className="upload-file-btn" onClick={() => handlerDeleteUser(userToDelete.id)}>
                            <DoneIcon className="icn-ok" />
                        </Button>
                        <Button className="upload-file-btn" onClick={handleClose}>
                            <ClearIcon className="icn-cancel" />
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                disablePortal
                disableAutoFocus
                disableEnforceFocus
                open={popupChangeValue}
                onClose={handleClose}
                className={classes.modal}>
                <div className="popup small">
                    <p>
                        Do you want to save the changes for <span>{userToEdit?.email}</span> user?
                    </p>
                    <div className="btn-wrap">
                        <Button className="upload-file-btn" onClick={handleSaveChanges}>
                            <DoneIcon className="icn-ok" />
                        </Button>
                        <Button className="upload-file-btn" onClick={handleClose}>
                            <ClearIcon className="icn-cancel" />
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                disablePortal
                disableAutoFocus
                disableEnforceFocus
                open={passwordPopup}
                onClose={handleClose}
                className={classes.modal}>
                <div className="popup small">
                    <p>
                        Update password for <span>{userToEdit?.email}</span> user
                    </p>
                    <FormControl variant="standard" className="password-form">
                        <InputLabel htmlFor="standard-adornment-password">New password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={password.show ? 'text' : 'password'}
                            value={password.value}
                            onChange={handleChangePassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setPasswordData('show', !password.show)}>
                                        {password.show ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText component="span" className="error">
                            {password.errMessage}
                        </FormHelperText>
                    </FormControl>
                    <div className="btn-wrap">
                        <Button className="upload-file-btn" onClick={handleSavePassword}>
                            <DoneIcon className="icn-ok" />
                        </Button>
                        <Button className="upload-file-btn" onClick={handleClose}>
                            <ClearIcon className="icn-cancel" />
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
