import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { sessionsPopup, isBrowserSession, HeaderTitle } from '../../store/auxiliary/auxiliary.actions';
import {
    clearStateOnSessionOpen,
    clearStateOnSessionStop,
    Recording,
    stopVideoRecord,
} from '../../store/interactive/interactive.actions';

import CloseIcon from '@material-ui/icons/Close';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';

import { getDeviceInfo } from '../../utils/session';
import { ACTIVE_SESSION, MAIN_PAGE, DEVICE, TITLE } from '../../variables/common';

export const ActiveSessionsPopup = ({ openActiveSession }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [deviceInfo, setDeviceInfo] = useState([]);
    const [controllClick, setControllClick] = useState(false);

    const realDevices = useSelector((state) => state.device.realDevices, shallowEqual);
    const virtualDevices = useSelector((state) => state.device.virtualDevices, shallowEqual);
    const activeSessions = useSelector((state) => state.interactive.activeSessions, shallowEqual);
    const isRecording = useSelector((state) => state.interactive.recording, shallowEqual);

    const { POPUP_HEADER, POPUP_TITLE, ACTION, TYPE } = ACTIVE_SESSION;

    const openActiveSessionHandler = (elem) => {
        dispatch(clearStateOnSessionOpen(elem));
        dispatch(HeaderTitle(TITLE.MAGNUM));
        openActiveSession(elem);
        dispatch(Recording(elem.recording));
        elem.sessionType === TYPE && dispatch(isBrowserSession(true));
    };

    const stopActiveSessionHandler = (elem) => {
        isRecording && dispatch(stopVideoRecord());
        dispatch(clearStateOnSessionStop(elem));
        history.push(MAIN_PAGE);
        setControllClick(elem.sid);
    };

    const closeSessionPopup = () => {
        dispatch(sessionsPopup(false));
    };

    useEffect(
        () => {
            const deviceInfoData = [realDevices, virtualDevices, activeSessions];
            const newDeviceInfo = getDeviceInfo(...deviceInfoData);

            if (newDeviceInfo) {
                setDeviceInfo(newDeviceInfo);
            }
        },
        // eslint-disable-next-line
        [realDevices, virtualDevices]
    );

    return (
        <div className="active-sessions-wrapper">
            <div className="sessions">
                <div className="popup-header">
                    <p>{POPUP_HEADER}</p>
                    <CloseIcon data-testid="close-icon" onClick={closeSessionPopup} />
                </div>

                <div className="device-wrapper">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {POPUP_TITLE.map((cell, idx) => (
                                    <TableCell key={idx} align="left">
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deviceInfo &&
                                deviceInfo.map((elem, index) => {
                                    const { model, deviceId, sid, sessionType } = elem;
                                    const POPUP_CONTENT = [model, deviceId, sid, sessionType];

                                    return (
                                        <TableRow key={index}>
                                            {POPUP_CONTENT.map((cell, idx) => (
                                                <TableCell key={idx} align="left">
                                                    {cell}
                                                </TableCell>
                                            ))}

                                            <TableCell align="left">
                                                <Button
                                                    onClick={() => openActiveSessionHandler(elem)}
                                                    disabled={
                                                        controllClick === elem.sid || location.pathname === DEVICE
                                                    }>
                                                    {sessionType === TYPE ? (
                                                        <span>{ACTION.OPEN}</span>
                                                    ) : (
                                                        <span>{ACTION.CONNECT}</span>
                                                    )}
                                                </Button>
                                            </TableCell>

                                            <TableCell align="left">
                                                <Button
                                                    onClick={() => stopActiveSessionHandler(elem)}
                                                    disabled={controllClick === elem.sid}>
                                                    {ACTION.STOP}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    );
};
