import {
    ALL_USERS,
    SET_USER_ROLE,
    SET_USER_GROUP,
    SET_USER_EMAIL,
    SET_USER_ERROR,
    SET_COGNITO_URL,
    SET_SERVER_VERSION,
} from '../storeActionTypes';

const initialState = {
    usersData: null,
    loginError: null,
    cognitoURL: null,
    serverVersion: null,
    group: '',
};

export function userReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ALL_USERS: {
            return { ...state, usersData: payload };
        }
        case SET_USER_ROLE: {
            return { ...state, role: payload };
        }
        case SET_USER_GROUP: {
            return { ...state, group: payload };
        }
        case SET_USER_EMAIL: {
            return { ...state, email: payload };
        }
        case SET_COGNITO_URL: {
            return { ...state, cognitoURL: payload };
        }
        case SET_SERVER_VERSION: {
            return { ...state, serverVersion: payload };
        }
        case SET_USER_ERROR: {
            return { ...state, loginError: payload };
        }
        default: {
            return state;
        }
    }
}
