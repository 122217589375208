import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const SelectDialog = ({ isOpen, setIsOpen, isPopupOpen, action }) => {
    const createButton = (title, value) => (
        <Button color="primary" onClick={() => !isPopupOpen && action(value)}>
            {title}
        </Button>
    );

    return (
        <div>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setIsOpen(false)}>
                <DialogTitle id="alert-dialog-title">
                    <SaveIcon className="save-confirm-icon" />
                    Choose a path to save the file:
                </DialogTitle>

                <DialogContent>
                    <div className="select-path-group">
                        <p>Default path:</p>
                        {createButton('Confirm', 'save_default')}
                    </div>

                    <div className="select-path-group">
                        <p>Choose a path:</p>
                        {createButton('Change', 'save_select')}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SelectDialog;
