import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Table from '@material-ui/core/Table';
import StopIcon from '@material-ui/icons/Stop';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';

import { updateListVirtualDevices } from '../../../../../store/admin/admin.actions';
import VirtualDevicesPopup from './VirtualDevicesPopup';
import { DEVICE_STATUS_CODES } from '../../../../../variables/common';

const useStyles = makeStyles({
    tableCell: {
        padding: '17px',
    },
});

const VirtualDevices = ({ agentUrl, virtualDevices, handleHideTable }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [device, setDevice] = useState('');
    const [command, setCommand] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const isAndroid = virtualDevices.some((item) => item.os !== 'iOS');

    const headCell = isAndroid
        ? ['Name', '', '']
        : ['Model', 'Manufacturer', 'OS', 'OS Version', 'Status', 'Device ID', 'Firmware', '', '']; // empty fields for buttons stop/start

    const handleClick = (device, isAndroid, command) => {
        dispatch(updateListVirtualDevices({ device, isAndroid, command, agentUrl }));
        isAndroid ? setDevice(device) : setDevice('');
        setCommand(command);
        setIsOpen(true);
    };

    return (
        <div className="simulators-wrapper">
            <VirtualDevicesPopup {...{ isOpen, setIsOpen, command, device }} />

            <div className="simulators-content" style={isAndroid ? { width: '450px' } : { width: '1200px' }}>
                <div className="simulators-popup">
                    <p>Virtual devices</p>
                    <CloseIcon className="close-icon" onClick={handleHideTable} />
                </div>

                <Table aria-label="simple">
                    <TableHead className="simulators-table-head">
                        <TableRow>
                            {headCell.map((item, idx) => (
                                <TableCell className={classes.tableCell} key={`${item}${idx}`}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isAndroid
                            ? virtualDevices.map((elem, index) => (
                                  <TableRow key={`${elem}${index}`}>
                                      <TableCell className={classes.tableCell}>{elem.model}</TableCell>

                                      <TableCell width="12%" className={classes.tableCell}>
                                          <Tooltip title="Stop">
                                              <StopIcon
                                                  className="stop-icon"
                                                  style={
                                                      elem.status === DEVICE_STATUS_CODES.AVAILABLE ||
                                                      elem.status === DEVICE_STATUS_CODES.DISCONNECTED
                                                          ? { opacity: '0.5', cursor: 'default' }
                                                          : { opacity: '1', cursor: 'pointer' }
                                                  }
                                                  onClick={() =>
                                                      elem.status !== DEVICE_STATUS_CODES.AVAILABLE
                                                          ? handleClick(elem.model, isAndroid, 'stop')
                                                          : null
                                                  }
                                              />
                                          </Tooltip>
                                      </TableCell>

                                      <TableCell width="12%" className={classes.tableCell}>
                                          <Tooltip title="Start">
                                              <PlayCircleFilledWhiteOutlinedIcon
                                                  className="start-icon"
                                                  style={
                                                      elem.status === DEVICE_STATUS_CODES.BUSY
                                                          ? { opacity: '0.5', cursor: 'default' }
                                                          : { opacity: '1', cursor: 'default' }
                                                  }
                                                  onClick={() =>
                                                      elem.status !== DEVICE_STATUS_CODES.BUSY
                                                          ? handleClick(elem.model, isAndroid, 'start')
                                                          : null
                                                  }
                                              />
                                          </Tooltip>
                                      </TableCell>
                                  </TableRow>
                              ))
                            : virtualDevices.map((elem, index) => (
                                  <TableRow key={`${elem}${index}`}>
                                      {['model', 'manufacturer', 'os', 'osVersion', 'status', 'id', 'firmware'].map(
                                          (item, idx) => (
                                              <TableCell className={classes.tableCell} key={`${item}${idx}`}>
                                                  {elem[item]}
                                              </TableCell>
                                          )
                                      )}

                                      <TableCell className={classes.tableCell}>
                                          <Tooltip title="Stop">
                                              <StopIcon
                                                  className="stop-icon"
                                                  style={
                                                      elem.status === DEVICE_STATUS_CODES.AVAILABLE ||
                                                      elem.status === DEVICE_STATUS_CODES.DISCONNECTED
                                                          ? { opacity: '0.5', cursor: 'default' }
                                                          : { opacity: '1', cursor: 'pointer' }
                                                  }
                                                  onClick={() =>
                                                      elem.status !== DEVICE_STATUS_CODES.AVAILABLE
                                                          ? handleClick(elem, isAndroid, 'stop')
                                                          : null
                                                  }
                                              />
                                          </Tooltip>
                                      </TableCell>

                                      <TableCell className={classes.tableCell}>
                                          <Tooltip title="Start">
                                              <PlayCircleFilledWhiteOutlinedIcon
                                                  className="start-icon"
                                                  style={
                                                      elem.status === DEVICE_STATUS_CODES.BUSY
                                                          ? { opacity: '0.5', cursor: 'default' }
                                                          : { opacity: '1', cursor: 'pointer' }
                                                  }
                                                  onClick={() =>
                                                      elem.status !== DEVICE_STATUS_CODES.BUSY
                                                          ? handleClick(elem, isAndroid, 'start')
                                                          : null
                                                  }
                                              />
                                          </Tooltip>
                                      </TableCell>
                                  </TableRow>
                              ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default VirtualDevices;
