import axios from 'axios';
import fileDownload from 'js-file-download';
import { BASE_URL } from '../../variables/common';
import errorHandler from '../../utils/errorHandler';
import { caseCloses, deduction } from '../../../static/notifications-sounds';
import { NotificationMessage, handlerNotification } from '../notification/notification.actions';
import {
    CREAT_DIR_ELEM,
    DIRECTORY_LIST,
    IS_RENAME,
    SELECT_CONTEXT,
    SET_SELECTED_REPO,
    TEXT_FILE_DATA,
    TOGGLE_FILE_FOLDER,
    VIDEO_PLAYER_STREAM,
    SET_LOADING_PERCENT,
} from '../storeActionTypes';

export const cretionDirElem = (data) => ({
    type: CREAT_DIR_ELEM,
    payload: data,
});

export const directoryList = (data) => ({
    type: DIRECTORY_LIST,
    payload: data,
});

export const selectContext = (data) => ({
    type: SELECT_CONTEXT,
    payload: data,
});

export const setIsRename = (data) => ({
    type: IS_RENAME,
    payload: data,
});

export const setSelectedRepositorium = (data) => ({
    type: SET_SELECTED_REPO,
    payload: data,
});

export const textFileData = (data) => ({
    type: TEXT_FILE_DATA,
    payload: data,
});

export const toggleFileForlder = (data) => ({
    type: TOGGLE_FILE_FOLDER,
    payload: data,
});

export const videoPlayerStream = (data) => ({
    type: VIDEO_PLAYER_STREAM,
    payload: data,
});

export const setLoadingPercent = (data) => ({
    type: SET_LOADING_PERCENT,
    payload: data,
});

export const createFolder = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/repositorium/new-folder',
        data: data,
    })
        .then((response) => {
            dispatch(getDirectoryList());
            dispatch(cretionDirElem(false));
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const deleteFolder = (data) => (dispatch) => {
    const { path } = data;

    axios({
        method: 'POST',
        url: BASE_URL + '/repositorium/delete',
        data: { path },
    })
        .then((response) => {
            dispatch(getDirectoryList());
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

const getLoadingPercent = (event) => {
    const { loaded, total } = event;
    const percent = Math.floor((loaded * 100) / total);
    return percent;
};

export const downloadFile = (data) => (dispatch) => {
    const { path } = data;
    axios({
        method: 'GET',
        url: `${BASE_URL}/repositorium/getItemURL/${Buffer.from(`${path}`).toString('base64')}`,
    })
    .then((res) => {
        let axiosConfig = {
            method: 'GET',
            url: res.data.url,
            onDownloadProgress: (event) => dispatch(setLoadingPercent(getLoadingPercent(event))),
            responseType: 'blob',
        };

        if (res.data.noAdditionalAuth) {
            axiosConfig.transformRequest = [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data
                }]
        }
        return axios(axiosConfig)
    })
    .then((response) => {
        dispatch(setLoadingPercent(0));
        fileDownload(response.data, data.file);
    })
    .catch((error) => {
        errorHandler(error);
        deduction.play();
        dispatch(setLoadingPercent(0));
        dispatch(NotificationMessage(error.response.data));
        dispatch(handlerNotification());
    });
};

export const uploadFile = (file, path, name) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/repositorium/getUploadURL/${Buffer.from(`${path}/${name}`).toString('base64')}`,
    })
    .then((res) => {
        let axiosConfig = {
            method: 'PUT',
            url: res.data.url,
            data: file,
            // onUploadProgress: (event) => dispatch(setLoadingPercent(getLoadingPercent(event))),
        };

        if (res.data.noAdditionalAuth) {
            axiosConfig.transformRequest = [
                (data, headers) => {
                    delete headers.common.Authorization
                    return data
                }]
        }
        dispatch(NotificationMessage({ msg: "File uploading, please wait", type: 'success' } ));
        dispatch(handlerNotification(1000*60*30)); // TODO: workaround, onUploadProgress doesn`t work with preflight requests (with AWS s3)
        return axios(axiosConfig)
    })
    .then((res) => {
        return axios({
            method: "POST",
            url: `${BASE_URL}/repositorium/upload/finished`,
            data: {
                path: path,
                file: name,
                size: file.size
            }
        })
    })
    .then((response) => {
        dispatch(getDirectoryList());
        dispatch(cretionDirElem(false));
        caseCloses.play();
        dispatch(setLoadingPercent(0));
        dispatch(NotificationMessage(response.data));
        dispatch(handlerNotification());
    })
    .catch((error) => {
        errorHandler(error);
        deduction.play();
        dispatch(setLoadingPercent(0));
        dispatch(NotificationMessage(error.response.data));
        dispatch(handlerNotification());
    });
};

export const renameFileorFolder = (data) => (dispatch) => {
    const { pathForRename, rename } = data;

    axios({
        method: 'POST',
        url: BASE_URL + '/repositorium/rename',
        data: { path: pathForRename, rename },
    })
        .then((response) => {
            dispatch(cretionDirElem(false));
            dispatch(getDirectoryList());
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const getDirectoryList = () => (dispatch) => {
    axios({
        method: 'GET',
        url: BASE_URL + '/repositorium/ls',
    })
        .then((response) => {
            dispatch(directoryList([response.data.tree]));
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const SubElements = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/repositorium/sub-element',
        data: {
            data,
        },
    })
        .then((response) => {
            dispatch(directoryList([response.data.tree]));
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const videoPlayer = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/repositorium/preparing_viewer`,
        data: data,
    })
        .then((response) => {
            dispatch(videoPlayerStream(response.data.url));
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const textFileDisplayer = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/repositorium/text_file_viewer`,
        data: data,
    })
        .then((response) => {
            dispatch(textFileData(response.data));
        })
        .catch((error) => {
            errorHandler(error);
        });
};
