import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import PreviewTextFile from './PreviewTextFile';
import PreviewVideoFile from './PreviewVideoFile';

import { MAIN_PAGE } from '../../variables/common';

const Preview = () => {
    const history = useHistory();
    const location = useLocation();

    const setPreview = () => {
        switch (location?.state?.type) {
            case 'text':
                return <PreviewTextFile />;
            case 'video':
                return <PreviewVideoFile />;
            default:
                history.push(MAIN_PAGE);
        }
    };

    return <div>{setPreview()}</div>;
};

export default Preview;
