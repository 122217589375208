import axios from 'axios';
import errorHandler from '../../utils/errorHandler';
import { _isExpired } from '../auxiliary/auxiliary.actions';
import { NotificationMessage, handlerNotification } from '../notification/notification.actions';
import { BASE_URL, DEVICE_STATUS_CODES } from '../../variables/common';
import { caseCloses } from '../../../static/notifications-sounds';
import { ALL_AGENTS, VIRTUAL_DEVICES_AGENT, USED_VIRTUAL_DEVICES, SET_DEVICES_TIMELINE, AGENT_LOGS } from '../storeActionTypes';

export const allAgents = (data) => ({
    type: ALL_AGENTS,
    payload: data,
});

export const virtualDevicesAgent = (data) => ({
    type: VIRTUAL_DEVICES_AGENT,
    payload: data,
});

export const agentLogs = (data) => ({
    type: AGENT_LOGS,
    payload: data,
});

export const setUsedVirtualDevices = (data) => ({
    type: USED_VIRTUAL_DEVICES,
    payload: data,
});

export const setDevicesTimeline = (data) => ({
    type: SET_DEVICES_TIMELINE,
    payload: data,
});

export const getAllAgents = () => (dispatch) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/admin/control/list`,
    })
        .then((response) => {
            dispatch(allAgents(response.data));
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const agentControl = (id, status) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/agent/status`,
        data: { id, status },
    })
        .then(() => {
            dispatch(getAllAgents());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const agentControlNEO = (id, statusNEO) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/agent/neo-status`,
        data: { id, statusNEO },
    })
        .then(() => {
            dispatch(getAllAgents());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const agentActions = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/agent/action`,
        data: data,
    })
        .then((response) => {
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};
export const deleteAgentActions = (data) => (dispatch) => {
    axios({
        method: 'DELETE',
        url: `${BASE_URL}/admin/control/agent/action`,
        data: data,
    })
        .then((response) => {
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};
export const getListVirtualDevices = (agentUrl) => (dispatch, getState) => {
    const { allAgents, usedVirtualDevices } = getState().admin;

    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/agent/vd/list`,
        data: { agentUrl },
    })
        .then((response) => {
            const virtualDevices = response.data;
            const { devices } = allAgents[agentUrl];
            const isAndroid = virtualDevices.some((item) => item.os !== 'iOS');

            const usedVirtualDevicesList = [
                ...new Set([...usedVirtualDevices, ..._getUsedVirtualDevices({ devices, virtualDevices, isAndroid })]),
            ];

            dispatch(setUsedVirtualDevices(usedVirtualDevicesList));
            dispatch(
                virtualDevicesAgent(_updateVirtualDevicesAgent({ usedVirtualDevicesList, virtualDevices, isAndroid }))
            );
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const updateListVirtualDevices = (data) => (dispatch, getState) => {
    const usedVirtualDevices = getState().admin.usedVirtualDevices;
    const virtualDevicesList = getState().admin.virtualDevicesAgent;

    let listUsedVirtualDevices = [];
    const { device, command, isAndroid } = data;
    const deviceModel = isAndroid ? device : device.model;

    const dataAndroid = data;
    const dataIOS = command === 'start' ? data : { ...data, device: device.id };

    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/agent/vd/action`,
        data: isAndroid ? dataAndroid : dataIOS,
    })
        .then((response) => {
            listUsedVirtualDevices =
                command === 'start'
                    ? [...usedVirtualDevices, deviceModel]
                    : usedVirtualDevices.filter((model) => model !== deviceModel);

            const updateListVirtualDevices = JSON.parse(JSON.stringify(virtualDevicesList)).map((item) => {
                if (command === 'stop' && item.model === deviceModel) {
                    return { ...item, status: DEVICE_STATUS_CODES.DISCONNECTED };
                }

                return item.model === deviceModel
                    ? item.status === DEVICE_STATUS_CODES.AVAILABLE
                        ? { ...item, status: DEVICE_STATUS_CODES.BUSY }
                        : { ...item, status: DEVICE_STATUS_CODES.AVAILABLE }
                    : item;
            });

            caseCloses.play();
            dispatch(setUsedVirtualDevices(listUsedVirtualDevices));
            dispatch(virtualDevicesAgent(updateListVirtualDevices));
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const getAgentLogsList = (agentUrl) => (dispatch, getState) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/admin/control/agent/logs/list`,
        params: {agent: agentUrl},
    })
        .then((response) => {
            const logsList = response.data;
 
            dispatch(agentLogs(logsList));
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const requestToUploadLogFile = ({agent, file}) => (dispatch, getState) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/admin/control/agent/logs/upload`,
        params: {
            agent: agent,
            file: file
        },
    })
        .then((response) => {
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const deviceControl = (id, status) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/device/${id}/status`,
        data: { status },
    })
        .then(() => {
            dispatch(getAllAgents());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const deviceControlNEO = (id, statusNEO) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/device/${id}/neo-status`,
        data: { statusNEO },
    })
        .then(() => {
            dispatch(getAllAgents());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const getExecutionTimelines =
    ({ devices, startTime, endTime }) =>
    (dispatch) => {
        axios({
            method: 'POST',
            url: `${BASE_URL}/admin/timeline/list`,
            data: { devices, startTime, endTime },
        })
            .then((response) => {
                dispatch(setDevicesTimeline([...response.data]));
            })
            .catch((error) => {
                _isExpired(error);
                errorHandler(error);
            });
    };

export const updateDeviceMetadata = (id, data) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/device/${id}/metadata/update`,
        data: { data },
    })
        .then(() => {
            dispatch(getAllAgents());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const deleteDeviceById = (id) => (dispatch) => {
    axios({
        method: 'DELETE',
        url: `${BASE_URL}/admin/control/device`,
        params: {
            udid: id
        }
    })
        .then((response) => {
            dispatch(getAllAgents());

            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const refreshDeviceById = (id) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/control/device/${id}/refresh`,
    })
        .then((response) => {
            dispatch(getAllAgents());

            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

// used only for getListVirtualDevices action
const _getUsedVirtualDevices = (virtualDeviceData) => {
    const { devices, virtualDevices, isAndroid } = virtualDeviceData;

    return isAndroid
        ? virtualDevices.filter((model) => {
              return devices.filter(
                  (device) => device.model === model && device.status !== DEVICE_STATUS_CODES.DISCONNECTED
              ).length;
          })
        : virtualDevices
              .filter(
                  (item) =>
                      devices.filter(
                          (elem) => elem.model === item.model && elem.status !== DEVICE_STATUS_CODES.DISCONNECTED
                      ).length
              )
              .map((item) => item.model);
};

// used only for getListVirtualDevices action
const _updateVirtualDevicesAgent = (virtualDeviceData) => {
    const { usedVirtualDevicesList, virtualDevices, isAndroid } = virtualDeviceData;

    const checkVirtualDeviceStatus = (device) => {
        return isAndroid
            ? usedVirtualDevicesList.some((model) => model === device)
                ? { model: device, status: DEVICE_STATUS_CODES.BUSY }
                : { model: device, status: DEVICE_STATUS_CODES.AVAILABLE }
            : usedVirtualDevicesList.some((model) => model === device.model)
            ? { ...device, status: DEVICE_STATUS_CODES.BUSY }
            : { ...device, status: DEVICE_STATUS_CODES.AVAILABLE };
    };

    return virtualDevices.map((device) => checkVirtualDeviceStatus(device));
};
