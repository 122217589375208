import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { routes } from './routes/mainRoutes';
import { Header } from './components/Common/Header';
import { Notification } from './components/Notifications/index.js';
import MaintenanceNotification from './components/MaintenanceNotification/MaintenanceNotification';

import { CreateConnection } from './store/socket/socketActions';
import { setUserRole, setUserGroup, setUserEmail } from './store/user/user.actions';

import auth from './utils/auth';
import Cookies from 'universal-cookie';
import './style/App.scss';

const App = () => {
    const dispatch = useDispatch();

    const message = useSelector((state) => state.notification.message, shallowEqual);
    const maintenanceMessage = useSelector((state) => state.maintenanceMessage.message, shallowEqual);

    const renderRoutes = useMemo(() => routes(), []);

    const maintenanceMessageCheck = () => {
        const cookies = new Cookies();
        const cookieHash = cookies.get('maintenanceMessage');
        return true ? message?.hash !== cookieHash || !cookieHash : false;
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(setUserRole(localStorage.getItem('role')));
            dispatch(setUserGroup(localStorage.getItem('group')));
            dispatch(setUserEmail(localStorage.getItem('email')));
            dispatch(CreateConnection());
        }
    }, []);

    return (
        <>
            <Header />
            {renderRoutes} {message && Notification(message)}
            {maintenanceMessageCheck() && maintenanceMessage && (
                <MaintenanceNotification message={maintenanceMessage} />
            )}
        </>
    );
};

export default App;
