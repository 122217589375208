import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import CopyText from 'react-copy-text';
import {
    Button,
    Drawer,
    Tooltip,
    TextField,
    Container,
    Accordion,
    IconButton,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { setCreationMode } from '../../../store/auxiliary/auxiliary.actions';
import { executeCommand, saveToFileCommand } from '../../../store/interactive/interactive.actions';

import { TITLE } from '../../../variables/common';

let id = 0;
const CreationMode = () => {
    const dispatch = useDispatch();

    const SID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);
    const isModeOpen = useSelector((state) => state.auxiliary.isCreationModeOpen, shallowEqual);
    const commandsData = useSelector((state) => state.interactive.commandsData, shallowEqual);

    const [command, setCommand] = useState('');
    const [commandsToCopy, setCommandsToCopy] = useState('');

    function handleCloseDrawer() {
        dispatch(setCreationMode(!isModeOpen));
    }

    function onCopied() {
        return setCommandsToCopy('');
    }

    function handleEnter(event) {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (command !== '') {
            dispatch(
                executeCommand({
                    SID,
                    id,
                    command,
                    list: [...commandsData, { id, command, response: null, status: null }],
                })
            );
            id++;
        }
        setCommand('');
    }

    function copyCommandToClipboard(command, params) {
        let element;
        if (!params) {
            if (command && params === undefined) {
                setCommandsToCopy(`${command}`);
            }
            if (typeof params === 'string') {
                setCommandsToCopy(`${command}()`);
            }
        } else {
            if (Array.isArray(params)) {
                if (params.length === 2) {
                    element = `${command}(${params[0]},${params[1]})`;
                } else {
                    element = `${command}(${params[0]},${params[1]},${params[2]},${params[3]})`;
                }
                setCommandsToCopy(element);
            }
            if (params['packageName'] || params['bundleId'] !== undefined) {
                element = `${command}(${JSON.stringify(params)})`;
                setCommandsToCopy(element);
            }
            if (typeof params === 'string') {
                element = `${command}(${params})`;
                setCommandsToCopy(element);
            }
        }
    }

    function copyAllCommandToClipboard(commandsData) {
        let commands = [];
        commandsData.forEach((el) => {
            if (!el.params) {
                if (el.params === undefined) {
                    commands.push(`${el.command}`);
                }
                if (typeof el.params === 'string') {
                    commands.push(`${el.command}()`);
                }
            } else {
                if (el.params['packageName'] || el.params['bundleId'] !== undefined) {
                    commands.push(`${el.command}(${JSON.stringify(el.params)})`);
                } else {
                    commands.push(`${el.command}(${el.params})`);
                }
            }
        });
        setCommandsToCopy(commands);
    }

    const displayCommand = (command, params) => {
        if (params === undefined && command) {
            return;
        }
        if (Array.isArray(params)) {
            if (params.length === 2) {
                return (
                    <p>
                        ({params[0]},{params[1]})
                    </p>
                );
            } else {
                return (
                    <p className="params-wrap">
                        ({params[0]},{params[1]},{params[2]},{params[3]})
                    </p>
                );
            }
        }
        if (params['packageName'] && params['packageName'] !== undefined) {
            return (
                <p>
                    (appPackage:{params.packageName},appActivity:{params.appActivity})
                </p>
            );
        }
        //iOS
        if (params['bundleId'] && params['bundleId'] !== undefined) {
            return (
                <p>
                    {'('}
                    {params['bundleId']}
                    {')'}
                </p>
            );
        }
        if (command === 'takeScreenshot' || command === 'source') {
            return <p>{'()'}</p>;
        }
        if (command === 'pressKeyCode') {
            return (
                <p>
                    {'('}
                    {params}
                    {')'}
                </p>
            );
        }
    };

    return (
        <Container className="creation-mode" maxWidth={false} data-testid="creation-test">
            <Drawer
                open={isModeOpen}
                variant="persistent"
                anchor="left"
                className="drawer"
                classes={{
                    paper: 'crm-wrap',
                }}>
                <div className="wrapper">
                    <div className="wrapper-header">
                        <p className="title">{TITLE.INTERACTIVE_MODE}</p>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close drawer"
                            onClick={handleCloseDrawer}
                            className="drawer-close-btn">
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className="output-text-field">
                        {/* for unit-test format */}
                        {Array.isArray(commandsData) &&
                            commandsData.map((element, index) => {
                                return (
                                    <div className="command" key={element.command + index}>
                                        <Accordion className="expand-panel">
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                className="summary"
                                                classes={{
                                                    content: 'summary-content',
                                                }}>
                                                <div
                                                    className={element.status === 'true' ? 'dot success' : 'dot error'}
                                                />
                                                <div className="display-command">
                                                    {element.command} {displayCommand(element.command, element.params)}
                                                </div>
                                            </AccordionSummary>

                                            <AccordionDetails className={'expand-content'}>
                                                <p className="expand-content-info">
                                                    {typeof element.response === 'object'
                                                        ? [...Object.entries({ height: 1977, width: 1080 })].map(
                                                              ([key, value]) => `${key}: ${value}`
                                                          )
                                                        : element.response}
                                                </p>
                                            </AccordionDetails>
                                        </Accordion>

                                        <div className="resource-controls">
                                            <Tooltip title="Copy Command">
                                                <FileCopyIcon
                                                    className="copy-icon"
                                                    onClick={() =>
                                                        copyCommandToClipboard(element.command, element.params)
                                                    }
                                                />
                                            </Tooltip>
                                            <CopyText text={commandsToCopy} onCopied={() => onCopied()} />
                                            <Tooltip title="Save To File">
                                                <InsertDriveFileIcon
                                                    className="copy-icon"
                                                    onClick={() =>
                                                        dispatch(
                                                            saveToFileCommand({
                                                                SID,
                                                                source: element.response,
                                                            })
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className="form">
                        <div className="form-container">
                            <TextField
                                multiline
                                maxRows="4"
                                value={command}
                                variant="filled"
                                onKeyPress={handleEnter}
                                className="text-field-multiline"
                                data-testid="creation-input-test"
                                placeholder="enter command to execute..."
                                onChange={(e) => setCommand(e.target.value)}
                            />

                            <div className={'copyBox'} onClick={() => copyAllCommandToClipboard(commandsData)}>
                                <div className="copy-wrap">
                                    <FileCopyOutlinedIcon fontSize="small" className={'copyIcon'} />
                                    <p className={'copyIcon'}>Copy all commands</p>
                                </div>

                                <Button
                                    className="execute-btn"
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}>
                                    Execute command
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </Container>
    );
};

export default CreationMode;
