import React, { useEffect, useState, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DraggableCore from 'react-draggable';
import { JSONTree } from 'react-json-tree';
import { userRole } from '../../../variables/common';
import { createDeviceSession, sessionExpired, sessionStoped } from '../../../store/interactive/interactive.actions';
import { isBrowserSession } from '../../../store/auxiliary/auxiliary.actions';
import {
    selectDevice,
    deviceCapabilities,
    setDeviceCapabilities,
    setFilteredData,
} from '../../../store/device/device.actions';

import {
    Paper,
    Table,
    Grow,
    Modal,
    Button,
    Select,
    Tooltip,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TextField,
    IconButton,
    InputLabel,
    FormControl,
    TableSortLabel,
    TableContainer,
    TablePagination,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import { ReactComponent as FlutterIcon } from '../../../../static/images/flutter.svg';

import { DEVICE, DEVICE_STATUS_CODES, DEVICETYPE } from '../../../variables/common';
import { copyText } from '../../../../helpers/copyText';
import {
    getSearch,
    getFilteredRealDevices,
    getFilteredVirtualDevices,
    getFilteredData,
} from '../../../store/device/device.selectors';

export const useStyles = makeStyles({
    scrollHoriz: {
        overflowX: 'inherit',
    },
    modal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderRadius: '10px',
        textAlign: 'center',
        borderColor: 'transparent',
    },
    paper: {
        borderColor: 'transparent',
        backgroundColor: '#FFFF',
        borderRadius: '10px',
    },
    container: {
        width: '250px',
        padding: '15px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        background: ' #fff',
        boxShadow: '4px 4px 16px -8px rgba(0, 0, 0, 0.68)',
        border: '1px solid silver',
    },
    closeIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: '#4d9bd5',
        },
    },
    executeBtn: {
        width: '100%',
        height: '37px',
        backgroundColor: '$secondary-color',
        borderRadius: '4px',
        textAlign: 'center',
        color: '$text-color-primary',
        marginTop: '25px',
        fontSize: '14px',
    },
    form: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
    popover: {
        width: '450px',
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 15,
    },
    popoverContent: {
        borderRadius: 5,
        padding: 15,
    },
    titleWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    copyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: '#4d9bd5',
        },
    },
    box: {
        inset: '0px',
        overflow: 'auto',
        height: 'fit-content',
        backgroundColor: '#cad8e673',
        lineHeight: '1.5',
        padding: '14px',
        borderRadius: '5px',
        margin: '10px 0',
    },
    title: {
        fontSize: '16px',
        textTransform: 'uppercase',
        color: '#4d9bd5',
        fontWeight: 600,
    },
    copyIcon: {
        color: '#4d9bd5',
        marginRight: 5,
    },
    paragraph: {
        paddingLeft: '25px',
        margin: '20px 0',
        fontWeight: 500,
    },
});

const headCells = [
    { id: 'model', label: 'Model', width: '10%', hideSortIcon: false },
    { id: 'manufacturer', label: 'Manufacturer', width: '10%', hideSortIcon: false },
    { id: 'os', label: 'OS', width: '6%', hideSortIcon: false },
    { id: 'osVersion', label: 'OS Version', width: '5%', hideSortIcon: false },
    { id: 'status', label: 'Status', width: '8%', hideSortIcon: false },
    { id: 'flutterIcon', width: '3%', hideSortIcon: true },
    { id: 'user', label: 'User', width: '9%', hideSortIcon: false },
    { id: 'id', label: 'Device ID', width: '12%', hideSortIcon: false },
    { id: 'firmware', label: 'Firmware', width: '18%', hideSortIcon: false },
    { id: 'resolution', label: 'Resolution', width: '9%', hideSortIcon: false },
    { id: 'group', width: '3%', hideSortIcon: true },
    { id: 'copy', label: 'Phone Info', width: '3%', hideSortIcon: true },
    { id: 'start', label: 'Remote GUI', width: '1%', hideSortIcon: true },
];

function compareValues(key, order) {
    let comparison = 0;
    let varA;
    let varB;
    let locale;

    return function innerSort(a, b) {
        if (key === 'user') {
            return order === 'asc' ? a?.owner?.localeCompare(b?.owner) : b?.owner?.localeCompare(a?.owner);
        } else if (key === 'resolution') {
            let arr = [a, b].map((item) =>
                !Number(item.resolution.width) || !Number(item.resolution.height) ? { ...item, resolution: {} } : item
            );

            return order === 'asc'
                ? `${arr[0]?.resolution?.width}`?.localeCompare(`${arr[1]?.resolution?.width}`)
                : `${arr[1]?.resolution?.width}`?.localeCompare(`${arr[0]?.resolution?.width}`);
        } else if (key === 'osVersion' || key === 'firmware') {
            return order === 'asc'
                ? a[key]?.localeCompare(b[key], locale, { numeric: true })
                : b[key]?.localeCompare(a[key], locale, { numeric: true });
        } else {
            varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
        }

        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }

        return order === 'desc' ? comparison * -1 : comparison;
    };
}

const EnhancedTableHead = ({ devicesData, filteredData, role }) => {
    const [sortConfig, setSortConfig] = useState('asc');
    const dispatch = useDispatch();

    const dispatchListByType = (key) => {
        const tempData = filteredData || devicesData;
        const temp = tempData.sort(compareValues(key, sortConfig));

        dispatch(setFilteredData([...temp]));
    };
    const handleSort = (key) => {
        dispatchListByType(key);
        sortConfig === 'asc' ? setSortConfig('desc') : setSortConfig('asc');
    };

    const headCellsByRole = () => {
        return role !== 'superuser' ? headCells.filter((item) => item.id !== 'group') : headCells;
    };

    return (
        <TableHead>
            <TableRow className="table-header">
                {headCellsByRole().map((headCell) => (
                    <TableCell align="left" key={headCell.id} width={headCell.width}>
                        <TableSortLabel
                            hideSortIcon={headCell.hideSortIcon}
                            direction={sortConfig}
                            onClick={() => handleSort(headCell.id)}>
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = ({ setIsFilterApplied, isFilterApplied, setFilter, setOpenFilter, filteredData }) => {
    const dispatch = useDispatch();
    return (
        <div className="filter-tooltip-wrapper">
            {filteredData && isFilterApplied && (
                <Tooltip title="clear filters">
                    <IconButton
                        aria-label="clear list"
                        onClick={() => {
                            setIsFilterApplied(false);
                            dispatch(setFilteredData(null));
                            setFilter({});
                        }}>
                        <ClearAllIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="filter">
                <IconButton
                    data-testid="filter-icon"
                    aria-label="filter list"
                    onClick={() => setOpenFilter((prev) => !prev)}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

const DeviceInfoPopover = ({ classes, capabilities, setOpenPopover, setDeviceCapabilities }) => {
    const dispatch = useDispatch();

    function copyCapabilities() {
        copyText(JSON.stringify(capabilities.startSessionCapabilities ? capabilities.startSessionCapabilities: capabilities));
        setOpenPopover(false);
        dispatch(setDeviceCapabilities(null));
    }

    function handleClose() {
        setOpenPopover(false);
        dispatch(setDeviceCapabilities(null));
    }

    return (
        <Paper elevation={4} className={classes.popoverContent}>
            <div className={classes.content}>
                <div className={classes.titleWrap}>
                    <p className={classes.title}>Phone Info</p>
                    <CloseIcon fontSize="small" className={classes.closeIcon} onClick={handleClose} />
                </div>

                <div className={classes.box}>
                    {capabilities
                        ? [...Object.entries(capabilities)].map(([key]) => (
                              <div key={key}>
                                  {key}: <b>{<JSONTree data={capabilities[key]}/>}</b>
                              </div>
                          ))
                        : null}
                </div>

                <div className={classes.copyBox} onClick={copyCapabilities}>
                    <FileCopyOutlinedIcon fontSize="small" className={classes.copyIcon} />
                    <p className={classes.copyIcon}>Copy to the clipboard</p>
                </div>
            </div>
        </Paper>
    );
};

export const DeviceList = () => {
    const dispatch = useDispatch();

    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({});
    const [listType, setListType] = useState(undefined);
    const [openFilter, setOpenFilter] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [openPopover, setOpenPopover] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [deviceInfoOptions, setDeviceInfoOptions] = useState({});

    const role = useSelector((state) => state.user.role, shallowEqual);
    const group = useSelector((state) => state.user.group, shallowEqual);
    const devicePlatform = useSelector((state) => state.device.devicePlatform, shallowEqual);
    const serverVersion = useSelector((state) => state.auxiliary.serverVersion, shallowEqual);
    const validateStatus = useSelector((state) => state.auxiliary.validateStatus, shallowEqual);
    const capabilities = useSelector((state) => state.device.deviceCapabilities, shallowEqual);
    const virtualDevicesSwitch = useSelector((state) => state.device.virtualDevicesSwitch, shallowEqual);
    const virtualDevicesData = useSelector(getFilteredVirtualDevices);
    const realDevicesData = useSelector(getFilteredRealDevices);
    const search = useSelector(getSearch);
    const filteredDevicesData = useSelector(getFilteredData);

    const virtualAvailableDevicesData = useMemo(() => {
        return virtualDevicesData.filter(
            (device) => device.status !== DEVICE_STATUS_CODES.DISCONNECTED && device.type === DEVICETYPE.EMULATOR
        );
    }, [virtualDevicesData]);

    const devicesData = listType ? virtualAvailableDevicesData : realDevicesData;
    const filteredData = search ? devicesData : filteredDevicesData;

    function createDeviceInfoOptionsForDropDown(rows) {
        let deviceInfoOptions = {};
        deviceInfoOptions.modelList = new Map();
        deviceInfoOptions.manufacturerList = new Map();
        deviceInfoOptions.osList = new Map();
        deviceInfoOptions.osVersionList = new Map();
        deviceInfoOptions.idList = new Map();
        deviceInfoOptions.statusList = new Map();

        // create JSX option lists
        for (const { model, manufacturer, os, osVersion, id, status } of rows) {
            if (!deviceInfoOptions['modelList'].has(model) && model) {
                deviceInfoOptions['modelList'].set(
                    model,
                    <option key={id + '-model'} value={model}>
                        {model}
                    </option>
                );
            }
            if (!deviceInfoOptions['manufacturerList'].has(manufacturer) && manufacturer) {
                deviceInfoOptions['manufacturerList'].set(
                    manufacturer,
                    <option key={id + '-manufacturer'} value={manufacturer}>
                        {manufacturer}
                    </option>
                );
            }
            if (!deviceInfoOptions['osList'].has(os) && os) {
                deviceInfoOptions['osList'].set(
                    os,
                    <option key={id + '-os'} value={os}>
                        {os}
                    </option>
                );
            }
            if (!deviceInfoOptions['osVersionList'].has(osVersion) && osVersion) {
                deviceInfoOptions['osVersionList'].set(
                    osVersion,
                    <option key={id + '-osVersion'} value={osVersion}>
                        {osVersion}
                    </option>
                );
            }
            if (!deviceInfoOptions['idList'].has(id) && id) {
                deviceInfoOptions['idList'].set(
                    id,
                    <option key={id} value={id}>
                        {id}
                    </option>
                );
            }
            if (!deviceInfoOptions['statusList'].has(status) && status) {
                deviceInfoOptions['statusList'].set(
                    status,
                    <option key={id + `${status}-status`} value={status}>
                        {status}
                    </option>
                );
            }
        }

        function innerSort(key) {
            return function compareValues(a, b) {
                let varA;
                let varB;
                if (key === 'osVersion') {
                    varA = parseInt(a.props.value);
                    varB = parseInt(b.props.value);
                } else {
                    varA = a.props.value.toUpperCase();
                    varB = b.props.value.toUpperCase();
                }
                if (varA > varB) {
                    return 1;
                } else if (varA < varB) {
                    return -1;
                }
                return 0;
            };
        }
        //sort the dropdown lists
        for (let deviceChar in deviceInfoOptions) {
            deviceInfoOptions[deviceChar] = [...deviceInfoOptions[deviceChar].values()];
            deviceInfoOptions[deviceChar] = [...deviceInfoOptions[deviceChar].values()];
            const regexProperty = /^(.+)(?=List)/g;
            const property = deviceChar.match(regexProperty)[0];
            deviceInfoOptions[deviceChar].sort(innerSort(property));

            // add the blank value for the select
            deviceInfoOptions[deviceChar].unshift(
                <option aria-label="None" key={deviceChar + '-emptyValue'} value="" />
            );
        }

        return deviceInfoOptions;
    }

    function setDeviceInformation(row) {
        dispatch(deviceCapabilities(row.id));
        setOpenPopover((prev) => !prev);
    }

    function handleFilterChange(e) {
        const { name, value } = e.target;
        setFilter((prev) => {
            if (value) {
                return { ...prev, [name]: value };
            } else {
                let temp = prev;
                delete temp[name];
                return { ...temp };
            }
        });
    }

    function filterDevices() {
        if (Object.keys(filter)?.length) {
            let temp = devicesData;
            for (let key in filter) {
                if (filter[key]) {
                    temp = temp.filter((device) => device[key]?.toUpperCase() === filter[key]?.toUpperCase());
                }
            }
            dispatch(setFilteredData([...temp]));
        }
        setOpenFilter(false);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleDevice(elem) {
        dispatch(sessionExpired(false));
        dispatch(sessionStoped(false));

        const parseResolution = elem.resolution.split('*');
        elem.resolution = {
            width: parseResolution[0],
            height: parseResolution[1],
        };
        dispatch(selectDevice(elem));
        dispatch(createDeviceSession(elem.id, elem));
        dispatch(isBrowserSession(true));
        history.push(DEVICE);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function capitalizeFirstLetter(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    function convertToString(value) {
        switch (typeof value) {
            case 'object':
                return value.join(', ');
            case 'string':
                // eslint-disable-next-line
                return value.replace(/[\[\]']+/g, '');
            default:
                return '';
        }
    }

    function createData(
        model,
        manufacturer,
        os,
        os_version,
        status,
        flutterIcon,
        owner,
        id,
        firmware,
        resolution,
        _group,
        type
    ) {
        const group = convertToString(_group);
        const product = capitalizeFirstLetter(manufacturer);
        const screen =
            Number(resolution.width) || Number(resolution.height) ? `${resolution.width}*${resolution.height}` : '';

        return {
            model,
            manufacturer: product,
            os,
            os_version,
            status,
            flutterIcon,
            owner,
            id,
            firmware,
            resolution: screen,
            group,
            type,
        };
    }

    function isDataExist() {
        if (filteredData?.length > 0) {
            return true;
        } else if (rows?.length) {
            return true;
        }
        return false;
    }

    function filterByPlatform(rows, platforms) {
        const tempRows = rows.filter((row) => {
            return platforms.find((platform) => {
                switch (row.type) {
                    case 'mobile':
                        return platform.toLowerCase() === row.os.toLowerCase() ? row : false;
                    case 'ble-serial-port':
                        return platform.toLowerCase() === row.type.toLowerCase() ? row : false;
                }
            });
        });

        // eslint-disable-next-line no-unused-vars
        setRows(tempRows.map(({ type, ...rest }) => rest));
    }

    function filterByUserGroup(devicesData, devices) {
        const showDevices = [];

        const hideDevices = devicesData.filter(({ metadata }) => {
            return (
                metadata.group &&
                typeof metadata.group === 'object' &&
                !metadata.group.some((item) => group.includes(item))
            );
        });

        const checkForGroup = (arr, id) => arr.find((item) => item.id === id);

        for (let device of devices) {
            if (!checkForGroup(hideDevices, device.id)) {
                showDevices.push(device);
            }
        }

        return showDevices;
    }

    useEffect(
        () => {
            virtualDevicesSwitch === true ? setListType(true) : setListType(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [virtualDevicesSwitch]
    );

    useEffect(() => {
        let tempRows;
        let temp = filteredData || devicesData;
        filteredData ? setIsFilterApplied(true) : setIsFilterApplied(false);
        if (temp) {
            tempRows = temp.map((elem) => {
                return createData(
                    elem.model ?? '',
                    elem.manufacturer ?? '',
                    elem.os ?? '',
                    elem.osVersion ?? '',
                    elem.status ?? '',
                    (elem.flutterIcon = elem.metadata?.enableFlutter ? true : false),
                    elem.owner ?? '',
                    elem.id ?? '',
                    elem.firmware ?? '',
                    elem.resolution ?? '',
                    elem.metadata?.group ?? '',
                    elem.class ?? ''
                );
            });
            if (userRole[2] !== role) {
                tempRows = filterByUserGroup(temp, tempRows);
            }
        }

        filterByPlatform(tempRows, devicePlatform);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devicesData, filteredData, devicePlatform]);

    useEffect(() => {
        if (openFilter) {
            if (Object.keys(filter).length !== 0) {
                let temp = devicesData;
                for (let key in filter) {
                    if (filter[key]) {
                        temp = temp.filter((device) => device[key]?.toUpperCase() === filter[key]?.toUpperCase());
                    }
                }
                if (temp) {
                    setDeviceInfoOptions(createDeviceInfoOptionsForDropDown(temp));
                }
            } else if (!isFilterApplied) {
                let rows = filteredData || devicesData;
                if (rows) {
                    setDeviceInfoOptions(createDeviceInfoOptionsForDropDown(rows));
                }
            }
        } else if (!openFilter && !isFilterApplied) {
            setDeviceInfoOptions({});
            setFilter(false);
        }
        // eslint-disable-next-line
    }, [openFilter, filter]);

    useEffect(() => {
        document.getElementById('table-execution').scrollTo(0, 0);
    }, [page]);

    return (
        <>
            <Paper elevation={0} data-testid="device-list">
                {isDataExist() && (
                    <>
                        <EnhancedTableToolbar
                            {...{ isFilterApplied, setIsFilterApplied, setFilter, setOpenFilter, filteredData }}
                        />
                        <TableContainer className={`table ${classes.scrollHoriz}`}>
                            <Table className="table">
                                <EnhancedTableHead devicesData={devicesData} filteredData={filteredData} role={role} />

                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row, index) => {
                                        const isBetaVersion =
                                            devicesData &&
                                            devicesData.find((item) => item.id === row.id)?.metadata?.betaVersion;
                                        return (
                                            <TableRow key={`${row.model} + ${index}`} hover className="table-row">
                                                {[...Object.entries(row)].map(([key, value], idx) => {
                                                    if (key === 'group') {
                                                        if (role === 'superuser') {
                                                            return (
                                                                <TableCell
                                                                    key={`${value} + ${idx}`}
                                                                    className="table-element"
                                                                    align="left">
                                                                    <div className="align-items table-content">
                                                                        {value}
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                        }

                                                        return null;
                                                    }
                                                    if (key === 'status') {
                                                        return (
                                                            <TableCell
                                                                key={`${value} + ${idx}`}
                                                                className="table-element"
                                                                align="left">
                                                                <div className="align-items table-content">
                                                                    <p
                                                                        className={`device-status ${
                                                                            value === DEVICE_STATUS_CODES.AVAILABLE
                                                                                ? 'active-device'
                                                                                : 'inactive-device'
                                                                        }`}
                                                                    />
                                                                    {value}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (key === 'os_version') {
                                                        return (
                                                            <TableCell
                                                                key={`${value} + ${idx}`}
                                                                className="table-element"
                                                                align="left">
                                                                <div className="table-content">
                                                                    <p
                                                                        className={
                                                                            isBetaVersion ? 'beta-version-text' : ''
                                                                        }>
                                                                        {value}
                                                                    </p>
                                                                    {isBetaVersion && (
                                                                        <div className="beta-version-icon">
                                                                            <p>beta</p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }
                                                    if (key === 'flutterIcon') {
                                                        return (
                                                            <TableCell
                                                                key={`${value} + ${idx}`}
                                                                className="table-element flutter-icon"
                                                                align="left">
                                                                <div className="table-content">
                                                                    {value ? (
                                                                        <FlutterIcon width="20px" height="20px" />
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={`${value} + ${idx}`}
                                                            className="table-element"
                                                            align="left">
                                                            <div className="table-content">{value}</div>
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell
                                                    className="table-element"
                                                    align="center"
                                                    onClick={() => setDeviceInformation(row)}>
                                                    <div className="table-content">
                                                        <InfoOutlinedIcon className="info-icon" />
                                                    </div>
                                                </TableCell>
                                                <TableCell className="table-element" align="center">
                                                    <div className="table-content">
                                                        {(row.status === DEVICE_STATUS_CODES.AVAILABLE ||
                                                         (row.status === DEVICE_STATUS_CODES.RESERVED && row.owner === localStorage.getItem('email'))
                                                        ) && 
                                                            row.model !== 'BLE-serial-port' && (
                                                                <PlayCircleFilledWhiteOutlinedIcon
                                                                    className="run-icon"
                                                                    onClick={() => handleDevice(row)}
                                                                />
                                                            )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                            {openPopover && (
                                <div className={classes.popover}>
                                    <Grow in={openPopover}>
                                        <DeviceInfoPopover
                                            {...{ classes, capabilities, setOpenPopover, setDeviceCapabilities }}
                                        />
                                    </Grow>
                                </div>
                            )}

                            <TablePagination
                                page={page}
                                component="div"
                                count={rows.length}
                                className="pagination"
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[15, 30, 50]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </>
                )}
                {!isDataExist() && !search && <h1 className="empty-table">No connected devices</h1>}
                {search && devicesData.length === 0 && <h1 className="empty-table">Not found</h1>}
            </Paper>
            <Modal
                data-testid="filter-modal"
                disablePortal
                open={openFilter}
                disableAutoFocus
                disableEnforceFocus
                className={classes.modal}
                onClose={() => {
                    setOpenFilter((prev) => !prev);
                }}>
                <DraggableCore>
                    <div className={classes.container}>
                        <CloseIcon
                            className={classes.closeIcon}
                            onClick={() => {
                                setOpenFilter((prev) => !prev);
                            }}
                        />

                        <FormControl className={classes.form}>
                            <InputLabel htmlFor="modelSelect">Model</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    name: 'model',
                                    id: 'modelSelect',
                                }}
                                onChange={handleFilterChange}
                                value={filter.model}
                                className={classes.input}>
                                {deviceInfoOptions.modelList}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.form}>
                            <InputLabel htmlFor="manufacturerSelect">Manufacturer</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    id: 'manufacturerSelect',
                                    name: 'manufacturer',
                                }}
                                className={classes.input}
                                onChange={handleFilterChange}
                                value={filter.manufacturer}>
                                {deviceInfoOptions.manufacturerList}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.form}>
                            <InputLabel htmlFor="osSelect">OS</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    id: 'osSelect',
                                    name: 'os',
                                }}
                                className={classes.input}
                                onChange={handleFilterChange}
                                value={filter.os}>
                                {deviceInfoOptions.osList}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.form}>
                            <InputLabel htmlFor="osVersionSelect">OS Version</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    id: 'osVersionSelect',
                                    name: 'osVersion',
                                }}
                                className={classes.input}
                                onChange={handleFilterChange}
                                value={filter.osVersion}>
                                {deviceInfoOptions.osVersionList}
                            </Select>
                        </FormControl>

                        {/* <FormControl className={classes.form}>
                            <InputLabel htmlFor="deviceIdSelect">Device ID</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    id: 'deviceIdSelect',
                                    name: 'id',
                                }}
                                className={classes.input}
                                onChange={handleFilterChange}
                                value={filter.id}>
                                {deviceInfoOptions.idList}
                            </Select>
                        </FormControl> */}

                        <FormControl className={classes.form}>
                            <InputLabel htmlFor="statusSelect">Status</InputLabel>
                            <Select
                                native
                                inputProps={{
                                    id: 'statusSelect',
                                    name: 'status',
                                }}
                                className={classes.input}
                                onChange={handleFilterChange}
                                value={filter.status}>
                                {deviceInfoOptions.statusList}
                            </Select>
                        </FormControl>

                        <TextField
                            name="id"
                            label="Device ID"
                            className={classes.input}
                            defaultValue={filter['id']}
                            onChange={handleFilterChange}
                            onDoubleClick={(event) => event.target.select()}
                        />

                        <Button
                            className={classes.executeBtn}
                            variant="contained"
                            type="submit"
                            onClick={() => {
                                filterDevices();
                                setIsFilterApplied(true);
                            }}>
                            Apply
                        </Button>
                    </div>
                </DraggableCore>
            </Modal>

            <div className="footer-info">
                <div className="footer-info-wrapper">
                    {serverVersion && <p className="footer-info-version">Server version: {serverVersion}</p>}
                    {validateStatus && (
                        <div className="footer-info-group">
                            <p>Validate</p>&nbsp;
                            <CheckCircleIcon className="footer-info-icon" />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DeviceList;
