import axios from 'axios';
import auth from '../../utils/auth';
import { BASE_URL, MAIN_PAGE } from '../../variables/common';
import errorHandler from '../../utils/errorHandler';
import { caseCloses, deduction } from '../../../static/notifications-sounds';
import { getAllAgents } from '../admin/admin.actions';
import { mySessions } from '../socket/socketActions';
import { sessionsPopup, _isExpired, setTabNumber, setCreationMode } from '../auxiliary/auxiliary.actions';
import { NotificationMessage, handlerNotification } from '../notification/notification.actions';
import {
    IS_SESSION_STOPED,
    IS_SESSION_EXPIRED,
    APPIUM_SESSION_ID,
    ACTIVE_SESSIONS,
    STREAM_URL,
    COMMAND_LIST,
    SET_SCREEN,
    DEVICE_ACTIVE_FOLDER,
    INSTALLED_APP_LIST,
    XML_SOURCE,
    SET_SESSION_TAB,
    SET_INSPECTOR_MODE,
    SET_INSPECTOR_DETAILS,
    SET_INSPECTOR_ELEMENT,
    SET_INSPECTOR_ELEMENT_NODES_ID,
    PREVENT_MULTIPLE_CLICKS,
    RECORDING,
    DEVICE_HAS_PIN_CODE,
    IS_LOADING,
} from '../storeActionTypes';

export const sessionExpired = (data) => ({
    type: IS_SESSION_EXPIRED,
    payload: data,
});

export const preventMultipleClicks = (data) => ({
    type: PREVENT_MULTIPLE_CLICKS,
    payload: data,
});

export const sessionStoped = (data) => ({
    type: IS_SESSION_STOPED,
    payload: data,
});

export const AppiumSessionID = (data) => ({
    type: APPIUM_SESSION_ID,
    payload: data,
});

export const ActiveSessions = (data) => ({
    type: ACTIVE_SESSIONS,
    payload: data,
});

export const StreamURLDevice = (data) => ({
    type: STREAM_URL,
    payload: data,
});

export const Recording = (data) => ({
    type: RECORDING,
    payload: data,
});

export const commandList = (data) => ({
    type: COMMAND_LIST,
    payload: data,
});

export const setScreen = (data) => ({
    type: SET_SCREEN,
    payload: data,
});

export const DeviceActiveFolder = (data) => ({
    type: DEVICE_ACTIVE_FOLDER,
    payload: data,
});

export const installedAppList = (data) => ({
    type: INSTALLED_APP_LIST,
    payload: data,
});

export const XMLTSource = (data) => ({
    type: XML_SOURCE,
    payload: data,
});

export const setSessionTab = (data) => ({
    type: SET_SESSION_TAB,
    payload: data,
});

export const setInspectorMode = (data) => ({
    type: SET_INSPECTOR_MODE,
    payload: data,
});

export const setInspectorDetails = (data) => ({
    type: SET_INSPECTOR_DETAILS,
    payload: data,
});

export const setInspectorElement = (data) => ({
    type: SET_INSPECTOR_ELEMENT,
    payload: data,
});

export const setInspectorElementNodesId = (data) => ({
    type: SET_INSPECTOR_ELEMENT_NODES_ID,
    payload: data,
});

export const deviceHasPINCode = (data) => ({
    type: DEVICE_HAS_PIN_CODE,
    payload: data,
});

export const _isLoading = (data) => ({
    type: IS_LOADING,
    payload: data,
});

export const createDeviceSession = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/interactive',
        data: {
            desiredCapabilities: {
                udid: data,
                webClient: true,
                user: auth.email,
            },
        },
    })
        .then((response) => {
            if (response.data.streamURL === 'null') {
                dispatch(StreamURLDevice('undefined'));
            } else {
                //TO DO fix proxy for transfering stream
                //dispatch(StreamURLDevice(`${BASE_URL}/user/${response.data.sid}/stream.mjpeg`));
                dispatch(StreamURLDevice(response.data.streamURL));
            }

            dispatch(getScreen({ AppiumSID: response.data.sid, type: 'display' }));
            dispatch(AppiumSessionID(response.data.sid));
            dispatch(deviceHasPINCode(response.data.hasPINCode));
        })
        .catch((error) => {
            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: error.response.data.value.message,
                    type: 'error',
                })
            );
            dispatch(handlerNotification());
            setTimeout(() => {
                window.location = MAIN_PAGE;
            }, 5000);
        });
};

export const attachToExistingSession = (elem) => (dispatch) => {
    axios({
        method: 'POST',
        url: BASE_URL + '/interactive/attach',
        data: {
            sid: elem.sid,
        },
    })
        .then((response) => {
            console.log('ON CREATE RESPONSE:  ' + JSON.stringify(response, 0, 4));
            if (response.data.streamURL === 'null') {
                dispatch(StreamURLDevice('undefined'));
            } else {
                dispatch(StreamURLDevice(response.data.streamURL));
            }
            dispatch(getScreen({ AppiumSID: response.data.sid, type: 'display' }));

            dispatch(AppiumSessionID(response.data.sid));
        })
        .catch((error) => {
            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: error.response.data.value.message,
                    type: 'error',
                })
            );
            dispatch(handlerNotification());
            setTimeout(() => {
                window.location = MAIN_PAGE
            }, 5000);
        });
};

// simulating hardware keys (VOLUME_UP, VOLUME_DOWN, POWER, HOME, APP_SWITCH, BACK(only android))
export const hardwareButtonClick = (data) => (dispatch) => {
    const { AppiumSID, command, keyCode, commandsData } = data;
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/hardware_button`,
        data: {
            key: keyCode,
        },
    })
        .then(() => {
            //add command to creation mode(green flag)
            if (commandsData !== undefined) {
                _pushCommand(dispatch, commandsData, command, keyCode, 'true');
            }
        })
        .catch((error) => {
            //add command to creation mode(red flag)
            if (commandsData !== undefined) {
                _pushCommand(dispatch, commandsData, command, keyCode, 'false');
            }
            _isExpired(error);
            errorHandler(error);
        });
};

/**
 * Perform screen gesture: swipe, touch ...
 * {
 * 		AppiumSID: <SID>,
 * 		command: <Name>,
 * 		params: {...},
 * 		commandsData
 * }
 * @param {Object} data
 */
export const executeGesture = (data) => (dispatch, getState) => {
    const { AppiumSID, command, params, commandsData } = data;
    const { sessionTab } = getState().interactive;

    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/gesture`,
        data: {
            command,
            params,
        },
    })
        .then(() => {
            if (sessionTab === 'Element Trees') {
                dispatch(getElementTrees({ SID: AppiumSID }));
            }
            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, params, 'true');
        })
        .catch((error) => {
            console.log('executeGesture error: ', error);
            //add command to creation mode(red flag)
            _pushCommand(dispatch, commandsData, command, params, 'false');
            _isExpired(error);
            errorHandler(error);
        });
};

//creation mode commands: source(), takeScreenshot(), ...
//localfarmpcagent\appiumClient\appiumClient.js - listed all supported commands;
export const executeCommand = (data) => (dispatch) => {
    const { SID, id, command, list } = data;
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${SID}/execute`,
        data: {
            command,
        },
    })
        .then((response) => {
            let item = list.find((elem) => elem.id === id);
            item.status = 'true';
            item.response = response.data;
            dispatch(commandList(list));
        })
        .catch((error) => {
            let item = list.find((elem) => elem.id === id);
            item.status = 'false';
            item.response = error.response.statusText;
            dispatch(commandList(list));
            _isExpired(error);
            errorHandler(error);
        });
};

// save all creation mode commands to file
export const saveToFileCommand = (data) => (dispatch) => {
    const { SID, source } = data;
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${SID}/save_command`,
        data: {
            source,
        },
    })
        .then((response) => {
            caseCloses.play();
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
            _isExpired(error);
        });
};

export const getScreenshot = (data) => (dispatch) => {
    const { AppiumSID, command, commandsData, type } = data;

    const instanceAxios = {
        method: 'GET',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/screenshot/${type}`,
        responseType: type === 'save_default' ? 'basic' : 'blob',
    };

    axios(instanceAxios)
        .then((response) => {
            if (type !== 'save_default') {
                const fileName = `screenshot_${new Date().getTime()}.png`;
                _openSaveAsDialog(response.data, fileName);
            }

            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, '', 'true');
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch(() => {
            _pushCommand(dispatch, commandsData, command, '', 'false');
        });
};

export const getScreenSource = (data) => (dispatch) => {
    const { AppiumSID, command, commandsData, path } = data;

    const instanceAxios = {
        method: 'GET',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/source/${path}`,
        responseType: path === 'save_default' ? 'basic' : 'blob',
    };

    axios(instanceAxios)
        .then((response) => {
            if (path !== 'save_default') {
                const fileName = `source_${new Date().getTime()}.xml`;
                _openSaveAsDialog(response.data, fileName);
            }

            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, '', 'true');
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
            //add command to creation mode(red flag)
            _pushCommand(dispatch, commandsData, command, '', 'false');
        });
};

export const getScreen = (data) => (dispatch) => {
    const { type, AppiumSID } = data;

    axios({
        method: 'GET',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/screenshot/${type}`,
    })
        .then((response) => {
            dispatch(setScreen(response.data.data));
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

export const updateScreen = (data) => (dispatch) => {
    const { AppiumSID, command, commandsData, type } = data;
    axios({
        method: 'GET',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/screenshot/${type}`,
    })
        .then((response) => {
            dispatch(setScreen(response.data.data));
            dispatch(preventMultipleClicks(false));

            _pushCommand(dispatch, commandsData, command, '', 'true');
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch(() => {
            _pushCommand(dispatch, commandsData, command, '', 'false');
        });
};

export const reboot = (data) => (dispatch) => {
    const { AppiumSID, commandsData } = data;
    const command = 'reboot';

    dispatch(_isLoading(true));
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/reboot`,
    })
        .then((response) => {
            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, '', 'true');
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
            dispatch(_isLoading(false));
        })
        .catch(() => {
            dispatch(_isLoading(false));
            _pushCommand(dispatch, commandsData, command, '', 'false');
        });
};

export const turnDevicePinOnOff = (data) => (dispatch) => {
    const { AppiumSID, onoff } = data;
    dispatch(_isLoading(true));
    axios({
        method: 'GET',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/turnDevicePinOnOff`,
        params: {
            onoff: onoff,
        },
    })
        .then((response) => {
            dispatch(deviceHasPINCode(onoff === 'on' ? true : false));
            dispatch(
                NotificationMessage({
                    msg: response.data,
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
            dispatch(_isLoading(false));
        })
        .catch((error) => {
            dispatch(_isLoading(false));
            _isExpired(error);
            errorHandler(error);
        });
};

export const toggleWifi = (data) => (dispatch) => {
    const { AppiumSID } = data;
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/toggle_wifi`,
    })
        .then((response) => {
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            _isExpired(error);
            errorHandler(error);
        });
};

export const getDeviceInstalledApp = (data) => (dispatch) => {
    const { SID } = data;
    axios({
        method: 'GET',
        url: `${BASE_URL}/interactive/${SID}/device/installed_apps`,
    })
        .then((response) => {
            dispatch(installedAppList(response.data));
        })
        .catch((error) => {
            errorHandler(error);
        });
};

export const installApp = (appPath, deviceOs, AppiumSID, setIsLoading) => (dispatch) => {
    if (setIsLoading) {
        setIsLoading(true);
    }
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/install_app`,
        data: { appPath },
    })
        .then(() => {
            dispatch(getDeviceInstalledApp({ SID: AppiumSID, deviceOs }));
            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: 'Application installed',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .then(() => {
            if (setIsLoading) {
                setIsLoading(false);
            }
        })
        .catch((error) => {
            if (setIsLoading) {
                setIsLoading(false);
            }
            errorHandler(error);
        });
};

export const launchApp = (data) => (dispatch) => {
    const { AppiumSID, command, params, commandsData } = data;
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/launch_app`,
        data: params,
    })
        .then(() => {
            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, params, 'true');

            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: 'Application started',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            //add command to creation mode(red flag)
            _pushCommand(dispatch, commandsData, command, params, 'false');

            errorHandler(error);
        });
};

export const terminateApp = (data) => (dispatch) => {
    const { AppiumSID, command, packageName, commandsData } = data;
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/terminate_app`,
        data: { packageName },
    })
        .then(() => {
            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, { bundleId: packageName }, 'true');

            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: 'Application closed',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            //add command to creation mode(red flag)
            _pushCommand(dispatch, commandsData, command, '', 'false');

            errorHandler(error);
        });
};

export const startVideoRecord = (data) => (dispatch) => {
    const { AppiumSID, command, commandsData } = data;

    const instanceAxios = {
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/start_video_record`,
    };

    axios(instanceAxios)
        .then((response) => {
            _pushCommand(dispatch, commandsData, command, '', 'true');
            dispatch(Recording(true));
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            _pushCommand(dispatch, commandsData, command, '', 'false');
            _isExpired(error);
            errorHandler(error);
        });
};

export const stopVideoRecord = (sessionId) => (dispatch, getState) => {
    const { AppiumSID, commandsData } = getState().interactive;

    const instanceAxios = {
        method: 'DELETE',
        url: `${BASE_URL}/interactive/${sessionId ?? AppiumSID}/device/stop_video_record`,
    };

    axios(instanceAxios)
        .then((response) => {
            _pushCommand(dispatch, commandsData, 'stopVideoRecord', '', 'true');
            dispatch(Recording(false));
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            _pushCommand(dispatch, commandsData, 'stopVideoRecord', '', 'false');
            _isExpired(error);
            errorHandler(error);
        });
};

export const uninstallApp = (data) => (dispatch) => {
    const { AppiumSID, command, packageName, commandsData, deviceOs } = data;

    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/uninstall_app`,
        data: { packageName },
    })
        .then(() => {
            dispatch(getDeviceInstalledApp({ SID: AppiumSID, deviceOs }));
        })
        .then(() => {
            //add command to creation mode(green flag)
            _pushCommand(dispatch, commandsData, command, packageName, 'true');
            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: 'Application removed',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
        })
        .catch((error) => {
            //add command to creation mode(red flag)
            _pushCommand(dispatch, commandsData, command, packageName, 'false');
            errorHandler(error);
        });
};

//get device file system(android)
//get bundle file system(ios)
export const getDeviceFolder = (data, setIsloading) => (dispatch) => {
    const { SID } = data;
    if (setIsloading) {
        setIsloading(true);
    }
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${SID}/device/ls`,
        data: {
            path: data.path,
        },
    })
        .then((response) => {
            dispatch(DeviceActiveFolder(response.data.filter((item) => item !== '')));
        })
        .then(() => {
            if (setIsloading) {
                setIsloading(false);
            }
        })
        .catch((error) => {
            errorHandler(error);
            if (setIsloading) {
                setIsloading(false);
            }
        });
};

// delete file/folder from device
export const removeItemFromDevice = (data) => (dispatch) => {
    const { AppiumSID, filePath, setIsLoading } = data;
    if (setIsLoading) {
        setIsLoading(true);
    }
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/remove_item`,
        data: {
            filePath,
        },
    })
        .then(() => {
            caseCloses.play();
            dispatch(
                NotificationMessage({
                    msg: 'Item removed',
                    type: 'success',
                })
            );
            dispatch(handlerNotification());
            if (setIsLoading) {
                setIsLoading(false);
            }
        })
        .catch((error) => {
            if (setIsLoading) {
                setIsLoading(false);
            }
            _isExpired(error);
            errorHandler(error);
        });
};

export const pushFileToDevice = (AppiumSID, data, selectedPath, fileName) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/push_file`,
        data: data,
        params: {
            destination: selectedPath,
            file: fileName,
        },
    })
        .then((response) => {
            dispatch(getDeviceFolder({ SID: AppiumSID }));
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
            _isExpired(error);
        });
};

//download file/folder from device
export const pullFileFromDevice = (data) => (dispatch) => {
    const { AppiumSID, saveAsPath, filePath, fileType } = data;

    axios({
        method: 'POST',
        url: `${BASE_URL}/interactive/${AppiumSID}/device/pull_file`,
        data: { saveAsPath, filePath, fileType },
        responseType: saveAsPath === 'default' ? 'basic' : 'blob',
    })
        .then((response) => {
            if (saveAsPath !== 'default') {
                let fileName = filePath.split('/').filter(Boolean).pop();
                fileName = fileType === 'folder' ? fileName + '.zip' : fileName;
                _openSaveAsDialog(response.data, fileName);
            }
            dispatch(NotificationMessage(response.data));
            dispatch(handlerNotification());
        })
        .catch((error) => {
            errorHandler(error);
            deduction.play();
            dispatch(NotificationMessage(error.response.data));
            dispatch(handlerNotification());
        });
};

// get XML source of screen
export const getElementTrees = (data) => (dispatch) => {
    const { SID } = data;

    axios({
        method: 'GET',
        url: `${BASE_URL}/interactive/${SID}/device/source/tree`,
    })
        .then((response) => {
            dispatch(XMLTSource(response.data.source));
        })
        .catch((error) => {
            _isExpired(error);
            errorHandler(error);
        });
};

export const deleteDeviceSession = (data) => (dispatch) => {
    const sid = data.sid;
    axios({
        method: 'DELETE',
        url: `${BASE_URL}/interactive/${sid}`,
    })
        .then(() => {
            dispatch(getAllAgents());
            dispatch(sessionsPopup(false));
            dispatch(StreamURLDevice(undefined));
            dispatch(AppiumSessionID(undefined));
            mySessions();
        })
        .catch((error) => {
            _isExpired(error);
            errorHandler(error);
        });
};

//reproduce all device interaction commands to creation mode
const _pushCommand = (dispatch, commandsData, command, params, status) => {
    commandsData.push({
        id: commandsData.length + 1,
        command,
        params: params,
        status: status,
    });
    dispatch(commandList(commandsData));
};

const _openSaveAsDialog = (data, fileName) => {
    const blob = data;
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

// used for active session popup
export const clearStateOnSessionOpen = (data) => (dispatch) => {
    const { sessionType, sid } = data;

    if (sessionType !== 'browser') {
        dispatch(attachToExistingSession(data));
    }
    dispatch(setSessionTab('Parameters'));
    dispatch(sessionStoped(false));
    dispatch(sessionExpired(false));
    dispatch(getScreen({ AppiumSID: sid, type: 'display' }));
    dispatch(setCreationMode(false));
};

// used for active session popup
export const clearStateOnSessionStop = (data) => (dispatch) => {
    dispatch(deleteDeviceSession(data));
    dispatch(setTabNumber(0));
    dispatch(setScreen(null));
    dispatch(commandList([]));
};
