import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import Spiner from '../../Common/Loader';
import SelectDialog from '../Common/SelectDialog';
import ConfirmDialog from '../Common/ConfirmDialog';

import IPhoneXR from '../Skins/IPhone_XR';
import GalaxyS9 from '../Skins/Galaxy_S9';

import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import WifiIcon from '@material-ui/icons/Wifi';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import RestartIcon from '@material-ui/icons/SettingsPower';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

import { ReactComponent as SetPinCodeIcon } from '../../../../static/images/set_pin_code.svg';

import XMLParser from 'react-xml-parser';
import JSMpeg from '@cycjimmy/jsmpeg-player';

import {
    toggleWifi,
    updateScreen,
    getScreenshot,
    executeGesture,
    getScreenSource,
    hardwareButtonClick,
    reboot,
    turnDevicePinOnOff,
    getElementTrees,
    setInspectorMode,
    setInspectorDetails,
    setInspectorElementNodesId,
    startVideoRecord,
    stopVideoRecord,
    preventMultipleClicks,
} from '../../../store/interactive/interactive.actions';
/* import { setCreationMode } from '../../../store/auxiliary/auxiliary.actions'; */
import { handlerNotification, NotificationMessage } from '../../../store/notification/notification.actions';

const Stream = () => {
    const dispatch = useDispatch();
    const streamElement = useRef(null);
    const canvasRef = useRef(null);
    const AppiumSID = useSelector((state) => state.interactive.AppiumSID, shallowEqual);
    const inspectorXY = useSelector((state) => state.device.inspectorXY, shallowEqual);
    const activeDevice = useSelector((state) => state.device.activeDevice, shallowEqual);
    const commandsData = useSelector((state) => state.interactive.commandsData, shallowEqual);
    const bgImage = useSelector((state) => state.interactive.bgImage, shallowEqual);
    const streamURL = useSelector((state) => state.interactive.streamURL, shallowEqual);
    const sessionTab = useSelector((state) => state.interactive.sessionTab, shallowEqual);
    const xmlSource = useSelector((state) => state.interactive.xmlSource, shallowEqual);
    const inspectorMode = useSelector((state) => state.interactive.inspectorMode, shallowEqual);
    const isPopupOpen = useSelector(
        (state) => state.auxiliary.isTokenPopup || state.auxiliary.isSessions,
        shallowEqual
    );
    const isVideoRecording = useSelector((state) => state.interactive.recording, shallowEqual);
    const isBrowserSession = useSelector((state) => state.auxiliary.isBrowserSession, shallowEqual);
    const isPrevented = useSelector((state) => state.interactive.isPrevented, shallowEqual);
    const hasPINCode = useSelector((state) => state.interactive.hasPinCode, shallowEqual);

    const [clicks, setClicks] = useState(0);
    const [XStart, setXStart] = useState(0);
    const [YStart, setYStart] = useState(0);
    const [XEnd, setXEnd] = useState(0);
    const [YEnd, setYEnd] = useState(0);
    const [matchXY, setMatchXY] = useState(null);
    const [isInside, setIsInside] = useState(false);
    const [clickXY, setClickXY] = useState(null);
    const [tree, setTree] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState({ reboot: false, inspector: false });

    const [sourcePopupIsOpen, setSourcePopupIsOpen] = useState(false);
    const [screenshotPopupIsOpen, setScreenshotPopupIsOpen] = useState(false);

    let timer;
    const isElementTreeTab = sessionTab === 'Element Trees';

    useEffect(() => {
        if (canvasRef.current) {
            new JSMpeg.Player(streamURL, { canvas: canvasRef.current, source: JSMpeg.Source.Fetch });
        }
    }, [streamURL, canvasRef]);

    const handleDeviceHardwareButtonClick = (keyCode) => {
        dispatch(hardwareButtonClick({ AppiumSID, command: 'pressKeyCode', keyCode, commandsData }));
        isElementTreeTab && dispatch(getElementTrees({ SID: AppiumSID }));
    };

    const handleSourceButtonClick = (path) => {
        setSourcePopupIsOpen(false);
        //dispatch(NotificationMessage({ msg: 'Getting source', type: 'warning' }));
        dispatch(getScreenSource({ AppiumSID, command: 'source', commandsData, path, deviceId: activeDevice.id }));
    };

    const handleScreenshotButtonClick = (type) => {
        setScreenshotPopupIsOpen(false);
        //dispatch(NotificationMessage({ msg: 'Taking screenshot', type: 'screenshot' }));
        dispatch(
            getScreenshot({ AppiumSID, command: 'takeScreenshot', commandsData, type, deviceId: activeDevice.id })
        );
    };

    const handleUpdateStreamButtonClick = (type) => {
        dispatch(preventMultipleClicks(true));
        dispatch(NotificationMessage({ msg: 'Update screen', type: 'screen' }));
        dispatch(updateScreen({ AppiumSID, command: 'updateScreen', commandsData, type }));
    };

    const handleRebootButtonClick = () => {
        setIsOpen({ ...isOpen, reboot: false });
        dispatch(NotificationMessage({ msg: 'Rebooting device', type: 'warning', btn: false }));
        dispatch(reboot({ AppiumSID, commandsData, setIsLoading }));
    };

    const handleInspectorButtonClick = () => {
        inspectorMode && setClickXY(null);
        setIsOpen({ ...isOpen, inspector: false });
        dispatch(setInspectorMode(!inspectorMode));
    };

    const handleVideoRecordStart = () => {
        dispatch(startVideoRecord({ AppiumSID, command: 'startVideoRecord', commandsData }));
    };
    const handleVideoRecordStop = () => {
        dispatch(stopVideoRecord());
    };

    const getActiveDevice = () => {
        //should be changed to activeDevice.model
        //return activeDevice.model;
        //return "iphone-xr";
        //return 'galaxy-s-9';
        if (activeDevice?.os?.toLowerCase() === 'ios') {
            return 'iphone-xr';
        } else {
            return 'galaxy-s-9';
        }
    };

    //getting stream image resolution for different screen size
    const getStreamResolution = () => {
        const element = streamURL ? canvasRef : streamElement;
        const height = element.current.clientHeight;
        const width = element.current.clientWidth;

        return { height, width };
    };

    const getCoordinatesClick = (param) => {
        const streamResolution = getStreamResolution();
        const relativeX = param.XStart / streamResolution.width;
        const relativeY = param.YStart / streamResolution.height;

        return [relativeX, relativeY];
    };

    const getCoordinatesMove = (param) => {
        let streamResolution = getStreamResolution();

        let realWidth,
            realHeigth = undefined;
        let absoluteX,
            absoluteY = undefined;

        if (typeof activeDevice.resolution === 'string') {
            const parseResolution = JSON.parse(activeDevice.resolution);
            realWidth = parseResolution.width;
            realHeigth = parseResolution.height;
        } else {
            realWidth = activeDevice.resolution.width;
            realHeigth = activeDevice.resolution.height;
        }

        absoluteX = (realWidth * param.XStart) / streamResolution.width;
        absoluteY = (realHeigth * param.YStart) / streamResolution.height;

        return [Math.ceil(absoluteX), Math.ceil(absoluteY)];
    };

    const getCoordinatesSwipe = (param) => {
        const streamResolution = getStreamResolution();
        const relativeStartX = param.XStart / streamResolution.width;
        const relativeStartY = param.YStart / streamResolution.height;
        const relativeEndX = param.XEnd / streamResolution.width;
        const relativeEndY = param.YEnd / streamResolution.height;

        return [relativeStartX, relativeStartY, relativeEndX, relativeEndY];
    };

    const resolutionRatioX = () => {
        let streamResolution = getStreamResolution();
        let realWidth;
        if (typeof activeDevice.resolution === 'string') {
            const parseResolution = JSON.parse(activeDevice.resolution);
            realWidth = parseResolution.width;
        } else {
            realWidth = activeDevice.resolution.width;
        }
        return (streamResolution.width / realWidth).toFixed(3);
    };

    const resolutionRatioY = () => {
        let streamResolution = getStreamResolution();
        let realHeight;
        if (typeof activeDevice.resolution === 'string') {
            const parseResolution = JSON.parse(activeDevice.resolution);
            realHeight = parseResolution.height;
        } else {
            realHeight = activeDevice.resolution.height;
        }
        return (streamResolution.height / realHeight).toFixed(3);
    };

    const treeItemToExpand = (tree, idArray, target) => {
        const bounds = tree?.attributes?.bounds;
        const boundsToCompare = bounds ? parseBounds(bounds) : [];

        if (isEqual(boundsToCompare, target)) {
            idArray.push(`${tree.id}`);
            return idArray;
        }

        for (const child of tree.children) {
            const found = treeItemToExpand(child, idArray, target);

            if (found) {
                idArray.push(`${tree.id}`);
                return idArray;
            }
        }
    };

    const handleMouseMove = useCallback(
        (e) => {
            const coordinates = {
                XStart: e.nativeEvent.layerX,
                YStart: e.nativeEvent.layerY,
            };

            setIsInside(true);
            const clickXY = getCoordinatesClick(coordinates);
            const mouseMoveXY = getCoordinatesMove(coordinates);

            clearTimeout(timer);
            timer = setTimeout(() => {
                const inspectorMatches = [];
                const treeItemsId = [];

                findElementsByCoordinates(tree, mouseMoveXY, inspectorMatches, 0);
                const matchesElement = inspectorMatches.sort((a, b) => b.counter - a.counter)[0];

                if (matchesElement) {
                    const itemToExpand = treeItemToExpand(tree, treeItemsId, matchesElement?.bounds).reverse();
                    dispatch(setInspectorElementNodesId(itemToExpand));
                }

                dispatch(setInspectorDetails(matchesElement));
                setMatchXY(matchesElement);
                setClickXY(clickXY);
            }, 200);
        },
        [tree, dispatch]
    );

    const checkMatchingXY = (mousePosition, topPosition, bottomPosition) => {
        const matchX = topPosition[0] <= mousePosition[0] && mousePosition[0] <= bottomPosition[0];
        const matchY = topPosition[1] <= mousePosition[1] && mousePosition[1] <= bottomPosition[1];
        return matchX && matchY ? true : false;
    };

    const parseBounds = (bounds) => {
        const XYtop = JSON.parse(bounds.slice(0, bounds.indexOf(']') + 1));
        const XYbottom = JSON.parse(bounds.slice(bounds.indexOf(']') + 1));
        return [XYtop, XYbottom];
    };

    const findElementsByCoordinates = (tree, mouseMoveXY, inspectorMatches, counter) => {
        const bounds = tree?.attributes?.bounds;

        if (bounds) {
            const [XYtop, XYbottom] = parseBounds(bounds);
            if (checkMatchingXY(mouseMoveXY, XYtop, XYbottom)) {
                inspectorMatches.push({ ...tree?.attributes, bounds: [XYtop, XYbottom], counter });
            }
        }

        for (const child of tree.children) {
            findElementsByCoordinates(child, mouseMoveXY, inspectorMatches, ++counter);
        }
    };

    //TO DO: refactor
    const handlerClickType = (type, elem) => {
        const check = isElementTreeTab && clickXY;

        switch (type) {
            case 'CLICK':
                {
                    const coordinates = check ? clickXY : getCoordinatesClick(elem);
                    dispatch(
                        executeGesture({
                            AppiumSID: AppiumSID,
                            command: 'touch',
                            params: coordinates,
                            commandsData,
                        })
                    );
                }
                break;
            case 'SWIPE': {
                const coordinates = check ? clickXY : getCoordinatesSwipe(elem);
                dispatch(
                    executeGesture({
                        AppiumSID: AppiumSID,
                        command: 'swipe',
                        params: coordinates,
                        commandsData,
                    })
                );
                break;
            }
            case 'DOUBLE_CLICK': {
                // eslint-disable-next-line
                const coordinates = getCoordinatesClick(elem);
                break;
            }
            default:
                break;
        }
    };

    //activeDevice.model as a param
    const renderSkin = () => {
        let device = getActiveDevice();
        switch (device) {
            case 'galaxy-s-9':
                return <GalaxyS9 />;
            case 'iphone-xr':
                return <IPhoneXR />;
            default:
                return <h1>Error</h1>;
        }
    };

    const renderScreen = () => {
        const deviceOS = activeDevice?.os?.toLowerCase();

        if (streamURL && deviceOS === 'ios') {
            return <canvas width="100%" height="auto" className={`${getActiveDevice()}-stream`} ref={canvasRef} />;
        }
        if (bgImage) {
            return (
                <img
                    alt="stream"
                    ref={streamElement}
                    data-testid="stream-img-test"
                    src={`data:image/jpeg;base64,${bgImage}`}
                    className={`${getActiveDevice()}-stream`}
                />
            );
        }

        return <p className={`${getActiveDevice()}-stream loading-stream`}>Loading...</p>;
    };

    const renderBottomButtons = () => {
        const isDisabled = isPrevented ? true : false;
        if (!streamURL) {
            return (
                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    className="screenshot-icon"
                    disabled={isDisabled}
                    onClick={() => {
                        !isPopupOpen && handleUpdateStreamButtonClick('display');
                        isElementTreeTab && dispatch(getElementTrees({ SID: AppiumSID }));
                    }}>
                    Update screen
                </Button>
            );
        }

        return '';
    };

    const checkActiveDeviceOS = () => {
        return activeDevice?.os?.toLowerCase() === 'ios';
    };

    const highlightElementBoundary = (bounds) => {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: bounds[0][0] * resolutionRatioX(),
                    top: bounds[0][1] * resolutionRatioY(),
                    width: (bounds[1][0] - bounds[0][0]) * resolutionRatioX(),
                    height: (bounds[1][1] - bounds[0][1]) * resolutionRatioY(),
                    border: '2px solid yellow',
                    boxSizing: 'border-box',
                }}
            />
        );
    };

    const renderSelectDialog = (isPopupOpen, isOpen, setIsOpen, action) => {
        const data = { isPopupOpen, isOpen, setIsOpen, action };

        return <SelectDialog {...data} />;
    };

    useEffect(
        () => {
            let singleClickTimer;
            if (XStart === XEnd && YStart === YEnd) {
                if (clicks === 1) {
                    singleClickTimer = setTimeout(function () {
                        handlerClickType('CLICK', { XStart, YStart });
                        setClicks(0);
                    }, 200);
                } else if (clicks === 2) {
                    handlerClickType('DOUBLE_CLICK', { XStart, YStart });
                    setClicks(0);
                }
                return () => clearTimeout(singleClickTimer);
            } else {
                handlerClickType('SWIPE', { XStart, YStart, XEnd, YEnd });
                setXStart(0);
                setYStart(0);
                setXEnd(0);
                setYEnd(0);
                setClicks(0);
            }
        },
        [clicks] // eslint-disable-line
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!streamURL && AppiumSID) {
                dispatch(
                    NotificationMessage({
                        msg: 'Stream is not available for the current session.',
                        type: 'warning',
                    })
                );
                dispatch(handlerNotification(10000));
            }
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [streamURL, AppiumSID]); // eslint-disable-line

    useEffect(
        () => {
            if (xmlSource) {
                const xml = new XMLParser().parseFromString(xmlSource);
                let id = 1;
                function identifiedXML(xml) {
                    if (Array.isArray(xml)) {
                        xml.forEach((item) => {
                            identifiedXML(item);
                        });
                    } else {
                        xml.id = `${id}`;
                        id++;
                        identifiedXML(xml.children);
                    }
                }
                identifiedXML(xml);
                setTree(xml);
            }
        },
        [xmlSource] // eslint-disable-line
    );

    return (
        <div className={`stream-wrapper ${isPopupOpen && 'disable'}`} data-testid="stream-test">
            <div className="leftPannel">
                <ConfirmDialog
                    {...{ isOpen, setIsOpen, isPopupOpen, value: 'reboot', action: handleRebootButtonClick }}>
                    <span>Are you sure you want to reboot your device?</span>
                </ConfirmDialog>

                {renderSelectDialog(
                    isPopupOpen,
                    screenshotPopupIsOpen,
                    setScreenshotPopupIsOpen,
                    handleScreenshotButtonClick
                )}
                {renderSelectDialog(isPopupOpen, sourcePopupIsOpen, setSourcePopupIsOpen, handleSourceButtonClick)}

                <div>
                    <Tooltip title="Reboot" placement="left-end" disableHoverListener={isPopupOpen}>
                        <RestartIcon
                            className="screenshot-icon"
                            onClick={() => setIsOpen({ ...isOpen, reboot: true })}
                        />
                    </Tooltip>
                </div>
                {hasPINCode !== null && (
                    <div style={{ position: 'relative' }}>
                        {hasPINCode ? (
                            <>
                                <Tooltip title="Set PIN Code OFF" placement="left-end">
                                    <SetPinCodeIcon
                                        className={'screenshot-icon set-pin-code-icon'}
                                        onClick={() => dispatch(turnDevicePinOnOff({ AppiumSID, onoff: 'off' }))}
                                    />
                                </Tooltip>
                                <LockIcon className="action-lock-icon" />
                            </>
                        ) : (
                            <Tooltip title="Set PIN Code ON" placement="left-end">
                                <SetPinCodeIcon
                                    className={'screenshot-icon set-pin-code-icon'}
                                    onClick={() => dispatch(turnDevicePinOnOff({ AppiumSID, onoff: 'on' }))}
                                />
                            </Tooltip>
                        )}
                    </div>
                )}
                <div>
                    <Tooltip title="Make Screenshot" placement="left-end" disableHoverListener={isPopupOpen}>
                        <PhotoCameraIcon className="screenshot-icon" onClick={() => setScreenshotPopupIsOpen(true)} />
                    </Tooltip>
                </div>

                {isBrowserSession && (
                    <div>
                        {isVideoRecording ? (
                            <Tooltip title="Stop recording" placement="left-end">
                                <VideocamOffIcon className="screenshot-icon" onClick={handleVideoRecordStop} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Start recording" placement="left-end">
                                <VideocamIcon className="screenshot-icon" onClick={handleVideoRecordStart} />
                            </Tooltip>
                        )}
                    </div>
                )}

                <div>
                    <Tooltip title="Take Source" placement="left-end" disableHoverListener={isPopupOpen}>
                        <AccountTreeIcon className="screenshot-icon" onClick={() => setSourcePopupIsOpen(true)} />
                    </Tooltip>
                </div>

                <div style={{ position: 'relative' }}>
                    <Tooltip title="Creation Mode" placement="left-end" disableHoverListener={isPopupOpen}>
                        <DeveloperModeIcon
                            className={`screenshot-icon screenshot-icon-not-active`}
                            //TODO uncomment when the developer mode will available, also uncomment import setCreationMode
                            /* onClick={() => dispatch(setCreationMode(true)) }  */
                        />
                    </Tooltip>
                    <LockIcon className="action-lock-icon" />
                </div>
            </div>

            <div className="skin-wrapper">
                {renderSkin()}
                {renderScreen()}

                <div
                    id="inspector-wrap"
                    className={`${getActiveDevice()}-stream`}
                    style={inspectorXY && { backgroundColor: ' rgba(0,0,0,0.3)' }}
                    onMouseDown={(e) => {
                        setXStart(e.nativeEvent.layerX);
                        setYStart(e.nativeEvent.layerY);
                    }}
                    onMouseUp={(e) => {
                        setXEnd(e.nativeEvent.layerX);
                        setYEnd(e.nativeEvent.layerY);
                        setClicks(clicks + 1);
                        //dispatch(getElementTrees({ SID: AppiumSID }));
                    }}
                    onMouseMove={(e) => {
                        isElementTreeTab && inspectorMode && handleMouseMove(e);
                    }}
                    onMouseLeave={(e) => {
                        e.persist();
                        if (e.buttons === 1) {
                            setXEnd(e.nativeEvent.layerX);
                            setYEnd(e.nativeEvent.layerY);
                            setClicks(clicks + 1);
                            dispatch(getElementTrees({ SID: AppiumSID }));
                        }

                        setIsInside(false);
                        setTimeout(() => dispatch(setInspectorDetails(null)), 200);
                    }}>
                    {Array.isArray(inspectorXY) && highlightElementBoundary(inspectorXY)}
                    {matchXY && isInside && highlightElementBoundary(matchXY.bounds)}
                </div>
            </div>

            <div className="rightPanel">
                <ConfirmDialog
                    {...{ isOpen, setIsOpen, isPopupOpen, value: 'inspector', action: handleInspectorButtonClick }}>
                    <span>Are you sure you want to {inspectorMode ? 'disable' : 'enable'} the inspector mode?</span>
                </ConfirmDialog>

                <div style={{ position: 'relative' }}>
                    <Tooltip title="Home" placement="right-end" disableHoverListener={isPopupOpen}>
                        <HomeIcon
                            className="screenshot-icon"
                            onClick={() => {
                                !isPopupOpen && handleDeviceHardwareButtonClick('HOME');
                            }}
                        />
                    </Tooltip>
                </div>

                <div style={{ position: 'relative' }}>
                    <Tooltip title="Apps" placement="right-end" disableHoverListener={isPopupOpen}>
                        <AppsIcon
                            className={`screenshot-icon`}
                            onClick={() => {
                                !isPopupOpen && handleDeviceHardwareButtonClick('APP_SWITCH');
                            }}
                        />
                    </Tooltip>
                </div>

                <div style={{ position: 'relative' }}>
                    <Tooltip title="Wi-Fi" placement="right-end">
                        <WifiIcon
                            className={`screenshot-icon ${checkActiveDeviceOS() ? 'screenshot-icon-not-active' : ''}`}
                            onClick={() =>
                                checkActiveDeviceOS() ? null : !isPopupOpen && dispatch(toggleWifi({ AppiumSID }))
                            }
                        />
                    </Tooltip>
                    {checkActiveDeviceOS() ? <LockIcon className="action-lock-icon" /> : null}
                </div>

                {!checkActiveDeviceOS() ? (
                    <div style={{ position: 'relative' }}>
                        <Tooltip title="Back" placement="right-end">
                            <ArrowBackIcon
                                className="screenshot-icon"
                                onClick={() => {
                                    !isPopupOpen && handleDeviceHardwareButtonClick('BACK');
                                }}
                            />
                        </Tooltip>
                    </div>
                ) : null}

                {!checkActiveDeviceOS() ? (
                    <div style={{ position: 'relative' }}>
                        <Tooltip title="Element inspector" placement="right-end">
                            <SelectAllIcon
                                className={`screenshot-icon ${!isElementTreeTab ? 'screenshot-icon-not-active' : ''}`}
                                onClick={() => (!isElementTreeTab ? null : setIsOpen({ ...isOpen, inspector: true }))}
                            />
                        </Tooltip>

                        {!isElementTreeTab ? (
                            <LockIcon className="action-lock-icon" />
                        ) : (
                            <div className={inspectorMode ? 'inspector-enable' : 'inspector-disable'} />
                        )}
                    </div>
                ) : null}
            </div>

            <div className="bottomPanel">
                <div style={{ position: 'relative' }}>{renderBottomButtons()}</div>
            </div>

            {isLoading && (
                <div className="spiner-device-controll">
                    <Spiner />
                </div>
            )}
        </div>
    );
};

export default Stream;
