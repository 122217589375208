import React, { useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { v4 } from 'uuid';

import MetadataList from './MedadataEditor/MetadataList';
import MetadataEditField from './MedadataEditor/MetadataEditField';

import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import StopIcon from '@material-ui/icons/Stop';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { deleteDeviceSession } from '../../../../../store/interactive/interactive.actions';
import { deleteDeviceById, refreshDeviceById, updateDeviceMetadata } from '../../../../../store/admin/admin.actions';

const DeviceSettings = ({ device }) => {
    const buttonRef = useRef();
    const dispatch = useDispatch();
    const activeSessions = useSelector((state) => state.interactive.activeSessions, shallowEqual);

    const [prop, setProp] = useState('');
    const [value, setValue] = useState('');
    const [metadata, setMetadata] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popup, setPopup] = useState({ parent: false, child: false });
    const [confirm, setConfirm] = useState({ release: false, delete: false, refresh: false });

    const open = Boolean(anchorEl);
    const isDisabled = activeSessions ? activeSessions.some((item) => item.deviceId === device.id) : false;

    const handleOpenMenu = (event) => {
        event.preventDefault();
        setAnchorEl(true);
    };

    const handleCloseChildPopup = () => setPopup({ ...popup, child: false });
    const handleCloseParentPopup = () => setPopup({ ...popup, parent: false });

    const handleOpenPopup = () => {
        setMetadata(JSON.stringify(device.metadata));

        setAnchorEl(null);
        setPopup({ ...popup, parent: true });
    };

    const updateDeviceMetadataInLocalStore = (device, newMetadata) => {
        device.metadata = newMetadata
    }

    const handleSaveData = (key, value) => {
        const newMetadata = { ...JSON.parse(metadata), [key]: JSON.parse(value) };
        dispatch(updateDeviceMetadata(device.id, newMetadata));

        updateDeviceMetadataInLocalStore(device, newMetadata);

        handleCloseParentPopup();
        setPopup({ child: false, parent: false });
    };

    const handleEditMetadata = (key, value) => {
        setProp(key);
        setValue(value);
        setPopup({ ...popup, child: true });
    };

    const handleDeleteMetadata = (key) => {
        let newMetadata = { ...JSON.parse(metadata) };
        delete newMetadata[key];

        dispatch(updateDeviceMetadata(device.id, newMetadata));
        setPopup({ ...popup, parent: false });

        updateDeviceMetadataInLocalStore(device, newMetadata);
    };

    const handleAddMetadata = () => {
        setProp('');
        setValue('');
        setPopup({ ...popup, child: true });
    };

    const handleStopDevice = () => {
        setAnchorEl(null);
        setConfirm({ ...setConfirm, release: true });
    };

    const handleDeleteDevice = () => {
        setAnchorEl(null);
        setConfirm({ ...setConfirm, delete: true });
    };

    const handleRefreshDevice = () => {
        setAnchorEl(null);
        setConfirm({ ...setConfirm, refresh: true });
    };

    const handleDeviceCommand = (command) => {
        switch (command) {
            case 'release': {
                if (activeSessions) {
                    activeSessions.forEach((item) => {
                        if (item.deviceId === device.id) {
                            dispatch(deleteDeviceSession({ sid: item.id }));
                        }
                    });
                }
                break;
            }
            case 'delete': {
                dispatch(deleteDeviceById(device.id));
                break;
            }
            case 'refresh':
                dispatch(refreshDeviceById(device.id));
                break;
            default:
                break;
        }
    };

    const tableHeadData = ['Variable', 'Value', '', ''];

    const menuItems = [
        [handleOpenPopup, 'Edit metadata', <EditIcon className="device-menu-icon" key={v4()} />, false],
        [handleStopDevice, 'Release device', <StopIcon className="device-menu-icon" key={v4()} />, !isDisabled],
        [handleDeleteDevice, 'Delete device', <DeleteIcon className="device-menu-icon" key={v4()} />, false],
        [handleRefreshDevice, 'Refresh device', <RefreshIcon className="device-menu-icon" key={v4()} />, false],
    ];

    const renderConfirmMessage = (type, command) => {
        return (
            <Dialog
                open={type}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setConfirm({ ...confirm, [command]: false })}>
                <DialogTitle id="alert-dialog-title">
                    <HelpIcon className="reboot-confirm-icon" />
                    Are you sure you want to {command} device?
                </DialogTitle>

                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            handleDeviceCommand(command);
                            setConfirm({ ...confirm, [command]: false });
                        }}>
                        Confirm
                    </Button>

                    <Button color="primary" onClick={() => setConfirm({ ...confirm, [command]: false })}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <div className="device-settings device-cell">
            <Toolbar className="device-menu-wrapper">
                <IconButton
                    edge="start"
                    ref={buttonRef}
                    aria-label="menu"
                    className="icon-wrapper"
                    data-testid="device-button-test"
                    onContextMenu={handleOpenMenu}>
                    <Tooltip title="Device settings" placement="left-start">
                        <SettingsIcon className="settings-icon" />
                    </Tooltip>
                </IconButton>

                <Menu
                    open={open}
                    id="menu-appbar"
                    keepMounted={false}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={() => buttonRef.current}
                    PaperProps={{ style: { transform: 'translateX(-8px) translateY(15px)' } }}>
                    {menuItems.map(([action, label, icon, status]) => (
                        <MenuItem
                            key={label}
                            onClick={action}
                            disabled={status}
                            className="settings-device-item device-menu-item">
                            {icon} {label}
                        </MenuItem>
                    ))}
                </Menu>
            </Toolbar>

            {confirm.delete ? renderConfirmMessage(confirm.delete, 'delete') : null}
            {confirm.refresh ? renderConfirmMessage(confirm.refresh, 'refresh') : null}
            {confirm.release ? renderConfirmMessage(confirm.release, 'release') : null}

            <Dialog
                fullWidth
                maxWidth="sm"
                open={popup.parent}
                onClose={handleCloseParentPopup}
                className="device-dialog-wrapper"
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" className="device-dialog-title">
                    Metadata Editor
                </DialogTitle>

                <DialogContent className="device-dialog-content">
                    <MetadataList
                        {...{ tableCellData: metadata, tableHeadData, handleEditMetadata, handleDeleteMetadata }}
                    />
                </DialogContent>

                <DialogActions className="device-dialog-action">
                    <Button
                        color="primary"
                        variant="outlined"
                        size="medium"
                        onClick={handleAddMetadata}
                        startIcon={<AddIcon className="device-menu-icon" />}>
                        Add New
                    </Button>
                </DialogActions>

                {popup.child ? (
                    <MetadataEditField {...{ handleCloseChildPopup, handleSaveData, prop, value, setProp, setValue }} />
                ) : null}
            </Dialog>
        </div>
    );
};

export default DeviceSettings;
