import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import moment from 'moment';
import { Chart as Timeline } from 'react-google-charts';

import DraggableCore from 'react-draggable';
import Datetime from 'react-datetime';
import {
    Select,
    InputLabel,
    Tooltip,
    Modal,
    Button,
    Checkbox,
    IconButton,
    ListItem,
    Drawer,
    Divider,
    ListItemText,
    AppBar,
    List,
    Paper,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

import { getExecutionTimelines } from '../../../store/admin/admin.actions';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    listText: {
        fontSize: '14px',
        wordBreak: 'break-word',
    },
    container: {
        width: '250px',
        position: 'absolute',
        top: '56px',
        left: '20px',
        padding: '15px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        background: ' #fff',
        boxShadow: '4px 4px 16px -8px rgba(0, 0, 0, 0.68)',
        border: '1px solid silver',
    },
    executeBtn: {
        width: '100%',
        height: '37px',
        backgroundColor: '$secondary-color',
        borderRadius: '4px',
        textAlign: 'center',
        color: '$text-color-primary',
        marginTop: '25px',
        fontSize: '14px',
    },
    form: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
    noData: {
        margin: '20% auto',
        fontSize: '30px',
        fontFamily: 'Effra',
        color: 'lightgrey',
        fontWeight: '500',
    },
    check: {
        '&.Mui-checked': {
            color: '#4d9bd5',
        },
    },
    rootCheck: {
        padding: '5px',
    },
    label: {
        color: '#90878d',
        lineHeight: 2,
        paddingLeft: '10px',
    },
    inputBody: {
        width: '95%',
        borderRadius: '4px',
        border: '1px solid #d2d2d2',
        padding: '5px',
        marginBottom: '5px',
        boxSizing: 'border-box',
    },
    inputWrap: {
        textAlign: 'center',
    },
    chartWrap: {
        width: '100%',
        height: '92%',
        textAlign: 'center',
    },
    button: {
        width: '60%',
        margin: '5px auto',
        height: '32px',
        color: '#FFFF',
        backgroundColor: '#314f72',
        boxShadow: '0px 1px 8px 2px rgba(34, 60, 80, 0.2)',
        '&:hover': {
            opacity: 0.9,
            backgroundColor: '#4d9bd5',
        },
    },
    positionRelative: {
        backgroundColor: '#cad8e6',
    },
    toolbar: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        color: '#182d44',
        fontWeight: '500',
        lineHeight: '1.5rem',
        textTransform: 'uppercase',
        fontFamily: ' "Roboto", "Helvetica", "Arial", sans-serif',
    },
    paperAnchorLeft: {
        padding: '10px',
        paddingLeft: '0',
        boxSizing: 'border-box',
        height: 'calc(100% - 45px)',
        position: 'absolute',
        top: '45px',
        minWidth: '280px',
        overflow: 'hidden',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        visibility: 'hidden',
    },
    listRoot: {
        position: 'relative',
        overflow: 'auto',
        height: '75%',
        '&>.filter-tooltip-wrapper': {
            position: 'absolute',
            top: '6px',
            right: '6px',
        },
    },
    gutters: {
        padding: 0,
    },
    btnLabel: {
        textAlign: 'end',
        cursor: 'pointer',
    },
}));

const EnhancedTableToolbar = ({ filteredDevices, setFilteredDevices, setOpenFilter }) => {
    return (
        <div className="filter-tooltip-wrapper">
            {filteredDevices && (
                <Tooltip title="clear filters">
                    <IconButton aria-label="clear list" onClick={() => setFilteredDevices(null)} style={{ padding: 0 }}>
                        <ClearAllIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Logs filter">
                <IconButton
                    data-testid="filter-icon-test"
                    style={{ padding: 0 }}
                    aria-label="filter list"
                    onClick={() => setOpenFilter((prev) => !prev)}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const Chart = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const chartData = useSelector((state) => state.admin.chartData, shallowEqual);
    const devicesData = useSelector((state) => state.device.realDevices, shallowEqual);

    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({});
    const [timelines, setTimelines] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [workloadTime, setWorkloadTime] = useState({});
    const [filteredDevices, setFilteredDevices] = useState(null);

    const [filteredData, setFilteredData] = useState({
        devices: [],
        startTime: moment(new Date(Date.now())),
        endTime: moment(new Date(Date.now())),
    });

    const dataForDeviceList = filteredDevices || devicesData;

    const chartSettings = [
        { type: 'string', id: 'Position' },
        { type: 'string', id: 'Name' },
        { type: 'string', role: 'tooltip' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'Stop' },
    ];

    const drawTooltip = (id, model, startTime, endTime) => {
        const startDate = moment(startTime).format('MM/DD/YY HH:mm:ss');
        const endDate = moment(endTime).format('MM/DD/YY HH:mm:ss');
        // const startPeriod = moment(filteredData.startTime).format('MM/DD/YY HH:mm:ss')
        // const endPeriod = moment(filteredData.endTime).format('MM/DD/YY HH:mm:ss')
        const duration = parseDate(getDateDifference(startTime, endTime));
        const workload = parseDate(moment.duration(workloadTime[id]));
        const downtime = parseDate(getDateDifference(filteredData.startTime, filteredData.endTime));

        return `<div style="padding:10px; font-size:14px">
         <p style="margin-bottom:10px"><span style="font-weight:bold">Work period:</span> ${startDate} - ${endDate}</p>
         <p style="margin-bottom:10px"><span style="font-weight:bold">Duration:</span> ${duration}</p>
         <div style="margin-bottom:10px; border-bottom:1px solid grey; width:100%"></div>
         <p style="margin-bottom:10px"><span style="font-weight:bold">Total workload:</span> ${workload}</p>
         <p style="margin-bottom:10px"><span style="font-weight:bold">Total downtime:</span> ${downtime}</p>
      </div>`;
    };

    const getDeviceModel = (id) => {
        if (devicesData) {
            return devicesData.filter((device) => device.id === id)[0].model;
        }
    };

    function handleDrawerOpen() {
        setOpen((prev) => !prev);
    }

    function parseDate(date) {
        return [`${date?.asHours().toFixed(0)}h`, `${date?.minutes()}m`, `${date?.seconds()}s`].join(':');
    }

    function getDateDifference(startDate, endDate) {
        const diff = moment.duration(moment(endDate).diff(moment(startDate)));
        return diff;
    }

    function handleDateChange(value, type) {
        const time = moment(value);
        if (type === 'start') {
            setFilteredData((prev) => ({ ...prev, startTime: time }));
        } else {
            setFilteredData((prev) => ({ ...prev, endTime: time }));
        }
    }

    function handleDevicesChange(value, device) {
        setFilteredData((prev) => {
            if (filteredData.devices.includes(device)) {
                let devices = prev.devices.filter((dev) => dev !== device);
                return { ...prev, devices: devices };
            } else {
                return { ...prev, devices: [...prev.devices, device] };
            }
        });
    }

    function getDevicesUsage() {
        dispatch(getExecutionTimelines(filteredData));
        setTimeout(() => setOpen(false), 500);
    }

    function setTimelinesData() {
        let children = [];
        chartData.map((timeline) => {
            return children.push([
                `${getDeviceModel(timeline.deviceId)}`,
                '',
                drawTooltip(timeline.deviceId, getDeviceModel(timeline.deviceId), timeline.start, timeline.stop),
                new Date(timeline.start),
                new Date(timeline.stop),
            ]);
        });
        setTimelines([...children]);
    }

    function setWorkloadData() {
        let durations = {};
        chartData.map((timeline) => {
            return (durations[timeline.deviceId] = durations[timeline.deviceId]
                ? durations[timeline.deviceId] + (timeline.stop - timeline.start)
                : timeline.stop - timeline.start);
        });
        setWorkloadTime({ ...durations });
    }

    function handleFilterChange(e) {
        const { name, value } = e.target;
        setFilter((prev) => {
            if (value) {
                return { ...prev, [name]: value };
            } else {
                let temp = prev;
                delete temp[name];
                return { ...temp };
            }
        });
    }

    function filterDevice() {
        if (Object.keys(filter)?.length) {
            let temp = devicesData;
            for (let key in filter) {
                if (filter[key]) {
                    temp = temp.filter((device) => device[key]?.toUpperCase() === filter[key]?.toUpperCase());
                }
            }
            setFilteredDevices([...temp]);
        }
        // setFilteredData((prev) => ({...prev, devices: []}));
        // setTimelines([]);
        setOpenFilter(false);
        setFilter({});
    }

    useEffect(() => {
        if (chartData) {
            (async () => setWorkloadData())();
        }
        // eslint-disable-next-line
    }, [chartData]);

    useEffect(() => {
        if (chartData) {
            (async () => setTimelinesData())();
        }
        // eslint-disable-next-line
    }, [workloadTime]);

    return (
        <div className="users-wrap">
            <Paper className={classes.root} variant="elevation" elevation={3}>
                <AppBar
                    position="relative"
                    classes={{
                        positionRelative: classes.positionRelative,
                    }}>
                    <div className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={classes.menuButton}>
                            {open ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <p>Device&apos;s usage timelines</p>
                    </div>
                </AppBar>

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                        paperAnchorLeft: classes.paperAnchorLeft,
                    }}>
                    <div>
                        <label htmlFor="start date" className={classes.label}>
                            Start time
                        </label>
                        <Datetime
                            name="start date"
                            inputProps={{
                                placeholder: 'start date',
                                className: classes.inputBody,
                                'data-testid': 'start-time-test',
                            }}
                            className={classes.inputWrap}
                            initialValue={filteredData.startTime}
                            onChange={(e) => handleDateChange(e, 'start')}
                        />

                        <label htmlFor="end date" className={classes.label}>
                            End time
                        </label>
                        <Datetime
                            name="start date"
                            inputProps={{
                                placeholder: 'end date',
                                className: classes.inputBody,
                            }}
                            initialValue={filteredData.startTime}
                            className={classes.inputWrap}
                            onChange={(e) => handleDateChange(e, 'end')}
                        />
                    </div>
                    <Divider style={{ margin: '5px 0' }} />

                    <List classes={{ root: classes.listRoot }}>
                        <EnhancedTableToolbar {...{ filteredDevices, setFilteredDevices, setOpenFilter }} />

                        {dataForDeviceList &&
                            dataForDeviceList.map((device) => (
                                <ListItem
                                    classes={{
                                        gutters: classes.gutters,
                                    }}
                                    button
                                    key={device.id}>
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={filteredData.devices.includes(device.id)}
                                        classes={{
                                            colorPrimary: classes.check,
                                            root: classes.rootCheck,
                                        }}
                                        onChange={(e) => handleDevicesChange(e.target.value, device.id)}
                                    />
                                    <ListItemText
                                        primary={`${device.model}`}
                                        secondary={`(id: ${device.id})`}
                                        secondaryTypographyProps={{
                                            style: {
                                                fontSize: '12px',
                                            },
                                        }}
                                        classes={{ primary: classes.listText }}
                                    />
                                </ListItem>
                            ))}
                    </List>

                    <Modal
                        disablePortal
                        open={openFilter}
                        disableAutoFocus
                        disableEnforceFocus
                        className={classes.modal}
                        onClose={() => setOpenFilter((prev) => !prev)}>
                        <DraggableCore>
                            <div className={classes.container}>
                                <CloseIcon
                                    className={classes.closeIcon}
                                    onClick={() => setOpenFilter((prev) => !prev)}
                                />
                                <div className={classes.form}>
                                    <InputLabel id="os-select-label">OS</InputLabel>
                                    <Select
                                        native
                                        inputProps={{
                                            name: 'os',
                                            id: 'os-select',
                                            placeholder: 'select os',
                                        }}
                                        labelId="os-select-label"
                                        className={classes.input}
                                        onChange={handleFilterChange}
                                        placeholder={'select os'}>
                                        <option aria-label="None" value="" />
                                        <option value={'android'}>android</option>
                                        <option value={'ios'}>ios</option>
                                    </Select>
                                    <Button
                                        className={classes.executeBtn}
                                        variant="contained"
                                        type="submit"
                                        onClick={() => filterDevice()}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </DraggableCore>
                    </Modal>

                    <Divider style={{ margin: '5px 0' }} />
                    <Button variant="outlined" classes={{ root: classes.button }} onClick={getDevicesUsage}>
                        <p> OK </p>
                    </Button>
                </Drawer>

                <div className={classes.chartWrap}>
                    {!!timelines?.length ? (
                        <Timeline
                            height={'99%'}
                            chartType="Timeline"
                            legend_toggle={false}
                            loader={<div>Loading Chart</div>}
                            data={[chartSettings, ...timelines]}
                            onError={(err) => console.log(err, '---err timeline')}
                            options={{
                                tooltip: { isHtml: true },
                                timeline: {
                                    groupByRowLabel: true,
                                },
                            }}
                            rootProps={{ 'data-testid': 'timeline-test' }}
                        />
                    ) : (
                        <p className={classes.noData}> No Data </p>
                    )}
                </div>
            </Paper>
        </div>
    );
};
