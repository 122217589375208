import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Draggable from 'react-draggable';
import { TITLE } from '../../variables/common';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ListSubheader from '@material-ui/core/ListSubheader';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { ReactComponent as ChevronLeft } from '../../../static/images/chevron_left.svg';

import GalaxyS9 from '../../../static/images/galaxy_s9.svg';
import IphoneXR from '../../../static/images/iphone_xr.svg';
//import GalaxyNote8 from '../../../static/images/galaxy_note_8.svg';

import { HeaderTitle } from '../../store/auxiliary/auxiliary.actions';
import { getAllDevicesData } from '../../store/device/device.selectors';
import { DEVICE_STATUS_CODES } from '../../variables/common';
import JSMpeg from '@cycjimmy/jsmpeg-player';

const ListHeader = ({ isListOpen, handleListToggle }) => {
    return (
        <ListSubheader>
            <div className="list-header">
                {isListOpen ? (
                    'Select Device'
                ) : (
                    <PhoneAndroidIcon className="mobile" onClick={handleListToggle(true)} />
                )}
                <ListItemSecondaryAction>
                    {isListOpen ? (
                        <ChevronLeft onClick={handleListToggle(!isListOpen)} className="device-move" />
                    ) : null}
                </ListItemSecondaryAction>
            </div>
        </ListSubheader>
    );
};

const ItemForList = ({ device, handleActiveDevice }) => {
    const deviceHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleActiveDevice(device);
    };

    return (
        <div className="device">
            <ListItem className="device-elem">
                <div className="device-item" onClick={deviceHandler}>
                    <ListItemText
                        className="device-container"
                        primary={device.model}
                        secondary={
                            <span className="align-items">
                                <span
                                    className={`device-status ${
                                        device.status === DEVICE_STATUS_CODES.AVAILABLE
                                            ? 'active-device'
                                            : 'inactive-device'
                                    }`}
                                />
                                <span>{device.status}</span>
                            </span>
                        }
                    />
                </div>
            </ListItem>
        </div>
    );
};

const DeviceScreen = ({ stream, os }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            new JSMpeg.Player(stream, { canvas: canvasRef.current, source: JSMpeg.Source.Fetch });
        }
    }, [stream, canvasRef]);

    const getDeviceSkin = () => (os.toLowerCase() === 'ios' ? 'iphone' : 'galaxy');
    return (
        <>
            {stream ? (
                <canvas width="100%" height="auto" className={`stream-video-${getDeviceSkin()}`} ref={canvasRef} />
            ) : (
                <p className={`stream-image-${getDeviceSkin()} stream-message`}>Stream unavailable</p>
            )}
        </>
    );
};

const DeviceDashboard = () => {
    const dispatch = useDispatch();
    const [busyData, setBusyData] = useState([]);
    const [preloadMessage, setPreloadMessage] = useState('Pleace wait, we are uploading data');
    const [activeDevices, setActiveDevices] = useState([]);
    const [toggleDevices, setToggleDevices] = useState([]);
    const activeSessions = useSelector((state) => state.interactive.activeSessions, shallowEqual);
    const allDevicesData = useSelector(getAllDevicesData);

    useEffect(() => {
        if (allDevicesData) {
            allDevicesData.forEach((device) => {
                const foundDevice = busyData.find((data) => data.id === device.id);
                if (!foundDevice && device.status.toLowerCase() === DEVICE_STATUS_CODES.BUSY) {
                    if (device.sid?.length !== 0) {
                        setPreloadMessage(undefined);
                        setBusyData((prev) => [...prev, device]);
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [allDevicesData]);

    useEffect(() => {
        dispatch(HeaderTitle(TITLE.DEVICE_DASHBOARD));
    }, [dispatch]);

    useEffect(() => {
        allDevicesData.filter((device) => {
            return activeSessions.find((session) => {
                return device.id === session.deviceId && Object.assign(device, { stream: session.stream });
            });
        });
    }, [allDevicesData, activeSessions]);

    const [isListOpen, setIsListOpen] = useState(false);
    const handleListToggle = (data) => () => {
        setIsListOpen(data);
    };

    const handleActiveDevice = (device) => {
        const isActive = activeDevices.find((item) => item.id === device.id);
        if (!isActive) {
            setActiveDevices([...activeDevices, device]);
        }
    };

    const handleToggleDevice = (id) => () => {
        const isClosed = toggleDevices.find((item) => item === id);
        if (isClosed) {
            setToggleDevices(toggleDevices.filter((item) => item !== id));
        } else {
            setToggleDevices([...toggleDevices, id]);
        }
    };

    const handleCloseDevice = (id) => () => {
        setActiveDevices(activeDevices.filter((device) => device.id !== id));
        const isClosed = toggleDevices.find((item) => item === id);
        if (isClosed) {
            setToggleDevices(toggleDevices.filter((item) => item !== id));
        }
    };

    return (
        <Container className="device-dashboard" maxWidth={false}>
            <div className={`device-list ${isListOpen ? 'list-open' : 'list-close'}`}>
                <List subheader={<ListHeader isListOpen={isListOpen} handleListToggle={handleListToggle} />}>
                    {preloadMessage ? (
                        <>
                            {isListOpen && (
                                <div className="device">
                                    <ListItem className="device-elem">
                                        <div className="device-item">
                                            <p>{preloadMessage}</p>
                                        </div>
                                    </ListItem>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {busyData.length !== 0 && (
                                <>
                                    {isListOpen
                                        ? busyData.map((device) => (
                                              <ItemForList
                                                  key={`${device.id}`}
                                                  device={device}
                                                  handleActiveDevice={handleActiveDevice}
                                              />
                                          ))
                                        : null}
                                </>
                            )}
                        </>
                    )}
                </List>
            </div>
            <div className="draggable-dashboard">
                {activeDevices && activeDevices.length >= 0
                    ? activeDevices.map((device) => {
                          return (
                              <Draggable
                                  defaultPosition={{ x: 0, y: 20 }}
                                  bounds={{ top: 20, left: 0 }}
                                  key={`${device.id}`}>
                                  <div className="skin-wrapper">
                                      <div
                                          className="dashboard-stream-wrapper"
                                          style={{
                                              height: `${
                                                  toggleDevices.find((id) => id === device.id) ? '20px' : 'auto'
                                              }`,
                                          }}>
                                          <div className="device-panel">
                                              <MinimizeIcon onClick={handleToggleDevice(device.id)} />
                                              {device.model}
                                              <CloseIcon onClick={handleCloseDevice(device.id)} />
                                          </div>
                                          <div
                                              style={{
                                                  opacity: `${
                                                      toggleDevices.find((id) => id === device.id) ? '0' : '1'
                                                  }`,
                                              }}>
                                              <img
                                                  alt="device skin"
                                                  className="skin-image"
                                                  // To Do: update device model
                                                  src={device.os.toLowerCase() === 'ios' ? IphoneXR : GalaxyS9}
                                              />
                                              <DeviceScreen {...device} />
                                          </div>
                                      </div>
                                  </div>
                              </Draggable>
                          );
                      })
                    : null}
            </div>
        </Container>
    );
};

export default DeviceDashboard;
