import {
    DIRECTORY_LIST,
    IS_RENAME,
    SELECT_CONTEXT,
    SET_SELECTED_REPO,
    TEXT_FILE_DATA,
    TOGGLE_FILE_FOLDER,
    VIDEO_PLAYER_STREAM,
    SET_LOADING_PERCENT,
} from '../storeActionTypes';

const initialState = {
    contextElement: null,
    directories: null,
    fileFolder: 'file',
    isRename: false,
    playerStream: null,
    selectedRepo: null,
    txtFileStream: null,
    loadingPercent: 0,
};

export function repositoriumReducer(state = initialState, { type, payload }) {
    switch (type) {
        case DIRECTORY_LIST: {
            return { ...state, directories: payload };
        }
        case IS_RENAME: {
            return { ...state, isRename: payload };
        }
        case SELECT_CONTEXT: {
            return { ...state, contextElement: payload };
        }
        case SET_SELECTED_REPO: {
            return { ...state, selectedRepo: payload };
        }
        case TEXT_FILE_DATA: {
            return { ...state, txtFileStream: payload };
        }
        case TOGGLE_FILE_FOLDER: {
            return { ...state, fileFolder: payload };
        }
        case VIDEO_PLAYER_STREAM: {
            return { ...state, playerStream: payload };
        }
        case SET_LOADING_PERCENT: {
            return { ...state, loadingPercent: payload };
        }
        default: {
            return state;
        }
    }
}
