import { CANCEL_MAINTENANCE_MESSAGE, SET_MAINTENANCE_MESSAGE } from '../storeActionTypes';

const initialState = {
    message: null,
};

export function maintenanceReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_MAINTENANCE_MESSAGE: {
            return { ...state, message: payload };
        }
        case CANCEL_MAINTENANCE_MESSAGE: {
            return { ...state, message: payload };
        }
        default: {
            return state;
        }
    }
}
