import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const SelectPathPopup = ({ action, isOpen, setIsOpen, path, type }) => {
    const createButton = (title, path, value, type) => (
        <Button color="primary" onClick={() => action(path, value, type)}>
            {title}
        </Button>
    );

    return (
        <div>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setIsOpen(false)}>
                <DialogTitle id="alert-dialog-title">
                    <SaveIcon className="save-confirm-icon" />
                    Choose a path to save the file:
                </DialogTitle>

                <DialogContent>
                    <div className="select-path-group">
                        <p>Default path:</p>
                        {createButton('Confirm', path, 'default', type)}
                    </div>

                    <div className="select-path-group">
                        <p>Choose a path:</p>
                        {createButton('Change', path, 'select', type)}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SelectPathPopup;
