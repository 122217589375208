import React from 'react';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

const MetadataList = (props) => {
    let { tableHeadData, tableCellData, handleEditMetadata, handleDeleteMetadata } = props;
    tableCellData = tableCellData ? JSON.parse(tableCellData) : [];

    const handleDelete = (key) => handleDeleteMetadata(key);
    const handleEdit = (key, value) => handleEditMetadata(key, value);

    return (
        <Table aria-label="simple table" className="device-dialog-table">
            <TableHead className="device-dialog-thead">
                <TableRow>
                    {tableHeadData.map((item, index) => (
                        <TableCell key={index} align="left">
                            {item}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody className="device-dialog-tbody">
                {Object.entries(tableCellData).map(([key, value]) => {
                    return (
                        <TableRow key={key}>
                            <TableCell align="left" className="device-dialog-small-cell">
                                <div className="device-dialog-cell-content">{`${key}`}</div>
                            </TableCell>

                            <TableCell align="left" className="device-dialog-big-cell">
                                <div className="device-dialog-cell-content">{JSON.stringify(value)}</div>
                            </TableCell>

                            <TableCell align="left" className="device-dialog-small-cell">
                                <Button color="primary" onClick={() => handleEdit(key, JSON.stringify(value))}>
                                    Edit
                                </Button>
                            </TableCell>

                            <TableCell align="left" className="device-dialog-small-cell">
                                <Button color="primary" onClick={() => handleDelete(key)}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default MetadataList;
