import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Cancel from '@material-ui/icons/Cancel';
import { Box, IconButton, makeStyles, Zoom } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { getMaintenanceMessage } from '../../store/maintenance/maintenance.selectors';
import { useSelector } from 'react-redux';

const cookies = new Cookies();

const useStyles = makeStyles({
    alert: {
        alignItems: 'center',
        boxShadow: '4px 4px 12px -2px rgb(159 166 189 / 42%)',
        wordBreak: 'break-word',
    },
});

const MaintenanceNotification = ({ message }) => {
    const [open, setOpen] = useState(true);
    const maintenanceMessage = useSelector(getMaintenanceMessage);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
        cookies.set('maintenanceMessage', maintenanceMessage.hash, { path: '/' });
    };

    return (
        <Box>
            <Zoom in={open} style={{ transitionDelay: open ? '500ms' : '0ms' }}>
                <Alert
                    severity="warning"
                    data-testid="message"
                    className={[classes.alert, 'maintenance'].join(' ')}
                    action={
                        <IconButton
                            data-testid="close-btn"
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleClose}>
                            <Cancel data-testid="close-icon" />
                        </IconButton>
                    }>
                    {message.text}
                </Alert>
            </Zoom>
        </Box>
    );
};

export default MaintenanceNotification;
