export const MAIN_PAGE = `${process.env.REACT_APP_API_PREFIX}/`;
export const BOARD = `${process.env.REACT_APP_API_PREFIX}/board`;
export const APPLE_WATCH = `${process.env.REACT_APP_API_PREFIX}/watch`;
export const DEVICE = `${process.env.REACT_APP_API_PREFIX}/device`;
export const PREVIEW = `${process.env.REACT_APP_API_PREFIX}/preview`;
export const USERCONTROL = `${process.env.REACT_APP_API_PREFIX}/admin`;
export const REGISTRATION = `${process.env.REACT_APP_API_PREFIX}/register`;
export const REPOSITORIUM = `${process.env.REACT_APP_API_PREFIX}/repositorium`;
export const DEVICE_DASHBOARD = `${process.env.REACT_APP_API_PREFIX}/device_dashboard`;
export const USERCONTROL_USERS = `${USERCONTROL}/users`;
export const DEVICECONTROL_DEVICES = `${USERCONTROL}/devices-control`;

let SERVER_PROTOCOL;
let SERVER_HOST;
let WS_PROTOCOL;
let WS_HOST;
let API_PREFIX;

export const userRole = ['user', 'admin', 'superuser'];
export const notificationType = ['error', 'warning', 'success'];
export const repositoriumFolder = ['public', 'private', 'logs', 'sessionData'];
export const executionLogStatus = ['completed', 'expired', 'in progress', 'interrupted'];

export const denyRoutesByRole = { user: [USERCONTROL, USERCONTROL_USERS, DEVICECONTROL_DEVICES] };

if (process.env.NODE_ENV === 'development') {
    SERVER_PROTOCOL = process.env.REACT_APP_SERVER_PROTOCOL;
    SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
    WS_PROTOCOL = process.env.REACT_APP_WS_PROTOCOL;
    WS_HOST = process.env.REACT_APP_SERVER_HOST;
    API_PREFIX = process.env.REACT_APP_API_PREFIX;
} else if (process.env.NODE_ENV === 'production') {
    SERVER_PROTOCOL = process.env.REACT_APP_SERVER_PROTOCOL;
    SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
    WS_PROTOCOL = process.env.REACT_APP_WS_PROTOCOL;
    WS_HOST = process.env.REACT_APP_SERVER_HOST;
    API_PREFIX = process.env.REACT_APP_API_PREFIX;
}

export const BASE_URL = SERVER_PROTOCOL + '://' + SERVER_HOST + API_PREFIX;
export const WS_URL = WS_PROTOCOL + '://' + WS_HOST + API_PREFIX;

export const TITLE = {
    MAGNUM: 'Magnum',
    PREVIEW: 'Preview',
    DEVICE_LIST: 'Device List',
    DEVICE_DASHBOARD: 'Device Dashboard',
    REPOSITORIUM: 'Repositorium',
    EXECUTION_CENTER: 'Execution Center',
    INTERACTIVE_MODE: 'Interactive Mode',
};

export const ACTIVE_SESSION = {
    POPUP_HEADER: 'Active sessions',
    POPUP_TITLE: ['MODEL', 'UDID', 'SESSION', 'TYPE', '', ''],
    TYPE: 'browser',
    ACTION: {
        OPEN: 'Open',
        STOP: 'Stop',
        CONNECT: 'Connect',
    },
};

export const DEVICES_CONTROL = {
    FILTER: {
        OS: ['macos', 'windows'],
        STATUS: ['active', 'disconnected'],
    },
    FILTERED_BY: {
        OS: 'os',
        STATUS: 'status',
    },
};

export const DEVICECLASS = {
    MOBILE: 'mobile',
    BLE_SERIAL_PORT: 'ble-serial-port',
};
export const DEVICETYPE = {
    REAL: 'real',
    EMULATOR: 'emulator',
};
export const DEVICE_STATUS_CODES = {
    AVAILABLE: 'available',
    RESERVED: 'reserved',
    BUSY: 'busy',
    OFFLINE: 'offline',
    DISCONNECTED: 'disconnected',
    RELEASED: 'releasing',
    ALLOCATED: 'allocating',
};
