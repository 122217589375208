import axios from 'axios';
import { BASE_URL } from '../../variables/common';
import errorHandler from '../../utils/errorHandler';
import {
    REAL_DEVICES,
    SELECT_DEVICE,
    VIRTUAL_DEVICES,
    SET_DEVICE_CAPABILITIES,
    IS_VIRTUAL_DEVICES_SWITCH,
    SET_SEARCH,
    SET_FILTERED_DATA,
    INSPECTOR_XY,
    SET_DEVICE_PLATFORM,
    SET_DEVICE_BLE,
} from '../storeActionTypes';

import { getAllAgents } from '../admin/admin.actions';

export const selectDevice = (data) => ({
    type: SELECT_DEVICE,
    payload: data,
});

export const setSearch = (data) => ({
    type: SET_SEARCH,
    payload: data,
});

export const setFilteredData = (data) => ({
    type: SET_FILTERED_DATA,
    payload: data,
});

export const setDeviceCapabilities = (data) => ({
    type: SET_DEVICE_CAPABILITIES,
    payload: data,
});
export const setDeviceBle = (data) => ({
    type: SET_DEVICE_BLE,
    payload: data,
});

export const isVirtualDevicesSwitch = (data) => ({
    type: IS_VIRTUAL_DEVICES_SWITCH,
    payload: data,
});

export const RealDevices = (data) => ({
    type: REAL_DEVICES,
    payload: data,
});

export const VirtualDevices = (data) => ({
    type: VIRTUAL_DEVICES,
    payload: _renameModelsVirtualDevices(data),
});

export const inspectorXY = (data) => ({
    type: INSPECTOR_XY,
    payload: data,
});

export const setDevicePlatform = (data) => ({
    type: SET_DEVICE_PLATFORM,
    payload: data,
});

export const deviceCapabilities = (id) => (dispatch) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/device-list/capabilities/${id}`,
    })
        .then((response) => {
            dispatch(setDeviceCapabilities(response.data));
        })
        .catch((error) => {
            errorHandler(error);
        });
};
export const getDeviceRelations = () => (dispatch) => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/admin/device/association`,
    })
        .then((response) => {
            dispatch(setDeviceBle(response.data));
            dispatch(getAllAgents());
        })
        .catch((error) => {
            errorHandler(error);
        });
};
export const setMobileBle = (data) => (dispatch) => {
    axios({
        method: 'POST',
        url: `${BASE_URL}/admin/device/association/link`,
        data: data,
    })
        .then(() => {
            dispatch(getDeviceRelations());
        })
        .catch((error) => {
            errorHandler(error);
        });
};
export const deleteMobileBle = (data) => (dispatch) => {
    axios({
        method: 'DELETE',
        url: `${BASE_URL}/admin/device/association/unlink`,
        data: data,
    })
        .then(() => {
            dispatch(getDeviceRelations());
        })
        .catch((error) => {
            errorHandler(error);
        });
};

const _renameModelsVirtualDevices = (data) => {
    if (data) {
        return data.map((item) => {
            const searchPosition = item.model.toLowerCase().indexOf('_api');

            const model =
                searchPosition === -1
                    ? item.model
                    : item.model
                          .slice(0, searchPosition + 1)
                          .split('_')
                          .join(' ');

            return { ...item, model };
        });
    }
    return [];
};
